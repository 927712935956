import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Set } from 'immutable';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import IntlMessages from 'Util/IntlMessages';
import { errorReset, logoutUser } from 'Redux/actions';
import * as errorsDuck from 'Redux/errors/reducer';

class ModalError extends PureComponent {
	constructor() {
		super();
		this.state = {
			isClosing: new Set(),
		};
		this.onDismissHandler = this.onDismissHandler.bind(this);
		this.renderDetail = this.renderDetail.bind(this);
	}

	onDismissHandler(type) {
		const { isClosing } = this.state;
		const { errors, logoutUser } = this.props;
		const error = errors.get(type);
		if (error.message === 'TOKEN_EXPIRED' || error.message === 'TOKEN_INVALID') {
			logoutUser();
		}
		this.setState({
			isClosing: isClosing.add(type),
		});
		if (error.message !== 'Invalid Request') {
			setTimeout(() => {
				const { isClosing } = this.state;
				this.setState({
					isClosing: isClosing.delete(type),
				});
				this.props.errorReset(type);
			}, 1000);
		}
	}

	renderDetail(detail) {
		if (_.isObject(detail)) {
			return (
				<ul className="mt-3">
					{
						_.map(detail, (value, index) => (
							<li key={ `modal-error-detail-${index}` }>
								<strong>{ index }: </strong>
								{
									_.isObject(value) ? value.map(
										(v, j) => (
											<p key={ `modal-error-detail-${index}-${j}` }>
												{ v }
											</p>
										)
									) : value
								}
							</li>
						))
					}
				</ul>
			);
		} else if (detail) {
			return (
				<p className="mb-0">
					{ detail }
				</p>
			);
		} else {
			return null;
		}
	}

	render() {
		const { errors } = this.props;
		const { isClosing } = this.state;
		if (errors.size > 0) {
			return (
				<Fragment>
					{
						errors.map((error, type) => {
							const onDismissHandler = this.onDismissHandler.bind(this, type);
							return (
								<Modal
									key={ `modal-error-${type}` }
									isOpen={ !isClosing.get(type) }
									toggle={ onDismissHandler }
								>
									<ModalHeader toggle={ onDismissHandler }>
										Error Occurred
									</ModalHeader>
									<ModalBody>
										<p className="mb-0">
											<IntlMessages id={ error.message } />
										</p>
										{ this.renderDetail(error.detail) }
									</ModalBody>
									<ModalFooter>
										<Button
											type="primary"
											size="lg"
											onClick={ onDismissHandler }
										>
											OK
										</Button>
									</ModalFooter>
								</Modal>
							);
						}).toList()
					}
				</Fragment>
			);
		}
		return null;
	}
}

export default connect(
	errorsDuck.selector,
	{
		errorReset,
		logoutUser,
	}
)(ModalError);