import {
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL
} from 'Constants/actionTypes';

export const optionsGetSuccess = (data) => {
	return ({
		type: OPTIONS_GET_SUCCESS,
		payload: data,
	});
};

export const optionsGetFail = (error) => ({
	type: OPTIONS_GET_FAIL,
	payload: error,
});
