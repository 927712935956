import React, { PureComponent, Fragment } from 'react';
import { Row, Col, Card, CardTitle, CardBody, Table, CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Spinner from 'Components/Spinner';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { usersGetPermissions, userRolesGetOptions, errorReset } from 'Redux/actions';
import IntlMessages from 'Util/IntlMessages';

class PageUserPermission extends PureComponent {
	constructor(props) {
		super(props);
		this.breadcrumb = [
			{ id: 'menu.user', href: '/user' },
			{ id: 'menu.user.find-user', href: '/user/list' },
			{ id: 'user.heading', href: `/user/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
			{ id: 'user-permission.heading', href: `/user/permission/${this.getId(props)}` },
		];
		this.getId = this.getId.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		this.props.usersGetPermissions(id);
		this.props.userRolesGetOptions();
		this.props.errorReset('permissions');
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.userId;
	}

	renderModule(category, module, index) {
		const { options } = this.props;
		const id = this.getId();
		let permissions = this.props.permissions.get(id);
		if (permissions) {
			permissions = permissions.permissions;
		}
		return (
			<tr key={ `page-user-permission-module-${category}-${index}` }>
				<th>{ module.label }</th>
				<td>{ module.desc }</td>
				<td>
					{
						options.get('permissions') && options.get('permissions').map((permission, j) => (
							<CustomInput
								id={ `${category}-${module.value}-${permission.value}` }
								key={ `page-user-permission-module-${category}-${index}-${j}` }
								type="radio"
								inline
								name={ module.value }
								label={ permission.label }
								value={ permission.value }
								checked={ (!permissions[module.value] && permission.value === ' ') || permissions[module.value] === permission.value }
								readOnly
							/>
						))
					}
				</td>
			</tr>
		);
	}

	render() {
		const { options } = this.props;
		const id = this.getId();
		const permissions = this.props.permissions.get(id);
		const status = this.props.status.get('permissions');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={
								<Fragment>
									{ `${id}: ` }
									<IntlMessages id="user-permission.heading" />
								</Fragment>
							}
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				{
					(status === 'fetching' || !permissions) ? (
						<Spinner />
					) : (
						<Fragment>
							<Row className="mb-3">
								<Col xs="12">
									<Card>
										<CardBody>
											<CardTitle>Backend Permission Control</CardTitle>
											<Table>
												<thead>
													<tr>
														<th width="20%">Module</th>
														<th width="50%">Name</th>
														<th width="30%">Permission</th>
													</tr>
												</thead>
												<tbody>
													{
														options.get('modules') && options.get('modules').backend.map((p, index) => this.renderModule('backend', p, index))
													}
												</tbody>
											</Table>
										</CardBody>
									</Card>
								</Col>
							</Row>

							<Row className="mb-3">
								<Col xs="12">
									<Card>
										<CardBody>
											<CardTitle>Shop Front Permission Control</CardTitle>

											<Table>
												<thead>
													<tr>
														<th width="20%">Module</th>
														<th width="50%">Name</th>
														<th width="30%">Permission</th>
													</tr>
												</thead>
												<tbody>
													{
														options.get('modules') && options.get('modules').shop_front.map((p, index) => this.renderModule('shop_front', p, index))
													}
												</tbody>
											</Table>
										</CardBody>
									</Card>
								</Col>
							</Row>

							<div className="text-center">
								<NavLink
									to={ `/app/user/edit/${id}` }
									className="btn btn-lg btn-primary"
								>
									BACK
								</NavLink>
							</div>
						</Fragment>
					)
				}
			</Fragment>
		);
	}
}

const selector = createStructuredSelector({
	status      : (state) => state.users.status,
	permissions : (state) => state.users.permissions,
	options     : (state) => state.userRoles.options,
});

export default connect(
	selector,
	{
		usersGetPermissions,
		userRolesGetOptions,
		errorReset,
	}
)(PageUserPermission);