import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	PROMOTIONS_CREATE,
	PROMOTIONS_UPDATE,
	PROMOTIONS_COPY,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PROMOTIONS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	promotionsCreateSuccess,
	promotionsCreateFail,
	promotionsUpdateSuccess,
	promotionsUpdateFail,
	promotionsCopySuccess,
	promotionsCopyFail,
	promotionsFindAllByPageAndOptionsSuccess,
	promotionsFindAllByPageAndOptionsFail,
	promotionsGetDetailsSuccess,
	promotionsGetDetailsFail,
} from './actions';

export function* watchPromotionsCreate() {
	yield takeEvery(PROMOTIONS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/promotion/create`, data);
			return response.data;
		},
		promotionsCreateSuccess,
		promotionsCreateFail
	));
}

export function* watchPromotionsUpdate() {
	yield takeEvery(PROMOTIONS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/promotion/update`, data);
			return response.data;
		},
		promotionsUpdateSuccess,
		promotionsUpdateFail
	));
}

export function* watchPromotionsCopy() {
	yield takeEvery(PROMOTIONS_COPY, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/promotion/copy`, data);
			return response.data;
		},
		promotionsCopySuccess,
		promotionsCopyFail
	));
}

export function* watchPromotionsFindAllByPageAndOptions() {
	yield takeEvery(PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/promotion/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		promotionsFindAllByPageAndOptionsSuccess,
		promotionsFindAllByPageAndOptionsFail
	));
}

export function* watchPromotionsGetDetails() {
	yield takeEvery(PROMOTIONS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/promotion/details`, { params: { id: data } });
			return response.data;
		},
		promotionsGetDetailsSuccess,
		promotionsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchPromotionsCreate),
		fork(watchPromotionsUpdate),
		fork(watchPromotionsCopy),
		fork(watchPromotionsFindAllByPageAndOptions),
		fork(watchPromotionsGetDetails),
	]);
}