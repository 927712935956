import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	MAX_BRAND_DISCOUNTS_CREATE,
	MAX_BRAND_DISCOUNTS_UPDATE,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	maxBrandDiscountsCreateSuccess,
	maxBrandDiscountsCreateFail,
	maxBrandDiscountsUpdateSuccess,
	maxBrandDiscountsUpdateFail,
	maxBrandDiscountsFindAllByPageAndOptionsSuccess,
	maxBrandDiscountsFindAllByPageAndOptionsFail,
	maxBrandDiscountsGetDetailsSuccess,
	maxBrandDiscountsGetDetailsFail,
} from './actions';

export function* watchMaxBrandDiscountsCreate() {
	yield takeEvery(MAX_BRAND_DISCOUNTS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/brand/maxDiscount/create`, data);
			return response.data;
		},
		maxBrandDiscountsCreateSuccess,
		maxBrandDiscountsCreateFail
	));
}

export function* watchMaxBrandDiscountsUpdate() {
	yield takeEvery(MAX_BRAND_DISCOUNTS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/brand/maxDiscount/update`, data);
			return response.data;
		},
		maxBrandDiscountsUpdateSuccess,
		maxBrandDiscountsUpdateFail
	));
}

export function* watchMaxBrandDiscountsFindAllByPageAndOptions() {
	yield takeEvery(MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/brand/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		maxBrandDiscountsFindAllByPageAndOptionsSuccess,
		maxBrandDiscountsFindAllByPageAndOptionsFail
	));
}

export function* watchMaxBrandDiscountsGetDetails() {
	yield takeEvery(MAX_BRAND_DISCOUNTS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/brand/maxDiscount/details`, { params: { id: data } });
			return response.data;
		},
		maxBrandDiscountsGetDetailsSuccess,
		maxBrandDiscountsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchMaxBrandDiscountsCreate),
		fork(watchMaxBrandDiscountsUpdate),
		fork(watchMaxBrandDiscountsFindAllByPageAndOptions),
		fork(watchMaxBrandDiscountsGetDetails),
	]);
}