import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import Pluralize from 'react-pluralize';
import ReactTable from 'react-table';
import moment from 'moment';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import {
	companiesFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as companiesReducer from 'Redux/companies/reducer';
import IntlMessages from 'Util/IntlMessages';
import 'react-table/react-table.css';

class PageCompanies extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.company', href: '/company/list' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={ `/app/company/edit/${d.id}` }>
						{ d.name }
					</NavLink>
				)
			},
			{
				id: 'category',
				Header: 'Category / Currency',
				accessor: (d) => `${d.category ? d.category.label : ''}${d.currency ? ` / ${d.currency.label}` : ''}`,
			},
			{
				id: 'warehouses',
				Header: 'Warehouses',
				sortable: false,
				accessor: (d) => (
					d.warehouses === null ? (
						'N/A'
					) : (
						<Pluralize singular="Warehouse" count={ d.warehouses.length } />
					)
				),
			},
			{
				id: 'updated_on',
				Header: 'Last Modified',
				accessor: (d) => (
					d.updated_on ? moment(d.updated_on).format('YYYY-MM-DD HH:mm:ss') : ''
				),
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					if (!d.status) {
						return null;
					}
					let className = 'badge-primary';
					switch (d.status.label) {
					case 'Pending':
						className = 'badge-danger';
						break;
					}
					return (
						<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ d.status.label }</span>
					);
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/company/edit/${d.id}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('companies');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.companiesFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { companiesByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('companies');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="mb-2">
							<BreadcrumbContainer
								heading={ <IntlMessages id="companies.heading" /> }
								items={ this.breadcrumb }
								match={ this.props.match }
							/>
							<div className="float-right">
								<NavLink to="/app/company/create" className="btn btn-lg btn-primary">
									ADD COMPANY
								</NavLink>
							</div>
							<div className="clearfix" />
						</div>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ companiesByPage.get('data') }
									pages={ companiesByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	companiesReducer.selector,
	{
		companiesFindAllByPageAndOptions,
		errorReset,
	}
)(PageCompanies);