/*
color options : 
	 'light.purple'		'dark.purple'
	 'light.blue'		  'dark.blue'
	 'light.green'		'dark.green'
	 'light.orange'		'dark.orange'
	 'light.red'		  'dark.red'
*/
var color = 'puyi';
if (localStorage.getItem('themeColor')) {
	color = localStorage.getItem('themeColor');
}

let render = () => {
	import('./assets/css/sass/themes/gogo.' + color + '.scss').then(() => {
		require('./App');
	});

};
render();