import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Label } from 'reactstrap';
import Switch from 'rc-switch';
import classnames from 'classnames';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import CustomSelectInput from 'Components/CustomSelectInput';
import {
	promotionsFindAllByPageAndOptions,
	promotionsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as promotionsReducer from 'Redux/promotions/reducer';
import IntlMessages from 'Util/IntlMessages';

class PagePromotions extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			showActiveOnly: null,
			shops: [],
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.promotion', href: '/promotion/list' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={ `/app/promotion/edit/${d.id}` }>
						{ d.name }
					</NavLink>
				),
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'period_start',
				Header: 'Period',
				accessor: (d) => (
					<Fragment>
						{ d.period_start ? moment(d.period_start).format('YYYY-MM-DD HH:mm:ss') : '' } -<br />
						{ d.period_end ? moment(d.period_end).format('YYYY-MM-DD HH:mm:ss') : '' }
					</Fragment>
				),
			},
			{
				Header: 'Level',
				accessor: 'level',
			},
			{
				id: 'updated_on',
				Header: 'Last Modified',
				accessor: (d) => (
					d.updated_on ? moment(d.updated_on).format('YYYY-MM-DD HH:mm:ss') : ''
				),
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					if (d.status) {
						let className = 'badge-primary';
						switch (d.status.label) {
						case 'Inactive':
							className = 'badge-light';
							break;
						}
						return (
							<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ d.status ? d.status.label : '' }</span>
						);
					}
					return '';
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/promotion/edit/${d.id}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangeWarehousesHandler = this.onChangeWarehousesHandler.bind(this);
		this.onToggleShowActiveHandler = this.onToggleShowActiveHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('promotions');
		this.props.promotionsGetOptions();
	}

	fetchData(state) {
		const { keyword, showActiveOnly, shops } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		if (showActiveOnly) {
			params.is_active = true;
		}
		if (shops.length > 0) {
			params.shops = shops.map((shop) => shop.value).join(',');
		}
		this.setState({
			tableState: state,
		});
		this.props.promotionsFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onChangeWarehousesHandler(selectedOption) {
		this.setState({
			shops: selectedOption,
		}, this.onSubmitHandler);
	}

	onToggleShowActiveHandler() {
		let { showActiveOnly } = this.state;
		showActiveOnly = showActiveOnly ? null : true;
		this.setState({
			showActiveOnly,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { promotionsByPage, options } = this.props;
		const { keyword, showActiveOnly, shops } = this.state;
		const status = this.props.status.get('promotions');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="float-left">
							<BreadcrumbContainer
								heading={ <IntlMessages id="promotions.heading" /> }
								items={ this.breadcrumb }
								match={ this.props.match }
							/>
						</div>
						<div className="float-right">
							<NavLink to="/app/promotion/create" className="btn btn-lg btn-primary">
								CREATE NEW
							</NavLink>
						</div>
						<div className="clearfix"></div>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="d-flex justify-content-between align-items-center">
								<div className="flex-fill" style={{ width: '33%' }}>
									<div className="search-sm d-inline-block">
										<input
											type="text"
											placeholder="Search"
											value={ keyword }
											onChange={ this.onChangeKeywordHandler }
										/>
									</div>
								</div>

								<div className="flex-fill" style={{ width: '33%' }}>
									<div className="d-inline-block align-middle mr-3">
										<Switch
											className="custom-switch custom-switch-primary"
											checked={ !!showActiveOnly }
											onChange={ this.onToggleShowActiveHandler }
										/>
									</div>
									<div className="d-inline-block align-middle">
										Show Active Promotions Only
									</div>
								</div>

								<div className="flex-fill" style={{ width: '33%' }}>
									<div className="d-inline-block align-middle form-inline-label">
										<Label>Warehouses:</Label>
									</div>

									<div className="d-inline-block align-middle form-inline-select">
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"
											value={ shops }
											isClearable={ true }
											onChange={ this.onChangeWarehousesHandler }
											options={ options.get('wh_codes') }
											isMulti={ true }
										/>
									</div>
								</div>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ promotionsByPage.get('data') }
									pages={ promotionsByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	promotionsReducer.selector,
	{
		promotionsFindAllByPageAndOptions,
		promotionsGetOptions,
		errorReset,
	}
)(PagePromotions);