export const subHiddenBreakpoint = 992;
export const menuHiddenBreakpoint = 768;
export const defaultMenuType = 'menu-default menu-sub-hidden'; //'menu-hidden'
export const defaultStartPath = '/app/home';

export const defaultLocale='en';
export const localeOptions=[
	{id:'en',name:'English'},
];

export const apiUrl = `${process.env.API_ENDPOINT}/api/v1`;
