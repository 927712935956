import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from 'Containers/App';
import { configureStore } from 'Redux/store';

if (process.env.BUGSNAG_API_KEY) {
	Bugsnag.start({
		apiKey: process.env.BUGSNAG_API_KEY,
		appVersion: process.env.APP_VERSION,
		plugins: [new BugsnagPluginReact()]
	});
}

Axios.defaults.headers.common['Request-Type'] = 'backend';

const MainApp = () => (
	isMobile ? (
		<div className="text-center">
			<div className="logoContainer">
				<img src="/assets/images/logo.png" className="logo" />
			</div>
			<h1 className="text px-3 py-4">This site is unavailable on mobile</h1>
		</div>
	) : (
		<Provider store={configureStore()}>
			<Router>
				<Switch>
					<Route path='/' component={App} />
				</Switch>
			</Router>
		</Provider>
	)
);

const ErrorBoundary = process.env.BUGSNAG_API_KEY ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;

export default  ReactDOM.render(
	<ErrorBoundary>
		<MainApp />
	</ErrorBoundary>,
	document.getElementById('root')
);