import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	USERS_READ_CURRENT,
	USERS_READ_CURRENT_SUCCESS,
	USERS_READ_CURRENT_FAIL,
	USERS_ASSIGN,
	USERS_ASSIGN_SUCCESS,
	USERS_ASSIGN_FAIL,
	USERS_UNLOCK,
	USERS_UNLOCK_SUCCESS,
	USERS_UNLOCK_FAIL,
	USERS_CLEAR_2FA_SECRET,
	USERS_CLEAR_2FA_SECRET_SUCCESS,
	USERS_RESET_2FA_SECRET,
	USERS_RESET_2FA_SECRET_SUCCESS,
	USERS_CHANGE_PASSWORD,
	USERS_CHANGE_PASSWORD_SUCCESS,
	USERS_CHANGE_PASSWORD_FAIL,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USERS_GET_DETAILS,
	USERS_GET_DETAILS_SUCCESS,
	USERS_GET_DETAILS_FAIL,
	USERS_GET_PERMISSIONS,
	USERS_GET_PERMISSIONS_SUCCESS,
	USERS_GET_PERMISSIONS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USERS_READ_CURRENT:
		return state.set('current', 'fetching');
	case USERS_READ_CURRENT_SUCCESS:
		return state.set('current', 'fetched');
	case USERS_READ_CURRENT_FAIL:
		return state.set('current', 'has-errors');
	case USERS_ASSIGN:
		return state.set('user', 'submitting');
	case USERS_ASSIGN_SUCCESS:
		return state.set('user', 'submitted');
	case USERS_CLEAR_2FA_SECRET:
		return state.set('2fa', 'clearing');
	case USERS_CLEAR_2FA_SECRET_SUCCESS:
		return state.set('2fa', 'cleared');
	case USERS_RESET_2FA_SECRET:
		return state.set('2fa', 'resetting');
	case USERS_RESET_2FA_SECRET_SUCCESS:
		return state.set('2fa', 'reset');
	case USERS_UNLOCK:
		return state.set('user', 'unlocking');
	case USERS_UNLOCK_SUCCESS:
		return state.set('user', 'unlocked');
	case USERS_CHANGE_PASSWORD:
		return state.set('password', 'submitting');
	case USERS_CHANGE_PASSWORD_SUCCESS:
		return state.set('password', 'submitted');
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('users', 'fetching');
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('users', 'fetched');
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('users', 'has-errors');
	case USERS_GET_DETAILS:
		return state.set('user', 'fetching');
	case USERS_GET_DETAILS_SUCCESS:
		return state.set('user', 'fetched');
	case USERS_ASSIGN_FAIL:
	case USERS_UNLOCK_FAIL:
	case USERS_GET_DETAILS_FAIL:
		return state.set('user', 'has-errors');
	case USERS_CHANGE_PASSWORD_FAIL:
		return state.set('password', 'has-errors');
	case USERS_GET_PERMISSIONS:
		return state.set('permissions', 'fetching');
	case USERS_GET_PERMISSIONS_SUCCESS:
		return state.set('permissions', 'fetched');
	case USERS_GET_PERMISSIONS_FAIL:
		return state.set('permissions', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'user' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'user' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'user' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USERS_READ_CURRENT:
		return new Map();
	case USERS_READ_CURRENT_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const usersByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const userReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USERS_GET_DETAILS:
		return new Map();
	case USERS_ASSIGN_SUCCESS:
	case USERS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.user_id, action.payload);
	default:
		return state;
	}
};

const permissionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USERS_GET_PERMISSIONS:
		return new Map();
	case USERS_GET_PERMISSIONS_SUCCESS:
		return state.set(action.payload.user_id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'user' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'user' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status      : statusReducer,
	current     : currentReducer,
	usersByPage : usersByPageReducer,
	user        : userReducer,
	permissions : permissionsReducer,
	options     : optionsReducer,
});

export const selector = createStructuredSelector({
	status         : (state) => state.users.status,
	current        : (state) => state.users.current,
	usersByPage    : (state) => state.users.usersByPage,
	user           : (state) => state.users.user,
	permissions    : (state) => state.users.permissions,
	options        : (state) => state.users.options,
	errors         : (state) => state.errors.get('user'),
	errorsPassword : (state) => state.errors.get('password'),
});