import React, { PureComponent, Fragment } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import LoadingOverlay from 'Components/LoadingOverlay';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { transactionsGetDetails, errorReset } from 'Redux/actions';
import * as transactionsReducer from 'Redux/transactions/reducer';
import IntlMessages from 'Util/IntlMessages';
import './PageStockTransferOrder.scss';

class PageTransaction extends PureComponent {
	constructor(props) {
		super(props);
		const params = this.getParams();
		this.breadcrumb = [
			{ id: 'menu.transaction', href: '/transaction/list' },
			{ id: 'transaction.heading', href: `/stock-transfer/view/${params.type}/${params.orderType}/${params.id}` },
		];
		this.columns = [
			{
				Header: 'Item Name',
				accessor: 'item_name_en',
			},
			{
				Header: 'Item Code',
				accessor: 'item_code',
			},
			{
				Header: 'Lot No.',
				accessor: 'lot_no',
			},
			{
				Header: 'Brand Name',
				accessor: 'brand.name_en',
			},
			{
				Header: 'Category',
				accessor: 'category.name_en',
			},
			{
				Header: 'Qty',
				accessor: 'item_quantity',
			},
		];
		this.getParams = this.getParams.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentDidMount() {
		this.fetchData();
		this.props.errorReset('transaction');
	}

	getParams(props = null) {
		if (!props) {
			props = this.props;
		}
		return {
			id: props.match.params.transactionNumber,
			orderType: props.match.params.orderType,
			type: props.match.params.type,
		};
	}

	fetchData() {
		const { id, orderType } = this.getParams();
		const params = {
			transaction_number: id,
			order_type: orderType,
		};
		this.props.transactionsGetDetails(params);
	}

	render() {
		const { id, orderType, type } = this.getParams();
		const transaction = this.props.transaction.get(id);
		const status = this.props.status.get('transaction');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ id ? <span>{ id }</span> : <IntlMessages id="transaction.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
						<div className="float-right">
							<NavLink
								to={ `/app/stock-transfer/print/${type}/${orderType}/${id}` }
								className="btn btn-primary btn-lg"
							>
								PRINT
							</NavLink>
						</div>
						<div className="clearfix" />
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Row className="mb-3">
						<Col xs="12">
							<table className="invoice-header-table">
								<tbody>
									<tr>
										<td className="invoice-header-customer">
											<p className="text-muted text-small font-weight-bold">From:</p>

											{
												transaction && transaction.wh_desc_from && (
													<Fragment>
														<p className="text-muted text-small mb-1">Warehouse:</p>
														<p className="mb-3">{ transaction && transaction.wh_desc_from }</p>
													</Fragment>
												)
											}

											{
												transaction && transaction.wh_address_from && (
													<Fragment>
														<p className="text-muted text-small mb-1">Address:</p>
														<p className="mb-3">{ transaction && transaction.wh_address_from }</p>
													</Fragment>
												)
											}

											{
												transaction && transaction.wh_address_from && (
													<Fragment>
														<p className="text-muted text-small mb-1">Phone:</p>
														<p className="mb-3">{ transaction && transaction.wh_phone_from }</p>
													</Fragment>
												)
											}

											<hr />

											<p className="text-muted text-small font-weight-bold">To:</p>

											{
												transaction && transaction.wh_desc_to && (
													<Fragment>
														<p className="text-muted text-small mb-1">Warehouse:</p>
														<p className="mb-3">{ transaction && transaction.wh_desc_to }</p>
													</Fragment>
												)
											}

											{
												transaction && transaction.wh_address_to && (
													<Fragment>
														<p className="text-muted text-small mb-1">Address:</p>
														<p className="mb-3">{ transaction && transaction.wh_address_to }</p>
													</Fragment>
												)
											}

											{
												transaction && transaction.wh_address_to && (
													<Fragment>
														<p className="text-muted text-small mb-1">Phone:</p>
														<p className="mb-3">{ transaction && transaction.wh_phone_to }</p>
													</Fragment>
												)
											}
										</td>
										<td className="invoice-header-staffs">
											<p className="text-muted text-small mb-1">Ordering Date</p>
											<p>
												{ (transaction && transaction.created_date) ? moment(transaction.created_date).format('YYYY-MM-DD HH:mm:ss') : '-' }
											</p>

											<p className="text-muted text-small mb-1">Status</p>
											<p className="text-uppercase">{ (transaction && transaction.status) ? transaction.status : '-' }</p>

											<p className="text-muted text-small mb-1">Sales Person</p>
											<p>
												{
													transaction && transaction.salesman_code ? (
														<Fragment>
															{ !!transaction.salesman_name && transaction.salesman_name }
															{ !!transaction.salesman_code && transaction.salesman_code }
															<br />
														</Fragment>
													) : (
														'-'
													)
												}
											</p>

											<p className="text-muted text-small mb-1">Remark</p>
											<p>{ (transaction && transaction.remark) ? transaction.remark : '-' }</p>

											<p className="text-muted text-small mb-1">Transferring Items</p>
											<p>{ transaction && transaction.item_count }</p>
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
					
					<Row className="mb-3">
						<Col xs="12">
							<Card>
								<CardBody>
									<ReactTable
										data={ transaction && transaction.transaction_items }
										columns={ this.columns }
										minRows={ 0 }
										showPageJump={ false }
										showPageSizeOptions={ false }
										showPagination={ false }
										PaginationComponent={ DataTablePagination }
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	transactionsReducer.selector,
	{
		transactionsGetDetails,
		errorReset,
	}
)(PageTransaction);