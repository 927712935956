import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import { OPTIONS_GET } from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	optionsGetSuccess,
	optionsGetFail,
} from './actions';

export function* watchOptionsGet() {
	yield takeEvery(OPTIONS_GET, defaultFetchDataFunc(
		async (payload) => {
			const response = await Axios.get(`${apiUrl}/options/${payload.tag}`, { params: payload.params });
			return response.data;
		},
		optionsGetSuccess,
		optionsGetFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchOptionsGet),
	]);
}