import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	REMARKS_CREATE,
	REMARKS_UPDATE,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	REMARKS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	remarksCreateSuccess,
	remarksCreateFail,
	remarksUpdateSuccess,
	remarksUpdateFail,
	remarksFindAllByPageAndOptionsSuccess,
	remarksFindAllByPageAndOptionsFail,
	remarksGetDetailsSuccess,
	remarksGetDetailsFail,
} from './actions';

export function* watchRemarksCreate() {
	yield takeEvery(REMARKS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/remark/create`, data);
			return response.data;
		},
		remarksCreateSuccess,
		remarksCreateFail
	));
}

export function* watchRemarksUpdate() {
	yield takeEvery(REMARKS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/remark/update`, data);
			return response.data;
		},
		remarksUpdateSuccess,
		remarksUpdateFail
	));
}

export function* watchRemarksFindAllByPageAndOptions() {
	yield takeEvery(REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/remark/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		remarksFindAllByPageAndOptionsSuccess,
		remarksFindAllByPageAndOptionsFail
	));
}

export function* watchRemarksGetDetails() {
	yield takeEvery(REMARKS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/remark/details`, { params: { id: data } });
			return response.data;
		},
		remarksGetDetailsSuccess,
		remarksGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchRemarksCreate),
		fork(watchRemarksUpdate),
		fork(watchRemarksFindAllByPageAndOptions),
		fork(watchRemarksGetDetails),
	]);
}