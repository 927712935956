import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import moment from 'moment';
import { Row, Button } from 'reactstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import MessageCondition from './components/MessageCondition.jsx';
import FormMessageDetails from './components/FormMessageDetails.jsx';
import IntlMessages from 'Util/IntlMessages';
import {
	messagesCreate,
	messagesUpdate,
	messagesGetDetails,
	messagesGetOptions,
	errorReset,
} from 'Redux/actions';
import * as messagesReducer from 'Redux/messages/reducer';

import 'react-datepicker/dist/react-datepicker.css';
import 'Assets/css/vendor/react-datepicker.css';
import 'rc-switch/assets/index.css';

class PageMessage extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			message: new Map({
				id: null,
				status: 1,
				name: '',
				period_start: null,
				period_end: null,
				level: 0,
				content: '',
				conditions: new List(),
			}),
		};
		this.defaults = {
			messageCondition: new Map({
				id: null,
				ordering: 1,
				type: null,
				criteria_type: null,
				total_item_count_operator: null,
				total_item_count_number: null,
				total_amount_operator: null,
				total_amount_number: null,
				parameter: '',
				parameterTo: '',
				parameters: [],
			}),
		};
		this.breadcrumb = [
			{ id: 'menu.message', href: '/message/list' },
			{ id: 'message.heading', href: `/message/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeConditionsHandler = this.onChangeConditionsHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.messagesGetDetails(id);
		}
		this.props.messagesGetOptions();
		this.props.errorReset('message');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		const prevId = this.getId(prevProps);
		if (id) {
			const prevMessage = prevProps.message.get(id);
			let message = this.props.message.get(id);
			if (prevId !== id || message && message !== prevMessage) {
				message.period_start = message.period_start ? moment(message.period_start) : null;
				message.period_end = message.period_end ? moment(message.period_end) : null;
				message.conditions = new List(message.conditions ? message.conditions.map((condition) => {
					if (condition.criteria_type) {
						switch (condition.criteria_type.value) {
						case 1: // Item group
							break;
						case 8: // Coupon Code (Range)
							if (condition.parameters && condition.parameters.length >= 2) {
								condition.parameter = condition.parameters[0];
								condition.parameterTo = condition.parameters[1];
							}
							break;
						default:
							if (condition.parameters && condition.parameters.length >= 1) {
								condition.parameter = condition.parameters[0];
							}
							break;
						}
					}
					condition = this.defaults.messageCondition.merge(condition);
					return condition;
				}) : undefined);
				message = this.state.message.merge(message);
				this.setState({
					message: message,
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('message');
		const status = this.props.status.get('message');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The message is ${id ? 'updated' : 'created'} successfully.`,
					`Message ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/message/edit/${current}`);
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The message is NOT ${id ? 'updated' : 'created'}.`,
					`Message ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.messageId);
	}

	onChangeHandler(field, value) {
		const { message } = this.state;
		this.setState({
			message: message.set(field, value),
		});
	}

	onChangeConditionsHandler(conditions) {
		const { message } = this.state;
		this.setState({
			message: message.set('conditions', conditions),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let message = this.state.message.toJS();
		message.period_start = message.period_start ? message.period_start.format() : null;
		message.period_end = message.period_end ? message.period_end.format() : null;
		message.conditions = message.conditions.map((condition) => {
			condition.type = condition.type ? condition.type.value : null;
			condition.criteria_type = condition.criteria_type ? condition.criteria_type.value : null;
			switch (condition.criteria_type) {
			case 1: // Item Group
				condition.parameters = condition.parameters ? condition.parameters.map((parameter) => parameter.value) : [];
				break;
			case 8: // Coupon Code (Range)
				condition.parameters = (condition.parameter && condition.parameterTo) ? [condition.parameter, condition.parameterTo] : [];
				break;
			default:
				condition.parameters = condition.parameter ? [condition.parameter] : [];
				break;
			}
			delete condition.parameter;
			delete condition.parameterTo;
			return condition;
		});
		if (!id) {
			this.props.messagesCreate(message);
		} else {
			this.props.messagesUpdate(message);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { message } = this.state;
		const status = this.props.status.get('message');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="message.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<FormMessageDetails
						message={ message }
						options={ options }
						errors={ errors }
						isLoading={ isLoading }
						onChange={ this.onChangeHandler }
						onSubmit={ this.onSubmitHandler }
					/>

					<h2 className="mb-2">Message Conditions</h2>

					<Separator className="mb-5" />

					<MessageCondition
						options={ options }
						errors={ errors }
						isLoading={ isLoading }
						defaults={ this.defaults.messageCondition }
						conditions={ message.get('conditions') }
						onChange={ this.onChangeConditionsHandler }
					/>

					<div className="mt-4 text-center">
						<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
							SAVE
						</Button>
					</div>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	messagesReducer.selector,
	{
		messagesCreate,
		messagesUpdate,
		messagesGetDetails,
		messagesGetOptions,
		errorReset,
	}
)(PageMessage);