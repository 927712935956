import { combineReducers } from 'redux';
import { Map, List } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	PAYMENT_METHODS_UPDATE,
	PAYMENT_METHODS_UPDATE_SUCCESS,
	PAYMENT_METHODS_UPDATE_FAIL,
	PAYMENT_METHODS_FIND_ALL,
	PAYMENT_METHODS_FIND_ALL_SUCCESS,
	PAYMENT_METHODS_FIND_ALL_FAIL,
	PAYMENT_METHODS_GET_DETAILS,
	PAYMENT_METHODS_GET_DETAILS_SUCCESS,
	PAYMENT_METHODS_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PAYMENT_METHODS_UPDATE:
		return state.set('paymentMethod', 'submitting');
	case PAYMENT_METHODS_UPDATE_SUCCESS:
		return state.set('paymentMethod', 'submitted');
	case PAYMENT_METHODS_FIND_ALL:
		return state.set('paymentMethods', 'fetching');
	case PAYMENT_METHODS_FIND_ALL_SUCCESS:
		return state.set('paymentMethods', 'fetched');
	case PAYMENT_METHODS_FIND_ALL_FAIL:
		return state.set('paymentMethods', 'has-errors');
	case PAYMENT_METHODS_GET_DETAILS:
		return state.set('paymentMethod', 'fetching');
	case PAYMENT_METHODS_GET_DETAILS_SUCCESS:
		return state.set('paymentMethod', 'fetched');
	case PAYMENT_METHODS_UPDATE_FAIL:
	case PAYMENT_METHODS_GET_DETAILS_FAIL:
		return state.set('paymentMethod', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'paymentMethod' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'paymentMethod' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'paymentMethod' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case PAYMENT_METHODS_FIND_ALL:
		return null;
	case PAYMENT_METHODS_UPDATE_SUCCESS:
	case PAYMENT_METHODS_GET_DETAILS_SUCCESS:
		return action.payload.code;
	default:
		return state;
	}
};

const paymentMethodsReducer = (state = new List(), action) => {
	switch (action.type) {
	case PAYMENT_METHODS_FIND_ALL:
		return new List();
	case PAYMENT_METHODS_FIND_ALL_SUCCESS:
		return new List(action.payload);
	default:
		return state;
	}
};

const paymentMethodReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PAYMENT_METHODS_GET_DETAILS:
		return new Map();
	case PAYMENT_METHODS_UPDATE_SUCCESS:
	case PAYMENT_METHODS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.code, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'paymentMethod' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'paymentMethod' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status         : statusReducer,
	current        : currentReducer,
	paymentMethods : paymentMethodsReducer,
	paymentMethod  : paymentMethodReducer,
	options        : optionsReducer,
});

export const selector = createStructuredSelector({
	status         : (state) => state.paymentMethods.status,
	current        : (state) => state.paymentMethods.current,
	paymentMethods : (state) => state.paymentMethods.paymentMethods,
	paymentMethod  : (state) => state.paymentMethods.paymentMethod,
	options        : (state) => state.paymentMethods.options,
	errors         : (state) => state.errors.get('paymentMethod'),
});