import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Map } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import { Row, Nav, NavItem, TabContent, TabPane, Card, CardBody, Form, Input, Button } from 'reactstrap';
import CustomFormGroup from 'Components/CustomFormGroup';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import IntlMessages from 'Util/IntlMessages';
import {
	itemGroupsCreate,
	itemGroupsUpdate,
	itemGroupsGetDetails,
	itemGroupsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as itemGroupsReducer from 'Redux/itemGroups/reducer';

class PageItemGroup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'details',
			itemGroup: new Map({
				id: null,
				status: null,
				name: '',
				description: '',
				group_type: null,
				item_ids: [],
			}),
		};
		this.fields = [
			{ id: 'name', type: 'text', label: 'Name' },
			{ id: 'description', type: 'textarea', label: 'Description' },
			{ id: 'group_type', type: 'react-select', label: 'Group Type', onChange: this.onChangeGroupTypeHandler.bind(this) },
			{ id: 'status', type: 'react-select', label: 'Status', onChange: this.onChangeStatusHandler.bind(this) },
		];
		this.breadcrumb = [
			{ id: 'menu.item-group', href: '/item-group/list' },
			{ id: 'item-group.heading', href: `/item-group/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onSelectDetailsTabHandler = this.onSelectTabHandler.bind(this, 'details');
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.itemGroupsGetDetails(id);
		}
		this.props.itemGroupsGetOptions();
		this.props.errorReset('itemGroup');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevItemGroup = prevProps.itemGroup.get(id);
			const itemGroup = this.props.itemGroup.get(id);
			if (itemGroup && itemGroup !== prevItemGroup) {
				this.setState({
					itemGroup: this.state.itemGroup.merge(itemGroup),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('itemGroup');
		const status = this.props.status.get('itemGroup');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The item group is ${id ? 'updated' : 'created'} successfully.`,
					`Item Group ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/item-group/edit/${current}`);
				const itemGroup = this.props.itemGroup.get(current);
				this.setState({
					itemGroup: this.state.itemGroup.merge(itemGroup),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The item group is NOT ${id ? 'updated' : 'created'}.`,
					`Item Group ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.itemGroupId);
	}

	onSelectTabHandler(tab) {
		this.setState({
			activeTab: tab,
		});
	}

	onChangeHandler(e) {
		let { itemGroup } = this.state;
		let value;
		switch (e.target.id) {
		case 'item_ids':
			value = e.target.value.split(/[\n,]/).map((v) => v.trim());
			break;
		default:
			value = e.target.value;
			break;
		}
		this.setState({
			itemGroup: itemGroup.set(e.target.id, value),
		});
	}

	onChangeGroupTypeHandler(selectedOption) {
		let { itemGroup } = this.state;
		this.setState({
			itemGroup: itemGroup.set('group_type', selectedOption),
		});
	}

	onChangeStatusHandler(selectedOption) {
		let { itemGroup } = this.state;
		this.setState({
			itemGroup: itemGroup.set('status', selectedOption),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let itemGroup = this.state.itemGroup.toJS();
		itemGroup.status = itemGroup.status ? itemGroup.status.value : null;
		itemGroup.group_type = itemGroup.group_type ? itemGroup.group_type.value : null;
		if (!id) {
			this.props.itemGroupsCreate(itemGroup);
		} else {
			this.props.itemGroupsUpdate(itemGroup);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { activeTab, itemGroup } = this.state;
		const status = this.props.status.get('itemGroup');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="item-group.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'details',
									'nav-link': true
								}) }
								onClick={ this.onSelectDetailsTabHandler }
								to="#"
							>
								DETAILS
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={ activeTab }>
						<TabPane tabId="details">
							<Form onSubmit={ this.onSubmitHandler }>
								<Row>
									<Colxx lg="4">
										<Card>
											<CardBody>
												<h4>Item Group Config</h4>

												{
													this.fields.map((field) => (
														<CustomFormGroup
															key={ `page-item-group-${field.id}` }
															id={ field.id }
															label={ field.label }
															type={ field.type }
															value={ itemGroup.get(field.id) }
															disabled={ isLoading }
															errors={ errors && errors.detail[field.id] }
															onChange={ field.onChange ? field.onChange : this.onChangeHandler }
															options={ options.get(field.id) }
															isMulti={ field.isMulti }
														/>
													))
												}

												{
													itemGroup.get('updated_on') && (
														<p>Updated at { moment(itemGroup.get('updated_on')).format('YYYY-MM-DD HH:mm:ss') }</p>
													)
												}
											</CardBody>
										</Card>
									</Colxx>
									<Colxx lg="8">
										<Card>
											<CardBody>
												<h4>Item List</h4>
												<p>Separated by commas or newline characters</p>
												<Input
													type="textarea"
													id="item_ids"
													rows="10"
													value={ itemGroup.get('item_ids').join('\n') || '' }
													disabled={ isLoading }
													onChange={ this.onChangeHandler }
													invalid={ errors && errors.detail && errors.detail.item_ids }
												/>
												{
													errors && errors.detail && errors.detail.item_ids && (
														<div className="invalid-feedback d-block">
															{
																errors.detail.item_ids.map((error, index) => (
																	<Fragment key={ `page-item-group-item_ids-error-${index}` }>
																		{ index > 0 && <br /> }
																		{ error }
																	</Fragment>
																))
															}
														</div>
													)
												}
											</CardBody>
										</Card>
									</Colxx>
								</Row>

								<div className="mt-4 text-center">
									<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
										SAVE
									</Button>
								</div>
							</Form>
						</TabPane>
					</TabContent>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	itemGroupsReducer.selector,
	{
		itemGroupsCreate,
		itemGroupsUpdate,
		itemGroupsGetDetails,
		itemGroupsGetOptions,
		errorReset,
	}
)(PageItemGroup);