import {
	REMARKS_CREATE,
	REMARKS_CREATE_SUCCESS,
	REMARKS_CREATE_FAIL,
	REMARKS_UPDATE,
	REMARKS_UPDATE_SUCCESS,
	REMARKS_UPDATE_FAIL,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	REMARKS_GET_DETAILS,
	REMARKS_GET_DETAILS_SUCCESS,
	REMARKS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const remarksCreate = (data) => ({
	type: REMARKS_CREATE,
	payload: data,
});

export const remarksCreateSuccess = (data) => {
	return ({
		type: REMARKS_CREATE_SUCCESS,
		payload: data,
	});
};

export const remarksCreateFail = (error) => ({
	type: REMARKS_CREATE_FAIL,
	payload: error,
});

export const remarksUpdate = (data) => ({
	type: REMARKS_UPDATE,
	payload: data,
});

export const remarksUpdateSuccess = (data) => {
	return ({
		type: REMARKS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const remarksUpdateFail = (error) => ({
	type: REMARKS_UPDATE_FAIL,
	payload: error,
});

export const remarksFindAllByPageAndOptions = (params) => ({
	type: REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const remarksFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const remarksFindAllByPageAndOptionsFail = (error) => ({
	type: REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const remarksGetDetails = (id) => ({
	type: REMARKS_GET_DETAILS,
	payload: id,
});

export const remarksGetDetailsSuccess = (data) => {
	return ({
		type: REMARKS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const remarksGetDetailsFail = (error) => ({
	type: REMARKS_GET_DETAILS_FAIL,
	payload: error,
});

export const remarksGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'remark',
		params,
	},
});
