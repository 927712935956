import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageWarehouses from 'Pages/warehouses';
import PageWarehouse from 'Pages/warehouse';
import PageWarehouseGroups from 'Pages/warehouse-groups';
import PageWarehouseGroup from 'Pages/warehouse-group';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/group/list`} component={ PageWarehouseGroups } />
			<Route exact path={`${match.url}/group/create`} component={ PageWarehouseGroup } />
			<Route exact path={`${match.url}/group/edit/:warehouseGroupId`} component={ PageWarehouseGroup } />
			<Route exact path={`${match.url}/list`} component={ PageWarehouses } />
			<Route exact path={`${match.url}/edit/:warehouseId`} component={ PageWarehouse } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;