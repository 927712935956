import {
	PAYMENT_METHODS_UPDATE,
	PAYMENT_METHODS_UPDATE_SUCCESS,
	PAYMENT_METHODS_UPDATE_FAIL,
	PAYMENT_METHODS_FIND_ALL,
	PAYMENT_METHODS_FIND_ALL_SUCCESS,
	PAYMENT_METHODS_FIND_ALL_FAIL,
	PAYMENT_METHODS_GET_DETAILS,
	PAYMENT_METHODS_GET_DETAILS_SUCCESS,
	PAYMENT_METHODS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const paymentMethodsUpdate = (data) => ({
	type: PAYMENT_METHODS_UPDATE,
	payload: data,
});

export const paymentMethodsUpdateSuccess = (data) => {
	return ({
		type: PAYMENT_METHODS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const paymentMethodsUpdateFail = (error) => ({
	type: PAYMENT_METHODS_UPDATE_FAIL,
	payload: error,
});

export const paymentMethodsFindAll = () => ({
	type: PAYMENT_METHODS_FIND_ALL,
});

export const paymentMethodsFindAllSuccess = (data) => {
	return ({
		type: PAYMENT_METHODS_FIND_ALL_SUCCESS,
		payload: data,
	});
};

export const paymentMethodsFindAllFail = (error) => ({
	type: PAYMENT_METHODS_FIND_ALL_FAIL,
	payload: error,
});

export const paymentMethodsGetDetails = (code) => ({
	type: PAYMENT_METHODS_GET_DETAILS,
	payload: code,
});

export const paymentMethodsGetDetailsSuccess = (data) => {
	return ({
		type: PAYMENT_METHODS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const paymentMethodsGetDetailsFail = (error) => ({
	type: PAYMENT_METHODS_GET_DETAILS_FAIL,
	payload: error,
});

export const paymentMethodsGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'paymentMethod',
		params,
	},
});

