import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Map, List } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import { Row, Nav, NavItem, TabContent, TabPane, Button } from 'reactstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import PromotionTier from './components/PromotionTier.jsx';
import FormPromotionDetails from './components/FormPromotionDetails.jsx';
import IntlMessages from 'Util/IntlMessages';
import {
	promotionsCreate,
	promotionsUpdate,
	promotionsCopy,
	promotionsGetDetails,
	promotionsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as promotionsReducer from 'Redux/promotions/reducer';

import 'react-datepicker/dist/react-datepicker.css';
import 'Assets/css/vendor/react-datepicker.css';
import 'rc-switch/assets/index.css';

class PagePromotion extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'details',
			promotion: new Map({
				id: null,
				status: 1,
				name: '',
				period_start: null,
				period_end: null,
				level: 0,
				description: '',
				max_app_per_invoice: 0,
				max_app_per_product_line: 0,
				is_exclusive: false,
				warehouse_groups: [],
				tiers: new List(),
			}),
		};
		this.defaults = {
			promotionTier: new Map({
				id: null,
				ordering: 1,
				conditions: new List(),
				benefits: new List(),
			}),
			promotionTierCondition: new Map({
				id: null,
				ordering: 1,
				type: null,
				criteria_type: null,
				total_item_count_operator: null,
				total_item_count_number: null,
				total_amount_operator: null,
				total_amount_number: null,
				parameter: '',
				parameterTo: '',
				parameters: new List(),
				sales_description: null,
			}),
			promotionTierBenefit: new Map({
				id: null,
				ordering: 1,
				type: null,
				number: null,
				number_unit: '%',
				apply_to_operator: null,
				apply_to: null,
				apply_to_max_items: null,
				apply_criteria_type: null,
				hide_line_item: null,
				parameter: '',
				parameterTo: '',
				parameters: new List(),
			}),
		};
		this.breadcrumb = [
			{ id: 'menu.promotion', href: '/promotion/list' },
			{ id: 'promotion.heading', href: `/promotion/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onSelectDetailsTabHandler = this.onSelectTabHandler.bind(this, 'details');
		this.onSelectRulesTabHandler = this.onSelectTabHandler.bind(this, 'rules');
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onClickAddTierHandler = this.onClickAddTierHandler.bind(this);
		this.onChangeTierHandler = this.onChangeTierHandler.bind(this);
		this.onReOrderHandler = this.onReOrderHandler.bind(this);
		this.onCopyHandler = this.onCopyHandler.bind(this);
		this.onCopyPromotionHandler = this.onCopyPromotionHandler.bind(this);
		this.onDeleteHandler = this.onDeleteHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.promotionsGetDetails(id);
		}
		this.props.promotionsGetOptions();
		this.props.errorReset('promotion');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		const prevId = this.getId(prevProps);
		if (id) {
			const prevPromotion = prevProps.promotion.get(id);
			let promotion = this.props.promotion.get(id);
			if (prevId !== id || promotion && promotion !== prevPromotion) {
				promotion.period_start = promotion.period_start ? moment(promotion.period_start) : null;
				promotion.period_end = promotion.period_end ? moment(promotion.period_end) : null;
				promotion.tiers = new List(promotion.tiers ? promotion.tiers.map((tier) => {
					tier.conditions = new List(tier.conditions.map((condition) => {
						if (condition.criteria_type) {
							switch (condition.criteria_type.value) {
							case 1: // Item group
								break;
							case 8: // Coupon Code (Range)
								if (condition.parameters && condition.parameters.length >= 2) {
									condition.parameter = condition.parameters[0];
									condition.parameterTo = condition.parameters[1];
								}
								break;
							default:
								if (condition.parameters && condition.parameters.length >= 1) {
									condition.parameter = condition.parameters[0];
								}
								break;
							}
						}
						condition = this.defaults.promotionTierCondition.merge(condition);
						return condition;
					}));
					tier.benefits = new List(tier.benefits.map((benefit) => {
						if (benefit.apply_criteria_type) {
							switch (benefit.apply_criteria_type.value) {
							case 1: // Item group
								break;
							case 6: // Coupon Code (Range)
								if (benefit.parameters && benefit.parameters.length >= 2) {
									benefit.parameter = benefit.parameters[0];
									benefit.parameterTo = benefit.parameters[1];
								}
								break;
							default:
								if (benefit.parameters && benefit.parameters.length >= 1) {
									benefit.parameter = benefit.parameters[0];
								}
								break;
							}
						}
						benefit = this.defaults.promotionTierBenefit.merge(benefit);
						return benefit;
					}));
					tier = this.defaults.promotionTier.merge(tier);
					return tier;
				}) : undefined);
				promotion = this.state.promotion.merge(promotion);
				this.setState({
					promotion: promotion,
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('promotion');
		const status = this.props.status.get('promotion');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The promotion is ${id ? 'updated' : 'created'} successfully.`,
					`Promotion ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/promotion/edit/${current}`);
			}
				break;
			case 'copied': {
				NotificationManager.success(
					'The promotion is copied successfully.',
					'Promotion Copied'
				);
				this.props.history.push(`/app/promotion/edit/${current}`);
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The promotion is NOT ${id ? 'updated' : 'created'}.`,
					`Promotion ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.promotionId);
	}

	onSelectTabHandler(tab) {
		this.setState({
			activeTab: tab,
		});
	}

	onChangeHandler(field, value) {
		const { promotion } = this.state;
		this.setState({
			promotion: promotion.set(field, value),
		});
	}

	onClickAddTierHandler() {
		const { promotion } = this.state;
		const tiers = promotion.get('tiers');
		this.setState({
			promotion: promotion.set(
				'tiers',
				tiers.push(this.defaults.promotionTier.set('ordering', tiers.size + 1))
			),
		});
	}

	onChangeTierHandler(index, tier) {
		const { promotion } = this.state;
		const tiers = promotion.get('tiers');
		this.setState({
			promotion: promotion.set('tiers', tiers.set(index, tier)),
		});
	}

	onReOrderHandler(index, isNext) {
		const { promotion } = this.state;
		let tiers = promotion.get('tiers');
		let sortFn = (a, b) => {
			const aOrdering = a.get('ordering');
			const bOrdering = b.get('ordering');
			if (aOrdering < bOrdering) {
				return -1;
			} else if (aOrdering > bOrdering) {
				return 1;
			} else {
				return 0;
			}
		};

		if (isNext) {
			tiers = tiers.set(index, tiers.get(index).set('ordering', index + 2));
			if (tiers.size >= index + 1) {
				tiers = tiers.set(index + 1, tiers.get(index + 1).set('ordering', index + 1));
			}
		} else {
			tiers = tiers.set(index, tiers.get(index).set('ordering', index));
			if (index >= 1) {
				tiers = tiers.set(index - 1, tiers.get(index - 1).set('ordering', index + 1));
			}
		}
		tiers = tiers.sort(sortFn);

		this.setState({
			promotion: promotion.set('tiers', tiers),
		});
	}

	onCopyHandler(index) {
		const { promotion } = this.state;
		const tiers = promotion.get('tiers');
		let tier = tiers
			.get(index)
			.set('id', null)
			.set('created_at', null)
			.set('updated_on', null)
			.set('ordering', tiers.size + 1)
		;
		tier = tier
			.set(
				'conditions',
				tier.get('conditions').map((condition) => condition.set('id', null))
			)
			.set(
				'benefits',
				tier.get('benefits').map((benefit) => benefit.set('id', null))
			)
		;
		this.setState({
			promotion: promotion.set('tiers', tiers.push(tier)),
		});
		alert(`Tier ${index + 1} is copied as Tier ${tiers.size + 1}.`);
	}

	onCopyPromotionHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let name = this.state.promotion.get('name');
		if ((name = prompt('Please enter the name of the duplicated promotion:', name))) {
			this.props.promotionsCopy({ id, name });
		}
	}

	onDeleteHandler(index) {
		const { promotion } = this.state;
		const tiers = promotion.get('tiers');
		this.setState({
			promotion: promotion.set(
				'tiers',
				tiers
					.delete(index)
					.map((tier, index) => tier.set('ordering', index + 1))
			),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let promotion = this.state.promotion.toJS();
		promotion.period_start = promotion.period_start ? promotion.period_start.format() : null;
		promotion.period_end = promotion.period_end ? promotion.period_end.format() : null;
		promotion.warehouse_groups = promotion.warehouse_groups.map((warehouse_group) => warehouse_group.value);
		promotion.tiers = promotion.tiers.map((tier) => {
			tier.conditions = tier.conditions.map((condition) => {
				condition.type = condition.type ? condition.type.value : null;
				condition.criteria_type = condition.criteria_type ? condition.criteria_type.value : null;
				switch (condition.criteria_type) {
				case 1: // Item Group
					condition.parameters = condition.parameters ? condition.parameters.map((parameter) => parameter.value) : [];
					break;
				case 8: // Coupon Code (Range)
					condition.parameters = (condition.parameter && condition.parameterTo) ? [condition.parameter, condition.parameterTo] : [];
					break;
				default:
					condition.parameters = condition.parameter ? [condition.parameter] : [];
					break;
				}
				delete condition.parameter;
				delete condition.parameterTo;
				return condition;
			});
			tier.benefits = tier.benefits.map((benefit) => {
				benefit.apply_criteria_type = benefit.apply_criteria_type ? benefit.apply_criteria_type.value : null;
				benefit.apply_to_operator = benefit.apply_to_operator ? benefit.apply_to_operator.value : null;
				benefit.apply_to = benefit.apply_to ? benefit.apply_to.value : null;
				benefit.type = benefit.type ? benefit.type.value : null;
				switch (benefit.apply_criteria_type) {
				case 1: // Item Group
					benefit.parameters = benefit.parameters ? benefit.parameters.map((parameter) => parameter.value) : [];
					break;
				case 6: // Coupon Code (Range)
					benefit.parameters = (benefit.parameter && benefit.parameterTo) ? [benefit.parameter, benefit.parameterTo] : [];
					break;
				default:
					benefit.parameters = benefit.parameter ? [benefit.parameter] : [];
					break;
				}
				delete benefit.parameter;
				delete benefit.parameterTo;
				return benefit;
			});
			return tier;
		});
		if (!id) {
			this.props.promotionsCreate(promotion);
		} else {
			this.props.promotionsUpdate(promotion);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { activeTab, promotion } = this.state;
		const id = this.getId();
		const status = this.props.status.get('promotion');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || status === 'copying' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="9">
						<BreadcrumbContainer
							heading={ <IntlMessages id="promotion.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
					{
						!!id && (
							<Colxx xxs="3">
								<Button size="lg" color="secondary" outline block onClick={ this.onCopyPromotionHandler } disabled={ isLoading }>
									Copy
								</Button>
							</Colxx>
						)
					}
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'details',
									'nav-link': true
								}) }
								onClick={ this.onSelectDetailsTabHandler }
								to="#"
							>
								DETAILS
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'rules',
									'nav-link': true
								}) }
								onClick={ this.onSelectRulesTabHandler }
								to="#"
							>
								RULES
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={ activeTab }>
						<TabPane tabId="details">
							<FormPromotionDetails
								promotion={ promotion }
								options={ options }
								errors={ errors }
								isLoading={ isLoading }
								onChange={ this.onChangeHandler }
								onSubmit={ this.onSubmitHandler }
							/>
						</TabPane>
						<TabPane tabId="rules">
							{
								promotion.get('tiers').map((tier, index) => (
									<PromotionTier
										key={ `page-promotion-tier-${index}` }
										index={ index }
										count={ promotion.get('tiers').size }
										options={ options }
										isLoading={ isLoading }
										defaults={ this.defaults }
										errors={ errors }
										tier={ tier }
										onChange={ this.onChangeTierHandler }
										onReOrder={ this.onReOrderHandler }
										onCopy={ this.onCopyHandler }
										onDelete={ this.onDeleteHandler }
									/>
								))
							}
							{
								promotion.get('tiers').size > 0 && (
									<Separator className="my-5" />
								)
							}
							<div className="text-center">
								<Button color="primary" size="sm" outline onClick={ this.onClickAddTierHandler } disabled={ isLoading }>
									<i className="simple-icon-plus btn-group-icon mr-1" />
									Add Tier
								</Button>
							</div>
						</TabPane>
					</TabContent>


					<div className="mt-4 text-center">
						<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
							SAVE
						</Button>
					</div>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	promotionsReducer.selector,
	{
		promotionsCreate,
		promotionsUpdate,
		promotionsCopy,
		promotionsGetDetails,
		promotionsGetOptions,
		errorReset,
	}
)(PagePromotion);