import Axios from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
	LOGIN_USER,
	REFRESH_ACCESS_TOKEN,
	LOGOUT_USER,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import {
	loginUserSuccess,
	loginUserFail,
	refreshAccessTokenSuccess,
	refreshAccessTokenFail,
} from './actions';

const loginAsync = async (username, password) => {
	const response = await Axios.post(`${apiUrl}/login`, { username, password, backend: true });
	return response.data;
};

function* loginWatcher({ payload }) {
	const { username, password } = payload.user;
	const { history } = payload;
	try {
		const loginUser = yield call(loginAsync, username, password);
		if (!loginUser.message) {
			const timestamp = (new Date()).getTime() / 1000;
			localStorage.setItem('user', JSON.stringify(Object.assign({}, loginUser, { timestamp })));
			yield put(loginUserSuccess(loginUser));
			history.push('/');
		} else {
			yield put(loginUserFail(loginUser.message));
		}
	} catch (error) {
		if (error.response) {
			if (error.response.status === 400 && error.response.data.message) {
				yield put(loginUserFail(error.response.data.message));
			} else {
				yield put(loginUserFail('Invalid response.'));
			}
		} else if (error.request) {
			yield put(loginUserFail('No response was received.'));
		} else {
			yield put(loginUserFail('Error while setting up the request.'));
		}
	}
}

const refreshAccessTokenAsync = async (username) => {
	const response = await Axios.post(`${apiUrl}/refresh`, { username });
	return response.data;
};

function* refreshAccessTokenWatcher({ payload }) {
	const { username } = payload;
	try {
		const loginUser = yield call(refreshAccessTokenAsync, username);
		if (!loginUser.message) {
			const timestamp = (new Date()).getTime() / 1000;
			localStorage.setItem('user', JSON.stringify(Object.assign({}, loginUser, { timestamp })));
			yield put(refreshAccessTokenSuccess(loginUser));
		} else {
			yield put(refreshAccessTokenFail(loginUser.message));
		}
	} catch (error) {
		if (error.response) {
			if (error.response.status === 400 && error.response.data.message) {
				yield put(refreshAccessTokenFail(error.response.data));
			} else {
				yield put(refreshAccessTokenFail({ message: 'Invalid response.' }));
			}
		} else if (error.request) {
			yield put(refreshAccessTokenFail({ message: 'No response was received.' }));
		} else {
			yield put(refreshAccessTokenFail({ message: 'Error while setting up the request.' }));
		}
	}
}

const logoutAsync = () => {
	location.href = '/login';
};

function* logoutWatcher() {
	try {
		localStorage.removeItem('user');
		delete Axios.defaults.headers.common['Authorization'];
		yield call(logoutAsync);
	} catch (error) {
		alert('Logout failed.');
	}
}

export function* watchLoginUser() {
	yield takeEvery(LOGIN_USER, loginWatcher);
}

export function* watchRefreshAccessToken() {
	yield takeEvery(REFRESH_ACCESS_TOKEN, refreshAccessTokenWatcher);
}

export function* watchLogoutUser() {
	yield takeEvery(LOGOUT_USER, logoutWatcher);
}

export default function* rootSaga() {
	yield all([
		fork(watchLoginUser),
		fork(watchRefreshAccessToken),
		fork(watchLogoutUser),
	]);
}