import React, { PureComponent, Fragment } from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import classnames from 'classnames';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import {
	usersFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as usersReducer from 'Redux/users/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageUsers extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.user', href: '/user' },
			{ id: 'menu.user.find-user', href: '/user/list' },
		];
		this.columns = [
			{
				id: 'user_id',
				Header: 'ID',
				accessor: (d) => (
					<NavLink
						to={ `/app/user/edit/${d.user_id}` }
						className="list-item-heading mb-1 truncate w-20 w-xs-100"
					>
						{ d.user_id }
					</NavLink>
				),
			},
			{
				id: 'user_name',
				Header: 'Name',
				accessor: (d) => (
					<p className="text-muted">
						{ d.user_name }
					</p>
				),
			},
			{
				id: 'salesman_pos',
				Header: 'Role',
				accessor: (d) => (
					<p className="text-muted">
						{
							d.salesman_pos && (
								<strong>{ d.salesman_pos }</strong>
							)
						}
						<br />
						{ d.user_dept }
					</p>
				),
			},
			{
				Header: 'Level',
				accessor: 'user_level',
			},
			{
				id: 'status',
				Header: 'Status',
				width: 150,
				accessor: (d) => {
					let className = 'badge-primary';
					let status = d.status;
					switch (status) {
					case 'I':
						className = 'badge-light';
						status = 'Inactive';
						break;
					case 'A':
						className = 'badge-primary';
						status = 'Active';
						break;
					}
					return (
						<Fragment>
							<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ status }</span>
							{
								d.allow_login !== 'Y' && (
									<span className="badge badge-pill badge-danger ml-1">LOCKED</span>
								)
							}
						</Fragment>
					);
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/user/edit/${d.user_id}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('users');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.usersFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { usersByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('users');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="users.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ usersByPage.get('data') }
									pages={ usersByPage.get('max_page') }
									loading={ status === 'fetching' }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	usersReducer.selector,
	{
		usersFindAllByPageAndOptions,
		errorReset,
	}
)(PageUsers);