import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageTransactions from 'Pages/transactions';
import PageTransaction from 'Pages/transaction';
import PageTransactionPrint from 'Pages/transaction-print';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/list`} component={ PageTransactions } />
			<Route exact path={`${match.url}/view/:transactionId`} component={ PageTransaction } />
			<Route exact path={`${match.url}/print/:transactionId`} component={ PageTransactionPrint } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;