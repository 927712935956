import React, { Component, Fragment } from 'react';
import IntlMessages from 'Util/IntlMessages';
import { Row, Card, CardTitle, Button } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';

class Error404 extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.body.classList.add('background');
	}
	componentWillUnmount() {
		document.body.classList.remove('background');
	}
	render() {
		return (
			<Fragment>
				<div className="fixed-background" />
				<main>
					<div className="container">
						<Row className="h-100">
							<Colxx xxs="12" md="10" className="mx-auto my-auto">
								<Card className="auth-card">
									<div className="position-relative image-side" />
									<div className="form-side">
										<CardTitle className="mb-4">
											<IntlMessages id="error.heading" />
										</CardTitle>
										<p className="mb-0 text-muted text-small mb-0">
											<IntlMessages id="error.code" />
										</p>
										<p className="display-1 font-weight-bold mb-5">404</p>
										<Button
											href="/app"
											color="primary"
											className="btn-shadow"
											size="lg"
										>
											<IntlMessages id="error.go-back-home" />
										</Button>
									</div>
								</Card>
							</Colxx>
						</Row>
					</div>
				</main>
			</Fragment>
		);
	}
}
export default Error404;
