import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	REMARKS_CREATE,
	REMARKS_CREATE_SUCCESS,
	REMARKS_CREATE_FAIL,
	REMARKS_UPDATE,
	REMARKS_UPDATE_SUCCESS,
	REMARKS_UPDATE_FAIL,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	REMARKS_GET_DETAILS,
	REMARKS_GET_DETAILS_SUCCESS,
	REMARKS_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case REMARKS_CREATE:
	case REMARKS_UPDATE:
		return state.set('remark', 'submitting');
	case REMARKS_CREATE_SUCCESS:
	case REMARKS_UPDATE_SUCCESS:
		return state.set('remark', 'submitted');
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('remarks', 'fetching');
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('remarks', 'fetched');
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('remarks', 'has-errors');
	case REMARKS_GET_DETAILS:
		return state.set('remark', 'fetching');
	case REMARKS_GET_DETAILS_SUCCESS:
		return state.set('remark', 'fetched');
	case REMARKS_CREATE_FAIL:
	case REMARKS_UPDATE_FAIL:
	case REMARKS_GET_DETAILS_FAIL:
		return state.set('remark', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'remark' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'remark' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'remark' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case REMARKS_CREATE:
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case REMARKS_CREATE_SUCCESS:
	case REMARKS_UPDATE_SUCCESS:
	case REMARKS_GET_DETAILS_SUCCESS:
		return action.payload.code;
	default:
		return state;
	}
};

const remarksByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const remarkReducer = (state = new Map(), action) => {
	switch (action.type) {
	case REMARKS_GET_DETAILS:
		return new Map();
	case REMARKS_CREATE_SUCCESS:
	case REMARKS_UPDATE_SUCCESS:
	case REMARKS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.code, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'remark' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'remark' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status        : statusReducer,
	current       : currentReducer,
	remarksByPage : remarksByPageReducer,
	remark        : remarkReducer,
	options       : optionsReducer,
});

export const selector = createStructuredSelector({
	status        : (state) => state.remarks.status,
	current       : (state) => state.remarks.current,
	remarksByPage : (state) => state.remarks.remarksByPage,
	remark        : (state) => state.remarks.remark,
	options       : (state) => state.remarks.options,
	errors        : (state) => state.errors.get('remark'),
});