import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	ITEM_GROUPS_CREATE,
	ITEM_GROUPS_UPDATE,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	ITEM_GROUPS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	itemGroupsCreateSuccess,
	itemGroupsCreateFail,
	itemGroupsUpdateSuccess,
	itemGroupsUpdateFail,
	itemGroupsFindAllByPageAndOptionsSuccess,
	itemGroupsFindAllByPageAndOptionsFail,
	itemGroupsGetDetailsSuccess,
	itemGroupsGetDetailsFail,
} from './actions';

export function* watchItemGroupsCreate() {
	yield takeEvery(ITEM_GROUPS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/itemGroup/create`, data);
			return response.data;
		},
		itemGroupsCreateSuccess,
		itemGroupsCreateFail
	));
}

export function* watchItemGroupsUpdate() {
	yield takeEvery(ITEM_GROUPS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/itemGroup/update`, data);
			return response.data;
		},
		itemGroupsUpdateSuccess,
		itemGroupsUpdateFail
	));
}

export function* watchItemGroupsFindAllByPageAndOptions() {
	yield takeEvery(ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/itemGroup/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		itemGroupsFindAllByPageAndOptionsSuccess,
		itemGroupsFindAllByPageAndOptionsFail
	));
}

export function* watchItemGroupsGetDetails() {
	yield takeEvery(ITEM_GROUPS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/itemGroup/details`, { params: { id: data } });
			return response.data;
		},
		itemGroupsGetDetailsSuccess,
		itemGroupsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchItemGroupsCreate),
		fork(watchItemGroupsUpdate),
		fork(watchItemGroupsFindAllByPageAndOptions),
		fork(watchItemGroupsGetDetails),
	]);
}