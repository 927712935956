import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import {
	remarksFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as remarksReducer from 'Redux/remarks/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageRemarks extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.remark', href: '/remark/list' },
		];
		this.columns = [
			{
				id: 'code',
				Header: 'Remark Code',
				accessor: (d) => (
					<NavLink to={ `/app/remark/edit/${d.code}` }>
						{ d.code }
					</NavLink>
				),
			},
			{
				Header: 'Doc Type',
				accessor: 'type',
			},
			{
				Header: 'Description',
				accessor: 'content',
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/remark/edit/${d.code}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('remarks');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.remarksFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { remarksByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('remarks');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="float-left">
							<BreadcrumbContainer
								heading={ <IntlMessages id="remark.heading" /> }
								items={ this.breadcrumb }
								match={ this.props.match }
							/>
						</div>
						<div className="float-right">
							<NavLink to="/app/remark/create" className="btn btn-lg btn-primary">
								ADD REMARK TEMPLATE
							</NavLink>
						</div>
						<div className="clearfix"></div>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ remarksByPage.get('data') }
									pages={ remarksByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	remarksReducer.selector,
	{
		remarksFindAllByPageAndOptions,
		errorReset,
	}
)(PageRemarks);