import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	PAYMENT_METHODS_UPDATE,
	PAYMENT_METHODS_FIND_ALL,
	PAYMENT_METHODS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	paymentMethodsUpdateSuccess,
	paymentMethodsUpdateFail,
	paymentMethodsFindAllSuccess,
	paymentMethodsFindAllFail,
	paymentMethodsGetDetailsSuccess,
	paymentMethodsGetDetailsFail,
} from './actions';

export function* watchPaymentMethodsUpdate() {
	yield takeEvery(PAYMENT_METHODS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/paymentMethod/update`, data);
			return response.data;
		},
		paymentMethodsUpdateSuccess,
		paymentMethodsUpdateFail
	));
}

export function* watchPaymentMethodsFindAll() {
	yield takeEvery(PAYMENT_METHODS_FIND_ALL, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/paymentMethod/findAll`, data);
			return response.data;
		},
		paymentMethodsFindAllSuccess,
		paymentMethodsFindAllFail
	));
}

export function* watchPaymentMethodsGetDetails() {
	yield takeEvery(PAYMENT_METHODS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/paymentMethod/details`, { params: { code: data } });
			return response.data;
		},
		paymentMethodsGetDetailsSuccess,
		paymentMethodsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchPaymentMethodsUpdate),
		fork(watchPaymentMethodsFindAll),
		fork(watchPaymentMethodsGetDetails),
	]);
}