import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageUserRoles from 'Pages/user-roles';
import PageUserRole from 'Pages/user-role';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/list`} component={ PageUserRoles } />
			<Route exact path={`${match.url}/create`} component={ PageUserRole } />
			<Route exact path={`${match.url}/edit/:userRoleId`} component={ PageUserRole } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;