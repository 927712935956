import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import CustomSelectInput from 'Components/CustomSelectInput';

class CustomFormGroup extends PureComponent {
	renderInput() {
		const { id, type, value, disabled, errors, onChange, options, isMulti, extraProps } = this.props;
		switch (type) {
		case 'react-select':
			return (
				<Select
					components={{ Input: CustomSelectInput }}
					className={ classnames('react-select', errors && 'is-invalid') }
					classNamePrefix="react-select"
					value={ value }
					isDisabled={ disabled }
					isClearable={ true }
					onChange={ onChange }
					options={ options }
					isMulti={ isMulti }
					{ ...extraProps }
				/>
			);
		default:
			return (
				<Input
					type={ type }
					id={ id }
					value={ (value === undefined || value === null) ? '' : value }
					disabled={ disabled }
					onChange={ onChange }
					invalid={ !!errors }
					{ ...extraProps }
				/>
			);
		}
	}

	render() {
		const { id, label, errors } = this.props;
		return (
			<FormGroup>
				<Label for={ id }>{ label }</Label>

				{ this.renderInput() }
				{
					errors && (
						<div className="invalid-feedback d-block">
							{
								errors.map((error, index) => (
									<Fragment key={ `custom-form-group-${id}-error-${index}` }>
										{ index > 0 && <br /> }
										{ error }
									</Fragment>
								))
							}
						</div>
					)
				}
			</FormGroup>
		);
	}
}

CustomFormGroup.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
	disabled: PropTypes.bool.isRequired,
	errors: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
	extraProps: PropTypes.object,
};

export default CustomFormGroup;