import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	USER_ROLES_CREATE,
	USER_ROLES_UPDATE,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USER_ROLES_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	userRolesCreateSuccess,
	userRolesCreateFail,
	userRolesUpdateSuccess,
	userRolesUpdateFail,
	userRolesFindAllByPageAndOptionsSuccess,
	userRolesFindAllByPageAndOptionsFail,
	userRolesGetDetailsSuccess,
	userRolesGetDetailsFail,
} from './actions';

export function* watchUserRolesCreate() {
	yield takeEvery(USER_ROLES_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/userRole/create`, data);
			return response.data;
		},
		userRolesCreateSuccess,
		userRolesCreateFail
	));
}

export function* watchUserRolesUpdate() {
	yield takeEvery(USER_ROLES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/userRole/update`, data);
			return response.data;
		},
		userRolesUpdateSuccess,
		userRolesUpdateFail
	));
}

export function* watchUserRolesFindAllByPageAndOptions() {
	yield takeEvery(USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/userRole/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		userRolesFindAllByPageAndOptionsSuccess,
		userRolesFindAllByPageAndOptionsFail
	));
}

export function* watchUserRolesGetDetails() {
	yield takeEvery(USER_ROLES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/userRole/details`, { params: { id: data } });
			return response.data;
		},
		userRolesGetDetailsSuccess,
		userRolesGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchUserRolesCreate),
		fork(watchUserRolesUpdate),
		fork(watchUserRolesFindAllByPageAndOptions),
		fork(watchUserRolesGetDetails),
	]);
}