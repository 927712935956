import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	REFRESH_ACCESS_TOKEN,
	REFRESH_ACCESS_TOKEN_SUCCESS,
	REFRESH_ACCESS_TOKEN_FAIL,
	LOGOUT_USER,
} from 'Constants/actionTypes';

export const loginUser = (user, history) => ({
	type: LOGIN_USER,
	payload: { user, history }
});

export const loginUserSuccess = (user) => ({
	type: LOGIN_USER_SUCCESS,
	payload: user
});

export const loginUserFail = (message) => ({
	type: LOGIN_USER_FAIL,
	payload: message
});

export const refreshAccessToken = (username) => ({
	type: REFRESH_ACCESS_TOKEN,
	payload: { username }
});

export const refreshAccessTokenSuccess = (user) => ({
	type: REFRESH_ACCESS_TOKEN_SUCCESS,
	payload: user
});

export const refreshAccessTokenFail = (message) => ({
	type: REFRESH_ACCESS_TOKEN_FAIL,
	payload: message
});

export const logoutUser = (history) => ({
	type: LOGOUT_USER,
	payload : {history}
});
