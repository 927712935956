import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardBody, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { Map } from 'immutable';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Switch from 'rc-switch';
import CustomSelectInput from 'Components/CustomSelectInput';

class FormPromotionDetails extends PureComponent {
	constructor() {
		super();
		this.onChangeTextHandler = this.onChangeTextHandler.bind(this);
		this.onChangeWarehouseGroupsHandler = this.onChangeWarehouseGroupsHandler.bind(this);
		this.onChangePeriodStartHandler = this.onChangePeriodStartHandler.bind(this);
		this.onChangePeriodEndHandler = this.onChangePeriodEndHandler.bind(this);
		this.onChangeStatusHandler = this.onChangeStatusHandler.bind(this);
		this.onChangeIsExclusiveHandler = this.onChangeIsExclusiveHandler.bind(this);
		this.renderErrors = this.renderErrors.bind(this);
	}

	onChangeTextHandler(e) {
		let value;
		switch (e.target.id) {
		case 'level':
		case 'max_app_per_invoice':
		case 'max_app_per_product_line':
			value = parseInt(e.target.value);
			if (isNaN(value)) {
				value = 0;
			}
			break;
		default:
			value = e.target.value;
			break;
		}
		this.props.onChange(e.target.id, value);
	}

	onChangeWarehouseGroupsHandler(value) {
		this.props.onChange('warehouse_groups', value);
	}

	onChangePeriodStartHandler(value) {
		value = value.set({
			hour: 0,
			minute: 0,
			second: 0,
		});
		this.props.onChange('period_start', value);
	}

	onChangePeriodEndHandler(value) {
		value = value.set({
			hour: 23,
			minute: 59,
			second: 59,
		});
		this.props.onChange('period_end', value);
	}

	onChangeStatusHandler() {
		const { promotion } = this.props;
		const value = promotion.get('status') ? 0 : 1;
		this.props.onChange('status', value);
	}

	onChangeIsExclusiveHandler() {
		const { promotion } = this.props;
		const value = !promotion.get('is_exclusive');
		this.props.onChange('is_exclusive', value);
	}

	renderErrors(id) {
		let { errors } = this.props;
		if (errors && errors.detail && errors.detail[id]) {
			errors = errors.detail[id];
			return (
				<div className="invalid-feedback d-block">
					{
						errors.map((error, index) => (
							<Fragment key={ `form-promotion-details-${id}-error-${index}` }>
								{ index > 0 && <br /> }
								{ error }
							</Fragment>
						))
					}
				</div>
			);
		}
		return null;
	}

	render() {
		const { promotion, options, isLoading } = this.props;
		return (
			<Form onSubmit={ this.onSubmitHandler }>
				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<h5 className="mb-4">Promotion Overall Settings</h5>

								<Row>
									<Colxx sm="6">
										<Label for="name" className="form-group has-float-label">
											<Input
												type="text"
												id="name"
												value={ promotion.get('name') }
												disabled={ isLoading || !!promotion.get('id') }
												onChange={ this.onChangeTextHandler }
												placeholder="Promotion ID"
											/>
											<span>Promotion ID</span>
											{ this.renderErrors('name') }
										</Label>

										<Row>
											<Colxx xxs="6">
												<Label for="period_start" className="form-group has-float-label">
													<DatePicker
														selected={ promotion.get('period_start') }
														selectsStart
														startDate={ promotion.get('period_start') }
														endDate={ promotion.get('period_end') }
														onChange={ this.onChangePeriodStartHandler }
														placeholderText="Start"
														dateFormat="YYYY-MM-DD HH:mm:ss"
													/>
													<span>Promotion Period</span>
													{ this.renderErrors('period_start') }
												</Label>
											</Colxx>
											<Colxx xxs="6">
												<Label for="period_end" className="form-group has-float-label">
													<DatePicker
														selected={ promotion.get('period_end') }
														selectsEnd
														startDate={ promotion.get('period_start') }
														endDate={ promotion.get('period_end') }
														onChange={ this.onChangePeriodEndHandler }
														placeholderText="End"
														dateFormat="YYYY-MM-DD HH:mm:ss"
													/>
													{ this.renderErrors('period_end') }
												</Label>
											</Colxx>
										</Row>

										<Label for="level" className="form-group has-float-label">
											<Input
												type="text"
												id="level"
												value={ promotion.get('level') }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												placeholder="0 - 99"
											/>
											<span>Promotion Level</span>
											{ this.renderErrors('level') }
											<small className="form-text text-muted">
												Integer starting from 0. Promotion will be applied in <strong>ascending order</strong> of promotion level.
											</small>
										</Label>
									</Colxx>
									<Colxx sm="6">
										<Label for="description" className="form-group has-float-label">
											<Input
												type="textarea"
												id="description"
												value={ promotion.get('description') || '' }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												rows="7"
											/>
											<span>Description</span>
											{ this.renderErrors('description') }
										</Label>
									</Colxx>
								</Row>

								<Separator className="mb-5" />

								<Row>
									<Colxx sm="12">
										<Label for="warehouse_groups" className="form-group has-float-label">
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"
												value={ promotion.get('warehouse_groups') }
												isDisabled={ isLoading }
												isMulti
												onChange={ this.onChangeWarehouseGroupsHandler }
												options={ options.get('warehouse_groups') }
											/>
											<span>Warehouse groups</span>
											{ this.renderErrors('warehouse_groups') }
										</Label>
									</Colxx>
								</Row>

								<Row>
									<Colxx sm="6">
										<Label for="max_app_per_invoice" className="form-group has-float-label">
											<Input
												type="text"
												id="max_app_per_invoice"
												value={ promotion.get('max_app_per_invoice') }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												placeholder="Max per invoice"
											/>
											<span>Max application/calculation per invoice</span>
											{ this.renderErrors('max_app_per_invoice') }
										</Label>
									</Colxx>
									<Colxx sm="6">
										<Label for="max_app_per_product_line" className="form-group has-float-label">
											<Input
												type="text"
												id="max_app_per_product_line"
												value={ promotion.get('max_app_per_product_line') }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												placeholder="Max per line"
											/>
											<span>Max application/calculation per product line</span>
											{ this.renderErrors('max_app_per_product_line') }
										</Label>
									</Colxx>
								</Row>

								<Row>
									<Colxx xxs="12">
										<FormGroup>
											<Label>Activate</Label>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={ promotion.get('status') == 1 }
												onChange={ this.onChangeStatusHandler }
											/>
										</FormGroup>
									</Colxx>
								</Row>

								<Row>
									<Colxx xxs="12">
										<CustomInput
											type="checkbox"
											id="is_exclusive"
											label="Exclusive Promotion (cannot use with other promotions)"
											checked={ promotion.get('is_exclusive') }
											onChange={ this.onChangeIsExclusiveHandler }
										/>
									</Colxx>
								</Row>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Form>
		);
	}
}

FormPromotionDetails.propTypes = {
	promotion: PropTypes.instanceOf(Map).isRequired,
	options: PropTypes.instanceOf(Map).isRequired,
	errors: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default FormPromotionDetails;
