import {
	USERS_READ_CURRENT,
	USERS_READ_CURRENT_SUCCESS,
	USERS_READ_CURRENT_FAIL,
	USERS_ASSIGN,
	USERS_ASSIGN_SUCCESS,
	USERS_ASSIGN_FAIL,
	USERS_UNLOCK,
	USERS_UNLOCK_SUCCESS,
	USERS_UNLOCK_FAIL,
	USERS_CLEAR_2FA_SECRET,
	USERS_CLEAR_2FA_SECRET_SUCCESS,
	USERS_CLEAR_2FA_SECRET_FAIL,
	USERS_RESET_2FA_SECRET,
	USERS_RESET_2FA_SECRET_SUCCESS,
	USERS_RESET_2FA_SECRET_FAIL,
	USERS_CHANGE_PASSWORD,
	USERS_CHANGE_PASSWORD_SUCCESS,
	USERS_CHANGE_PASSWORD_FAIL,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USERS_GET_DETAILS,
	USERS_GET_DETAILS_SUCCESS,
	USERS_GET_DETAILS_FAIL,
	USERS_GET_PERMISSIONS,
	USERS_GET_PERMISSIONS_SUCCESS,
	USERS_GET_PERMISSIONS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const usersReadCurrent = () => ({
	type: USERS_READ_CURRENT,
});

export const usersReadCurrentSuccess = (current) => {
	return ({
		type: USERS_READ_CURRENT_SUCCESS,
		payload: current,
	});
};

export const usersReadCurrentFail = (error) => ({
	type: USERS_READ_CURRENT_FAIL,
	payload: error,
});

export const usersAssign = (data) => ({
	type: USERS_ASSIGN,
	payload: data,
});

export const usersAssignSuccess = (data) => {
	return ({
		type: USERS_ASSIGN_SUCCESS,
		payload: data,
	});
};

export const usersAssignFail = (error) => ({
	type: USERS_ASSIGN_FAIL,
	payload: error,
});

export const usersUnlock = (data) => ({
	type: USERS_UNLOCK,
	payload: data,
});

export const usersUnlockSuccess = (data) => {
	return ({
		type: USERS_UNLOCK_SUCCESS,
		payload: data,
	});
};

export const usersUnlockFail = (error) => ({
	type: USERS_UNLOCK_FAIL,
	payload: error,
});

export const usersClear2FASecret = (data) => ({
	type: USERS_CLEAR_2FA_SECRET,
	payload: data,
});

export const usersClear2FASecretSuccess = (data) => {
	return ({
		type: USERS_CLEAR_2FA_SECRET_SUCCESS,
		payload: data,
	});
};

export const usersClear2FASecretFail = (error) => ({
	type: USERS_CLEAR_2FA_SECRET_FAIL,
	payload: error,
});

export const usersReset2FASecret = (data) => ({
	type: USERS_RESET_2FA_SECRET,
	payload: data,
});

export const usersReset2FASecretSuccess = (data) => {
	return ({
		type: USERS_RESET_2FA_SECRET_SUCCESS,
		payload: data,
	});
};

export const usersReset2FASecretFail = (error) => ({
	type: USERS_RESET_2FA_SECRET_FAIL,
	payload: error,
});

export const usersChangePassword = (data) => ({
	type: USERS_CHANGE_PASSWORD,
	payload: data,
});

export const usersChangePasswordSuccess = (data) => {
	return ({
		type: USERS_CHANGE_PASSWORD_SUCCESS,
		payload: data,
	});
};

export const usersChangePasswordFail = (error) => ({
	type: USERS_CHANGE_PASSWORD_FAIL,
	payload: error,
});

export const usersFindAllByPageAndOptions = (params) => ({
	type: USERS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const usersFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const usersFindAllByPageAndOptionsFail = (error) => ({
	type: USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const usersGetDetails = (id) => ({
	type: USERS_GET_DETAILS,
	payload: id,
});

export const usersGetDetailsSuccess = (data) => {
	return ({
		type: USERS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const usersGetDetailsFail = (error) => ({
	type: USERS_GET_DETAILS_FAIL,
	payload: error,
});

export const usersGetPermissions = (id) => ({
	type: USERS_GET_PERMISSIONS,
	payload: id,
});

export const usersGetPermissionsSuccess = (data) => {
	return ({
		type: USERS_GET_PERMISSIONS_SUCCESS,
		payload: data,
	});
};

export const usersGetPermissionsFail = (error) => ({
	type: USERS_GET_PERMISSIONS_FAIL,
	payload: error,
});

export const usersGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'user',
		params,
	},
});
