import {
	COMPANIES_CREATE,
	COMPANIES_CREATE_SUCCESS,
	COMPANIES_CREATE_FAIL,
	COMPANIES_UPDATE,
	COMPANIES_UPDATE_SUCCESS,
	COMPANIES_UPDATE_FAIL,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	COMPANIES_GET_DETAILS,
	COMPANIES_GET_DETAILS_SUCCESS,
	COMPANIES_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const companiesCreate = (data) => ({
	type: COMPANIES_CREATE,
	payload: data,
});

export const companiesCreateSuccess = (data) => {
	return ({
		type: COMPANIES_CREATE_SUCCESS,
		payload: data,
	});
};

export const companiesCreateFail = (error) => ({
	type: COMPANIES_CREATE_FAIL,
	payload: error,
});

export const companiesUpdate = (data) => ({
	type: COMPANIES_UPDATE,
	payload: data,
});

export const companiesUpdateSuccess = (data) => {
	return ({
		type: COMPANIES_UPDATE_SUCCESS,
		payload: data,
	});
};

export const companiesUpdateFail = (error) => ({
	type: COMPANIES_UPDATE_FAIL,
	payload: error,
});

export const companiesFindAllByPageAndOptions = (params) => ({
	type: COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const companiesFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const companiesFindAllByPageAndOptionsFail = (error) => ({
	type: COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const companiesGetDetails = (id) => ({
	type: COMPANIES_GET_DETAILS,
	payload: id,
});

export const companiesGetDetailsSuccess = (data) => {
	return ({
		type: COMPANIES_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const companiesGetDetailsFail = (error) => ({
	type: COMPANIES_GET_DETAILS_FAIL,
	payload: error,
});

export const companiesGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'company',
		params,
	},
});
