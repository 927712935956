import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	ITEM_GROUPS_CREATE,
	ITEM_GROUPS_CREATE_SUCCESS,
	ITEM_GROUPS_CREATE_FAIL,
	ITEM_GROUPS_UPDATE,
	ITEM_GROUPS_UPDATE_SUCCESS,
	ITEM_GROUPS_UPDATE_FAIL,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	ITEM_GROUPS_GET_DETAILS,
	ITEM_GROUPS_GET_DETAILS_SUCCESS,
	ITEM_GROUPS_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case ITEM_GROUPS_CREATE:
	case ITEM_GROUPS_UPDATE:
		return state.set('itemGroup', 'submitting');
	case ITEM_GROUPS_CREATE_SUCCESS:
	case ITEM_GROUPS_UPDATE_SUCCESS:
		return state.set('itemGroup', 'submitted');
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('itemGroups', 'fetching');
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('itemGroups', 'fetched');
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('itemGroups', 'has-errors');
	case ITEM_GROUPS_GET_DETAILS:
		return state.set('itemGroup', 'fetching');
	case ITEM_GROUPS_GET_DETAILS_SUCCESS:
		return state.set('itemGroup', 'fetched');
	case ITEM_GROUPS_CREATE_FAIL:
	case ITEM_GROUPS_UPDATE_FAIL:
	case ITEM_GROUPS_GET_DETAILS_FAIL:
		return state.set('itemGroup', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'itemGroup' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'itemGroup' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'itemGroup' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case ITEM_GROUPS_CREATE:
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case ITEM_GROUPS_CREATE_SUCCESS:
	case ITEM_GROUPS_UPDATE_SUCCESS:
	case ITEM_GROUPS_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const itemGroupsByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const itemGroupReducer = (state = new Map(), action) => {
	switch (action.type) {
	case ITEM_GROUPS_GET_DETAILS:
		return new Map();
	case ITEM_GROUPS_CREATE_SUCCESS:
	case ITEM_GROUPS_UPDATE_SUCCESS:
	case ITEM_GROUPS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'itemGroup' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'itemGroup' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status           : statusReducer,
	current          : currentReducer,
	itemGroupsByPage : itemGroupsByPageReducer,
	itemGroup        : itemGroupReducer,
	options          : optionsReducer,
});

export const selector = createStructuredSelector({
	status           : (state) => state.itemGroups.status,
	current          : (state) => state.itemGroups.current,
	itemGroupsByPage : (state) => state.itemGroups.itemGroupsByPage,
	itemGroup        : (state) => state.itemGroups.itemGroup,
	options          : (state) => state.itemGroups.options,
	errors           : (state) => state.errors.get('itemGroup'),
});