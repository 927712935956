import {
	USER_ROLES_CREATE,
	USER_ROLES_CREATE_SUCCESS,
	USER_ROLES_CREATE_FAIL,
	USER_ROLES_UPDATE,
	USER_ROLES_UPDATE_SUCCESS,
	USER_ROLES_UPDATE_FAIL,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USER_ROLES_GET_DETAILS,
	USER_ROLES_GET_DETAILS_SUCCESS,
	USER_ROLES_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const userRolesCreate = (data) => ({
	type: USER_ROLES_CREATE,
	payload: data,
});

export const userRolesCreateSuccess = (data) => {
	return ({
		type: USER_ROLES_CREATE_SUCCESS,
		payload: data,
	});
};

export const userRolesCreateFail = (error) => ({
	type: USER_ROLES_CREATE_FAIL,
	payload: error,
});

export const userRolesUpdate = (data) => ({
	type: USER_ROLES_UPDATE,
	payload: data,
});

export const userRolesUpdateSuccess = (data) => {
	return ({
		type: USER_ROLES_UPDATE_SUCCESS,
		payload: data,
	});
};

export const userRolesUpdateFail = (error) => ({
	type: USER_ROLES_UPDATE_FAIL,
	payload: error,
});

export const userRolesFindAllByPageAndOptions = (params) => ({
	type: USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const userRolesFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const userRolesFindAllByPageAndOptionsFail = (error) => ({
	type: USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const userRolesGetDetails = (id) => ({
	type: USER_ROLES_GET_DETAILS,
	payload: id,
});

export const userRolesGetDetailsSuccess = (data) => {
	return ({
		type: USER_ROLES_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const userRolesGetDetailsFail = (error) => ({
	type: USER_ROLES_GET_DETAILS_FAIL,
	payload: error,
});

export const userRolesGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'userRole',
		params,
	},
});

