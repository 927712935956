import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	ERROR_RESET,
	LOGIN_USER,
	LOGIN_USER_FAIL,
	REFRESH_ACCESS_TOKEN,
	REFRESH_ACCESS_TOKEN_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_FAIL,
	USERS_READ_CURRENT,
	USERS_READ_CURRENT_FAIL,
	USERS_ASSIGN,
	USERS_ASSIGN_FAIL,
	USERS_UNLOCK,
	USERS_UNLOCK_FAIL,
	USERS_CLEAR_2FA_SECRET,
	USERS_CLEAR_2FA_SECRET_FAIL,
	USERS_RESET_2FA_SECRET,
	USERS_RESET_2FA_SECRET_FAIL,
	USERS_CHANGE_PASSWORD,
	USERS_CHANGE_PASSWORD_FAIL,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USERS_GET_DETAILS,
	USERS_GET_DETAILS_FAIL,
	USERS_GET_PERMISSIONS,
	USERS_GET_PERMISSIONS_FAIL,
	USER_ROLES_CREATE,
	USER_ROLES_CREATE_FAIL,
	USER_ROLES_UPDATE,
	USER_ROLES_UPDATE_FAIL,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USER_ROLES_GET_DETAILS,
	USER_ROLES_GET_DETAILS_FAIL,
	COMPANIES_CREATE,
	COMPANIES_CREATE_FAIL,
	COMPANIES_UPDATE,
	COMPANIES_UPDATE_FAIL,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	COMPANIES_GET_DETAILS,
	COMPANIES_GET_DETAILS_FAIL,
	WAREHOUSES_UPDATE,
	WAREHOUSES_UPDATE_FAIL,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	WAREHOUSES_GET_DETAILS,
	WAREHOUSES_GET_DETAILS_FAIL,
	WAREHOUSES_GET_IP_WHITELISTS,
	WAREHOUSES_GET_IP_WHITELISTS_FAIL,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL,
	WAREHOUSE_GROUPS_CREATE,
	WAREHOUSE_GROUPS_CREATE_FAIL,
	WAREHOUSE_GROUPS_UPDATE,
	WAREHOUSE_GROUPS_UPDATE_FAIL,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	WAREHOUSE_GROUPS_GET_DETAILS,
	WAREHOUSE_GROUPS_GET_DETAILS_FAIL,
	PAYMENT_METHODS_UPDATE,
	PAYMENT_METHODS_UPDATE_FAIL,
	PAYMENT_METHODS_FIND_ALL,
	PAYMENT_METHODS_FIND_ALL_FAIL,
	PAYMENT_METHODS_GET_DETAILS,
	PAYMENT_METHODS_GET_DETAILS_FAIL,
	ITEM_GROUPS_CREATE,
	ITEM_GROUPS_CREATE_FAIL,
	ITEM_GROUPS_UPDATE,
	ITEM_GROUPS_UPDATE_FAIL,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	ITEM_GROUPS_GET_DETAILS,
	ITEM_GROUPS_GET_DETAILS_FAIL,
	PROMOTIONS_CREATE,
	PROMOTIONS_CREATE_FAIL,
	PROMOTIONS_UPDATE,
	PROMOTIONS_UPDATE_FAIL,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	PROMOTIONS_GET_DETAILS,
	PROMOTIONS_GET_DETAILS_FAIL,
	MESSAGES_CREATE,
	MESSAGES_CREATE_FAIL,
	MESSAGES_UPDATE,
	MESSAGES_UPDATE_FAIL,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MESSAGES_GET_DETAILS,
	MESSAGES_GET_DETAILS_FAIL,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	TRANSACTIONS_GET_DETAILS,
	TRANSACTIONS_GET_DETAILS_FAIL,
	CONFIGS_FIND_ALL_BY_GROUP,
	CONFIGS_FIND_ALL_BY_GROUP_FAIL,
	CONFIGS_BATCH_SET_CONFIG,
	CONFIGS_BATCH_SET_CONFIG_FAIL,
	MAX_BRAND_DISCOUNTS_CREATE,
	MAX_BRAND_DISCOUNTS_CREATE_FAIL,
	MAX_BRAND_DISCOUNTS_UPDATE,
	MAX_BRAND_DISCOUNTS_UPDATE_FAIL,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MAX_BRAND_DISCOUNTS_GET_DETAILS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL,
	REMARKS_CREATE,
	REMARKS_CREATE_FAIL,
	REMARKS_UPDATE,
	REMARKS_UPDATE_FAIL,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	REMARKS_GET_DETAILS,
	REMARKS_GET_DETAILS_FAIL,
} from 'Constants/actionTypes';

const errorsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case ERROR_RESET:
		return state.delete(action.payload);
	/* Login */
	case LOGIN_USER:
		return new Map();
	case LOGIN_USER_FAIL:
		return state.set('login', action.payload);
	case REFRESH_ACCESS_TOKEN:
		return new Map();
	case REFRESH_ACCESS_TOKEN_FAIL:
		return state.set('login', action.payload);
	/* Options */
	case OPTIONS_GET:
		return state.delete('options');
	case OPTIONS_GET_FAIL:
		return state.set('options', action.payload);
	/* Users */
	case USERS_READ_CURRENT:
		return state.delete('current');
	case USERS_READ_CURRENT_FAIL:
		return state.set('current', action.payload);
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('users');
	case USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('users', action.payload);
	case USERS_ASSIGN:
	case USERS_UNLOCK:
	case USERS_CLEAR_2FA_SECRET:
	case USERS_RESET_2FA_SECRET:
		return state.delete('user');
	case USERS_GET_DETAILS:
		return state.delete('user').delete('password');
	case USERS_ASSIGN_FAIL:
	case USERS_UNLOCK_FAIL:
	case USERS_CLEAR_2FA_SECRET_FAIL:
	case USERS_RESET_2FA_SECRET_FAIL:
	case USERS_GET_DETAILS_FAIL:
		return state.set('user', action.payload);
	case USERS_CHANGE_PASSWORD:
		return state.delete('password');
	case USERS_CHANGE_PASSWORD_FAIL:
		return state.set('password', action.payload);
	case USERS_GET_PERMISSIONS:
		return state.delete('permissions');
	case USERS_GET_PERMISSIONS_FAIL:
		return state.set('permissions', action.payload);
	/* User roles */
	case USER_ROLES_CREATE:
	case USER_ROLES_UPDATE:
	case USER_ROLES_GET_DETAILS:
		return state.delete('userRole');
	case USER_ROLES_CREATE_FAIL:
	case USER_ROLES_UPDATE_FAIL:
	case USER_ROLES_GET_DETAILS_FAIL:
		return state.set('userRole', action.payload);
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('userRoles');
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('userRoles', action.payload);
	/* Companies */
	case COMPANIES_CREATE:
	case COMPANIES_UPDATE:
	case COMPANIES_GET_DETAILS:
		return state.delete('company');
	case COMPANIES_CREATE_FAIL:
	case COMPANIES_UPDATE_FAIL:
	case COMPANIES_GET_DETAILS_FAIL:
		return state.set('company', action.payload);
	case COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('companies');
	case COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('companies', action.payload);
	/* Warehouses */
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('warehouses');
	case WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('warehouses', action.payload);
	case WAREHOUSES_UPDATE:
	case WAREHOUSES_GET_DETAILS:
		return state.delete('warehouse');
	case WAREHOUSES_UPDATE_FAIL:
	case WAREHOUSES_GET_DETAILS_FAIL:
		return state.set('warehouse', action.payload);
	case WAREHOUSES_GET_IP_WHITELISTS:
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS:
		return state.delete('warehouseIPWhitelists');
	case WAREHOUSES_GET_IP_WHITELISTS_FAIL:
	case WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL:
		return state.set('warehouseIPWhitelists', action.payload);
	/* Warehouse groups */
	case WAREHOUSE_GROUPS_CREATE:
	case WAREHOUSE_GROUPS_UPDATE:
	case WAREHOUSE_GROUPS_GET_DETAILS:
		return state.delete('warehouseGroup');
	case WAREHOUSE_GROUPS_CREATE_FAIL:
	case WAREHOUSE_GROUPS_UPDATE_FAIL:
	case WAREHOUSE_GROUPS_GET_DETAILS_FAIL:
		return state.set('warehouseGroup', action.payload);
	case WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('warehouseGroups');
	case WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('warehouseGroups', action.payload);
	/* Payment methods */
	case PAYMENT_METHODS_UPDATE:
	case PAYMENT_METHODS_GET_DETAILS:
		return state.delete('paymentMethod');
	case PAYMENT_METHODS_UPDATE_FAIL:
	case PAYMENT_METHODS_GET_DETAILS_FAIL:
		return state.set('paymentMethod', action.payload);
	case PAYMENT_METHODS_FIND_ALL:
		return state.delete('paymentMethods');
	case PAYMENT_METHODS_FIND_ALL_FAIL:
		return state.set('paymentMethods', action.payload);
	/* Item groups */
	case ITEM_GROUPS_CREATE:
	case ITEM_GROUPS_UPDATE:
	case ITEM_GROUPS_GET_DETAILS:
		return state.delete('itemGroup');
	case ITEM_GROUPS_CREATE_FAIL:
	case ITEM_GROUPS_UPDATE_FAIL:
	case ITEM_GROUPS_GET_DETAILS_FAIL:
		return state.set('itemGroup', action.payload);
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('itemGroups');
	case ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('itemGroups', action.payload);
	/* Promotions */
	case PROMOTIONS_CREATE:
	case PROMOTIONS_UPDATE:
	case PROMOTIONS_GET_DETAILS:
		return state.delete('promotion');
	case PROMOTIONS_CREATE_FAIL:
	case PROMOTIONS_UPDATE_FAIL:
	case PROMOTIONS_GET_DETAILS_FAIL:
		return state.set('promotion', action.payload);
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('promotions');
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('promotions', action.payload);
	/* Messages */
	case MESSAGES_CREATE:
	case MESSAGES_UPDATE:
	case MESSAGES_GET_DETAILS:
		return state.delete('message');
	case MESSAGES_CREATE_FAIL:
	case MESSAGES_UPDATE_FAIL:
	case MESSAGES_GET_DETAILS_FAIL:
		return state.set('message', action.payload);
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('messages');
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('messages', action.payload);
	/* Transactions */
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('transactions');
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('transactions', action.payload);
	case TRANSACTIONS_GET_DETAILS:
		return state.delete('transaction');
	case TRANSACTIONS_GET_DETAILS_FAIL:
		return state.set('transaction', action.payload);
	/* Configs */
	case CONFIGS_FIND_ALL_BY_GROUP:
	case CONFIGS_BATCH_SET_CONFIG:
		return state.delete('configs');
	case CONFIGS_FIND_ALL_BY_GROUP_FAIL:
	case CONFIGS_BATCH_SET_CONFIG_FAIL:
		return state.set('configs', action.payload);
	/* Maximum brand discounts */
	case MAX_BRAND_DISCOUNTS_CREATE:
	case MAX_BRAND_DISCOUNTS_UPDATE:
	case MAX_BRAND_DISCOUNTS_GET_DETAILS:
		return state.delete('maxBrandDiscounts');
	case MAX_BRAND_DISCOUNTS_CREATE_FAIL:
	case MAX_BRAND_DISCOUNTS_UPDATE_FAIL:
	case MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL:
		return state.set('maxBrandDiscounts', action.payload);
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('maxBrandDiscounts');
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('maxBrandDiscounts', action.payload);
	/* Remarks */
	case REMARKS_CREATE:
	case REMARKS_UPDATE:
	case REMARKS_GET_DETAILS:
		return state.delete('remark');
	case REMARKS_CREATE_FAIL:
	case REMARKS_UPDATE_FAIL:
	case REMARKS_GET_DETAILS_FAIL:
		return state.set('remark', action.payload);
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.delete('remarks');
	case REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('remarks', action.payload);
	default:
		return state;
	}
};

export default errorsReducer;

export const selector = createStructuredSelector({
	errors: (state) => state.errors,
});