import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	USER_ROLES_CREATE,
	USER_ROLES_CREATE_SUCCESS,
	USER_ROLES_CREATE_FAIL,
	USER_ROLES_UPDATE,
	USER_ROLES_UPDATE_SUCCESS,
	USER_ROLES_UPDATE_FAIL,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	USER_ROLES_GET_DETAILS,
	USER_ROLES_GET_DETAILS_SUCCESS,
	USER_ROLES_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USER_ROLES_CREATE:
	case USER_ROLES_UPDATE:
		return state.set('userRole', 'submitting');
	case USER_ROLES_CREATE_SUCCESS:
	case USER_ROLES_UPDATE_SUCCESS:
		return state.set('userRole', 'submitted');
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('userRoles', 'fetching');
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('userRoles', 'fetched');
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('userRoles', 'has-errors');
	case USER_ROLES_GET_DETAILS:
		return state.set('userRole', 'fetching');
	case USER_ROLES_GET_DETAILS_SUCCESS:
		return state.set('userRole', 'fetched');
	case USER_ROLES_CREATE_FAIL:
	case USER_ROLES_UPDATE_FAIL:
	case USER_ROLES_GET_DETAILS_FAIL:
		return state.set('userRole', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'userRole' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'userRole' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'userRole' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case USER_ROLES_CREATE:
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case USER_ROLES_CREATE_SUCCESS:
	case USER_ROLES_UPDATE_SUCCESS:
	case USER_ROLES_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const userRolesByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const userRoleReducer = (state = new Map(), action) => {
	switch (action.type) {
	case USER_ROLES_GET_DETAILS:
		return new Map();
	case USER_ROLES_CREATE_SUCCESS:
	case USER_ROLES_UPDATE_SUCCESS:
	case USER_ROLES_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'userRole' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'userRole' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status          : statusReducer,
	current         : currentReducer,
	userRolesByPage : userRolesByPageReducer,
	userRole        : userRoleReducer,
	options         : optionsReducer,
});

export const selector = createStructuredSelector({
	status          : (state) => state.userRoles.status,
	current         : (state) => state.userRoles.current,
	userRolesByPage : (state) => state.userRoles.userRolesByPage,
	userRole        : (state) => state.userRoles.userRole,
	options         : (state) => state.userRoles.options,
	errors          : (state) => state.errors.get('userRole'),
});