import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageChangePassword from 'Pages/change-password';
import PageUsers from 'Pages/users';
import PageUser from 'Pages/user';
import PageUserPermission from 'Pages/user-permission';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/change-password`} component={ PageChangePassword } />
			<Route exact path={`${match.url}/list`} component={ PageUsers } />
			<Route exact path={`${match.url}/edit/:userId`} component={ PageUser } />
			<Route exact path={`${match.url}/permission/:userId`} component={ PageUserPermission } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;