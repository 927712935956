import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Row, Button } from 'reactstrap';
import classnames from 'classnames';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import PromotionTierCondition from './PromotionTierCondition.jsx';
import PromotionTierBenefit from './PromotionTierBenefit.jsx';

class PromotionTier extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			showDetails: true,
		};
		this.onToggleHandler = this.onToggleHandler.bind(this);
		this.onReOrderUpHandler = this.onReOrderUpHandler.bind(this);
		this.onReOrderDownHandler = this.onReOrderDownHandler.bind(this);
		this.onCopyHandler = this.onCopyHandler.bind(this);
		this.onDeleteHandler = this.onDeleteHandler.bind(this);
		this.onChangeConditionsHandler = this.onChangeConditionsHandler.bind(this);
		this.onChangeBenefitsHandler = this.onChangeBenefitsHandler.bind(this);
	}

	onToggleHandler() {
		const { showDetails } = this.state;
		this.setState({
			showDetails: !showDetails,
		});
	}

	onReOrderUpHandler() {
		const { index, onReOrder } = this.props;
		onReOrder(index, false);
	}

	onReOrderDownHandler() {
		const { index, onReOrder } = this.props;
		onReOrder(index, true);
	}

	onCopyHandler() {
		const { index, onCopy } = this.props;
		onCopy(index);
	}

	onDeleteHandler() {
		if (confirm('Are you sure to delete this promotion tier?')) {
			const { index, onDelete } = this.props;
			onDelete(index);
		}
	}

	onChangeConditionsHandler(conditions) {
		const { index, tier, onChange } = this.props;
		onChange(index, tier.set('conditions', conditions));
	}

	onChangeBenefitsHandler(benefits) {
		const { index, tier, onChange } = this.props;
		onChange(index, tier.set('benefits', benefits));
	}

	render() {
		const { index, errors, count, options, defaults, isLoading, tier } = this.props;
		const { showDetails } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="8">
						<h2 className="mt-2">Tier { index + 1 }</h2>
					</Colxx>
					<Colxx xxs="4" className="text-right">
						<Button size="sm" outline color="primary" className="mr-1" disabled={ isLoading } onClick={ this.onToggleHandler }>
							<i className={ classnames(showDetails ? 'simple-icon-minus' : 'simple-icon-plus', 'btn-group-icon') } />
						</Button>
						<Button size="sm" outline color="primary" className="mr-1" disabled={ index === 0 || isLoading } onClick={ this.onReOrderUpHandler }>
							<i className="simple-icon-arrow-up btn-group-icon" />
						</Button>
						<Button size="sm" outline color="primary" className="mr-1" disabled={ index === count - 1 || isLoading } onClick={ this.onReOrderDownHandler }>
							<i className="simple-icon-arrow-down btn-group-icon" />
						</Button>
						<Button size="sm" outline color="primary" className="mr-1" disabled={ isLoading } onClick={ this.onCopyHandler }>
							<i className="iconsminds-file-copy btn-group-icon" />
						</Button>
						<Button size="sm" outline color="danger" disabled={ isLoading } onClick={ this.onDeleteHandler }>
							<i className="simple-icon-close btn-group-icon" />
						</Button>
					</Colxx>
				</Row>

				<Separator className="mt-2 mb-3" />

				{
					showDetails && (
						<Row>
							<Colxx lg="6">
								<PromotionTierCondition
									tierIndex={ index }
									options={ options }
									errors={ errors }
									isLoading={ isLoading }
									defaults={ defaults.promotionTierCondition }
									conditions={ tier.get('conditions') }
									onChange={ this.onChangeConditionsHandler }
								/>
							</Colxx>
							<Colxx lg="6">
								<PromotionTierBenefit
									tierIndex={ index }
									options={ options }
									errors={ errors }
									isLoading={ isLoading }
									defaults={ defaults.promotionTierBenefit }
									benefits={ tier.get('benefits') }
									onChange={ this.onChangeBenefitsHandler }
								/>
							</Colxx>
						</Row>
					)
				}
			</Fragment>
		);
	}
}

PromotionTier.propTypes = {
	index: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	options: PropTypes.instanceOf(Map).isRequired,
	defaults: PropTypes.object.isRequired,
	errors: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	tier: PropTypes.instanceOf(Map).isRequired,
	onChange: PropTypes.func.isRequired,
	onReOrder: PropTypes.func.isRequired,
	onCopy: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default PromotionTier;