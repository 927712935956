import {
	MAX_BRAND_DISCOUNTS_CREATE,
	MAX_BRAND_DISCOUNTS_CREATE_SUCCESS,
	MAX_BRAND_DISCOUNTS_CREATE_FAIL,
	MAX_BRAND_DISCOUNTS_UPDATE,
	MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS,
	MAX_BRAND_DISCOUNTS_UPDATE_FAIL,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MAX_BRAND_DISCOUNTS_GET_DETAILS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL,
} from 'Constants/actionTypes';

export const maxBrandDiscountsCreate = (data) => ({
	type: MAX_BRAND_DISCOUNTS_CREATE,
	payload: data,
});

export const maxBrandDiscountsCreateSuccess = (data) => {
	return ({
		type: MAX_BRAND_DISCOUNTS_CREATE_SUCCESS,
		payload: data,
	});
};

export const maxBrandDiscountsCreateFail = (error) => ({
	type: MAX_BRAND_DISCOUNTS_CREATE_FAIL,
	payload: error,
});

export const maxBrandDiscountsUpdate = (data) => ({
	type: MAX_BRAND_DISCOUNTS_UPDATE,
	payload: data,
});

export const maxBrandDiscountsUpdateSuccess = (data) => {
	return ({
		type: MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const maxBrandDiscountsUpdateFail = (error) => ({
	type: MAX_BRAND_DISCOUNTS_UPDATE_FAIL,
	payload: error,
});

export const maxBrandDiscountsFindAllByPageAndOptions = (params) => ({
	type: MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const maxBrandDiscountsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const maxBrandDiscountsFindAllByPageAndOptionsFail = (error) => ({
	type: MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const maxBrandDiscountsGetDetails = (id) => ({
	type: MAX_BRAND_DISCOUNTS_GET_DETAILS,
	payload: id,
});

export const maxBrandDiscountsGetDetailsSuccess = (data) => {
	return ({
		type: MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const maxBrandDiscountsGetDetailsFail = (error) => ({
	type: MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL,
	payload: error,
});
