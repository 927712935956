import React, { PureComponent, Fragment } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import thousands from 'thousands';
import moment from 'moment';
import LoadingOverlay from 'Components/LoadingOverlay';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { transactionsGetDetails, errorReset } from 'Redux/actions';
import * as transactionsReducer from 'Redux/transactions/reducer';
import IntlMessages from 'Util/IntlMessages';
import './PageTransaction.scss';

class PageTransaction extends PureComponent {
	constructor(props) {
		super(props);
		this.breadcrumb = [
			{ id: 'menu.transaction', href: '/transaction/list' },
			{ id: 'transaction.heading', href: `/transaction/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.columns = [
			{
				Header: 'Item Name',
				accessor: 'item_name_en',
			},
			{
				Header: 'Item Code',
				accessor: 'item_code',
			},
			{
				Header: 'Lot No.',
				accessor: 'lot_no',
			},
			{
				id: 'unit_price',
				Header: 'Unit Price',
				accessor: (d) => `$ ${thousands(d.unit_price)}`,
			},
			{
				id: 'item_quantity',
				Header: 'Qty',
				accessor: (d) => thousands(d.item_quantity),
			},
			{
				id: 'discount',
				Header: 'Discount',
				accessor: (d) => {
					if (d.discount_reduction) {
						return `$ ${thousands(d.discount_reduction)}`;
					}
					return `${d.discount_percentage} %`;
				},
			},
			{
				id: 'final_amount',
				Header: 'Amount',
				accessor: (d) => `${d.final_amount > 0 ? '' : '- '}$${thousands(Math.abs(d.final_amount))}`,
			},
		];
		this.getId = this.getId.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentDidMount() {
		this.fetchData();
		this.props.errorReset('transaction');
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.transactionId;
	}

	fetchData() {
		const id = this.getId();
		this.props.transactionsGetDetails(id);
	}

	render() {
		const id = this.getId();
		const transaction = this.props.transaction.get(id);
		const status = this.props.status.get('transaction');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ id ? <span>{ id }</span> : <IntlMessages id="transaction.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
						<div className="float-right">
							<NavLink
								to={ `/app/transaction/print/${id}` }
								className="btn btn-primary btn-lg"
							>
								PRINT
							</NavLink>
						</div>
						<div className="clearfix" />
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Row className="mb-3">
						<Col xs="12">
							<table className="invoice-header-table">
								<tbody>
									<tr>
										<td colSpan={ 2 } className="invoice-header-address">
											{
												transaction && transaction.shop && (
													<Fragment>
														{ transaction.shop.wh_desc }<br />
														{ transaction.shop.inv_msg }
													</Fragment>
												)
											}
										</td>
									</tr>
									<tr>
										<td className="invoice-header-customer">
											<p className="text-muted text-small mb-1">Customer</p>
											<p className="mb-3">
												{
													transaction && transaction.member && transaction.member.member_code ? (
														`${transaction.member.member_name} (${transaction.member.member_code})`
													) : (
														'-'
													)
												}
											</p>
											<p className="text-muted text-small mb-1">Ordering Date</p>
											<p className="mb-0">
												{ transaction && transaction.transaction_date && moment(transaction.transaction_date).format('YYYY-MM-DD HH:mm:ss') }
											</p>
										</td>
										<td className="invoice-header-staffs">
											<p className="text-muted text-small mb-1">Sales Person</p>
											<p className="mb-3">
												{
													transaction && transaction.salesman_code1 ? (
														<Fragment>
															{ !!transaction.salesman_name1 && transaction.salesman_name1 }
															{ !!transaction.salesman_code1 && transaction.salesman_code1 }
															<br />
														</Fragment>
													) : (
														'-'
													)
												}
												{
													transaction && transaction.salesman_code2 ? (
														<Fragment>
															{ !!transaction.salesman_name2 && transaction.salesman_name2 }
															{ !!transaction.salesman_code2 && transaction.salesman_code2 }
														</Fragment>
													) : (
														'-'
													)
												}
											</p>
											<p className="text-muted text-small mb-1">Optometrist</p>
											<p className="mb-0">
												{
													transaction && transaction.optometrist_code ? (
														<Fragment>
															{ !!transaction.optometrist_name && transaction.optometrist_name }
															{ !!transaction.optometrist_code && transaction.optometrist_code }
														</Fragment>
													) : (
														'-'
													)
												}
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
					
					<Row className="mb-3">
						<Col xs="12">
							<Card>
								<CardBody>
									<ReactTable
										data={ transaction && transaction.transaction_items }
										columns={ this.columns }
										minRows={ 0 }
										showPageJump={ false }
										showPageSizeOptions={ false }
										PaginationComponent={ DataTablePagination }
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					
					<Row className="mb-3">
						<Col xs="12">
							<table className="invoice-footer-table">
								<tbody>
									<tr>
										<td colSpan={ 2 } className="invoice-footer-table-border-top">&nbsp;</td>
									</tr>
									<tr>
										<th>Retail Amount :</th>
										<td>{ transaction ? `${transaction.retail_amount > 0 ? '' : '- '}$ ${thousands(Math.abs(transaction.retail_amount))}` : '' }</td>
									</tr>
									<tr>
										<th>Quantity :</th>
										<td>{ transaction ? thousands(transaction.total_quantity) : '' }</td>
									</tr>
									<tr>
										<th>Total Discount :</th>
										<td>{ transaction ? `$ ${thousands(transaction.total_discount)}` : '' }</td>
									</tr>
									<tr>
										<th><strong>Net Amount :</strong></th>
										<td>{ transaction ? `$ ${thousands(transaction.net_amount)}` : '' }</td>
									</tr>
									<tr>
										<td colSpan={ 2 } className="invoice-footer-table-border-bottom">&nbsp;</td>
									</tr>
									<tr>
										<td colSpan={ 2 } className="invoice-footer-remark">
											Invoice was created on a computer and is valid without the signature and seal.
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	transactionsReducer.selector,
	{
		transactionsGetDetails,
		errorReset,
	}
)(PageTransaction);