import { combineReducers } from 'redux';
import menu from './menu/reducer';
import settings from './settings/reducer';
import authUser from './auth/reducer';
import errors from './errors/reducer';
import users from './users/reducer';
import userRoles from './userRoles/reducer';
import companies from './companies/reducer';
import configs from './configs/reducer';
import warehouses from './warehouses/reducer';
import warehouseGroups from './warehouseGroups/reducer';
import paymentMethods from './paymentMethods/reducer';
import itemGroups from './itemGroups/reducer';
import promotions from './promotions/reducer';
import messages from './messages/reducer';
import transactions from './transactions/reducer';
import maxBrandDiscounts from './maxBrandDiscounts/reducer';
import remarks from './remarks/reducer';

const reducers = combineReducers({
	menu,
	settings,
	authUser,
	errors,
	users,
	userRoles,
	companies,
	configs,
	warehouses,
	warehouseGroups,
	paymentMethods,
	itemGroups,
	promotions,
	messages,
	transactions,
	maxBrandDiscounts,
	remarks,
});

export default reducers;