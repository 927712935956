import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PagePayments from 'Pages/payments';
import PagePayment from 'Pages/payment';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/list`} component={ PagePayments } />
			<Route exact path={`${match.url}/edit/:paymentId`} component={ PagePayment } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;