import React, { PureComponent, Fragment } from 'react';
import { Row, Col, Card, CardTitle, CardBody, Form, FormGroup, Label, Input, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Map, Set } from 'immutable';
import classnames from 'classnames';
import LoadingOverlay from 'Components/LoadingOverlay';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import CustomFormGroup from 'Components/CustomFormGroup';
import ModalHistory from './components/ModalHistory.jsx';
import TrUserRole from './components/TrUserRole.jsx';
import { usersGetOptions, usersAssign, usersUnlock, usersClear2FASecret, usersReset2FASecret, usersChangePassword, usersGetDetails, errorReset } from 'Redux/actions';
import * as usersReducer from 'Redux/users/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageUser extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showModalHistory: false,
			user: new Map({
				roles: new Set(),
			}),
			original: '',
			password: '',
			confirmPassword: '',
			mismatch: false,
		};
		this.breadcrumb = [
			{ id: 'menu.user', href: '/user' },
			{ id: 'menu.user.find-user', href: '/user/list' },
			{ id: 'user.heading', href: `/user/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.getUserMap = this.getUserMap.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeUserRoleHandler = this.onChangeUserRoleHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onToggleModalHistoryHandler = this.onToggleModalHistoryHandler.bind(this);
		this.onClickReactivateHandler = this.onClickReactivateHandler.bind(this);
		this.onClickReset2FASecretHandler = this.onClickReset2FASecretHandler.bind(this);
		this.renderStatus = this.renderStatus.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		this.props.usersGetOptions();
		this.props.usersGetDetails(id);
		this.props.errorReset('user');
		this.props.errorReset('password');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevUser = prevProps.user.get(id);
			const user = this.props.user.get(id);
			if (user && user !== prevUser) {
				this.setState({
					user: this.getUserMap(user),
				});
			}
		}

		const prevStatus = prevProps.status.get('user');
		const status = this.props.status.get('user');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The user is ${id ? 'updated' : 'created'} successfully.`,
					`User ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/user/edit/${id}`);
				const user = this.props.user.get(id);
				this.setState({
					user: this.getUserMap(user),
				});
			}
				break;
			case 'unlocked':
				NotificationManager.success(
					'The user is reactivated successfully.',
					'User Reactivated'
				);
				this.props.usersGetDetails(id);
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The user is NOT ${id ? 'updated' : 'created'}.`,
					`User ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}

		const prevPasswordStatus = prevProps.status.get('password');
		const passwordStatus = this.props.status.get('password');
		if (passwordStatus && prevPasswordStatus !== passwordStatus) {
			switch (passwordStatus) {
			case 'submitted':
				NotificationManager.success(
					'The user password is updated successfully.',
					'User Password Updated'
				);
				this.setState({
					original: '',
					password: '',
					confirmPassword: '',
				});
				break;
			case 'has-errors':
				NotificationManager.error(
					'Error occured! The user password is NOT updated',
					'User Password Update Failed'
				);
				break;
			}
		}

		const prev2faStatus = prevProps.status.get('2fa');
		const _2faStatus = this.props.status.get('2fa');
		if (_2faStatus && _2faStatus !== prev2faStatus) {
			switch (_2faStatus) {
			case 'cleared':
				this.props.usersReset2FASecret({ user_id: id });
				break;
			case 'reset':
				NotificationManager.success(
					'The 2FA secret is reset successfully.',
					'2FA Secret Reset'
				);
				this.props.usersGetDetails(id);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.userId;
	}

	getUserMap(user) {
		let ret = this.state.user.merge(user);
		let roles = new Set(user.roles);
		return ret.set('roles', roles);
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.id] = e.target.value;
		this.setState(newState);
	}

	onChangeUserRoleHandler(userRole) {
		const { user } = this.state;
		let roles = user.get('roles');
		let value = parseInt(userRole.value);
		if (roles.includes(value)) {
			roles = roles.delete(value);
		} else {
			roles = roles.add(value);
		}
		this.setState({
			user: user.set('roles', roles),
		});
	}

	onSubmitHandler(e) {
		const { original, password, confirmPassword, user } = this.state;
		const currentUser = this.props.current;
		e.preventDefault();
		let newState = { mismatch: false };
		if (password || confirmPassword) {
			if (password === confirmPassword) {
				const id = this.getId();
				let data = {};
				data.username = id;
				data.password = password;
				if (currentUser && currentUser.get('user_id') === id) {
					data.original = original;
				}
				this.props.usersChangePassword(data);
			} else {
				alert('The password and confirmation password do not match. Please fix it and try again.');
				newState.mismatch = true;
			}
		}
		this.setState(newState);

		let data = {};
		data.username = this.getId();
		data.roles = user.get('roles').toJS();
		this.props.usersAssign(data);
	}

	onToggleModalHistoryHandler() {
		const { showModalHistory } = this.state;
		this.setState({
			showModalHistory: !showModalHistory,
		});
	}

	onClickReactivateHandler(e) {
		e.preventDefault();
		if (confirm('Are you sure to reactivate this user?')) {
			const data = {
				username: this.getId(),
			};
			this.props.usersUnlock(data);
		}
	}

	onClickReset2FASecretHandler(e) {
		const id = this.getId();
		const user = this.props.user.get(id);
		e.preventDefault();
		if (confirm(user && user.qrcode ? 'Are you sure to reset the 2FA secret of this user?' : 'Are you sure to generate the 2FA secret of this user?')) {
			const data = {
				user_id: this.getId(),
			};
			this.props.usersClear2FASecret(data);
		}
	}

	renderStatus() {
		const id = this.getId();
		const user = this.props.user.get(id);
		let className = 'badge-primary';
		if (!user) {
			return;
		}
		let status = user.status;
		switch (status) {
		case 'I':
			className = 'badge-light';
			status = 'Inactive';
			break;
		case 'A':
			className = 'badge-primary';
			status = 'Active';
			break;
		}
		return (
			<Fragment>
				<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ status }</span>
				{
					user.allow_login !== 'Y' && (
						<span className="badge badge-pill badge-danger ml-1">LOCKED</span>
					)
				}
			</Fragment>
		);
	}

	render() {
		const { options, errorsPassword } = this.props;
		const currentUser = this.props.current;
		const { showModalHistory, original, password, confirmPassword, mismatch } = this.state;
		const id = this.getId();
		const user = this.props.user.get(id);
		const status = this.props.status.get('user');
		const optionsStatus = this.props.status.get('options');
		const roles = this.state.user.get('roles');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="user.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Form onSubmit={ this.onSubmitHandler }>
						<Row className="mb-3">
							<Col xs="12">
								<Card>
									<div className="d-flex flex-grow-1 min-width-zero">
										<CardBody className="align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
											<div className="list-item-heading mb-1 truncate w-20 w-xs-100">
												{ user && user.user_id }
											</div>
											<p className="mb-1 text-muted text-small w-15 w-xs-100">{ user && user.user_name }</p>
											<p className="mb-1 text-muted text-small w-15 w-xs-100">
												{
													user && user.salesman_pos && (
														<strong>{ user.salesman_pos }</strong>
													)
												}
												<br />
												{ user && user.user_dept }
											</p>
											<p className="mb-1 text-muted text-small w-15 w-xs-100">{ user && 	user.user_level }</p>
											<div className="w-15 w-xs-100">{ this.renderStatus() }</div>
										</CardBody>
									</div>
								</Card>
							</Col>
						</Row>

						<Row className="mb-4">
							<Col md="6">
								<Card>
									<CardBody>
										<CardTitle>User Role</CardTitle>
										<Table>
											<thead>
												<tr>
													<th>User Role</th>
													<th>Assign Status</th>
												</tr>
											</thead>
											<tbody>
												{
													options.get('userRoles') && options.get('userRoles').map((userRole, index) => (
														<TrUserRole
															key={ `page-user-tr-user-role-${index}` }
															userRole={ userRole }
															checked={ roles.has(userRole.value) }
															onChange={ this.onChangeUserRoleHandler }
														/>
													))
												}
											</tbody>
										</Table>

										<div className="text-center">
											<NavLink
												to={ `/app/user/permission/${id}` }
												className="btn btn-sm btn-outline-primary"
											>
												REVIEW PERMISSION
											</NavLink>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col md="6">
								<Card>
									<CardBody>
										<CardTitle>Password Update</CardTitle>

										{
											currentUser && currentUser.get('user_id') === id && (
												<CustomFormGroup
													id="original"
													label="Original Password"
													type="password"
													value={ original }
													disabled={ isLoading }
													errors={ errorsPassword && errorsPassword.detail.original }
													onChange={ this.onChangeHandler }
												/>
											)
										}

										<CustomFormGroup
											id="password"
											label="New Password"
											type="password"
											value={ password }
											disabled={ isLoading }
											errors={ errorsPassword && errorsPassword.detail.password }
											onChange={ this.onChangeHandler }
										/>

										<FormGroup>
											<Label for="confirmPassword">Confirm Password</Label>
											<Input
												type="password"
												id="confirmPassword"
												value={ confirmPassword }
												disabled={ isLoading }
												onChange={ this.onChangeHandler }
												invalid={ mismatch }
											/>
											{
												mismatch && (
													<div className="invalid-feedback d-block">
														The password and confirmation password do not match. Please fix it and try again.
													</div>
												)
											}
										</FormGroup>

										<div className="text-center">
											<Button size="sm" outline color="primary" disabled={ isLoading || (user && user.allow_login !== 'N') } className="mr-1" onClick={ this.onClickReactivateHandler }>
												REACTIVATE
											</Button>
											<Button size="sm" outline color="primary" disabled={ isLoading } onClick={ this.onToggleModalHistoryHandler }>
												HISTORY
											</Button>
										</div>

										{
											user && user.qrcode && (
												<div className="text-center pt-4">
													<img src={ `data:image/png;base64,${user.qrcode}` } />
												</div>
											)
										}

										{
											user && user.permissions && user.permissions.shop_front_2fa === 'w' && (
												<div className="text-center pt-2">
													<Button size="sm" outline color="primary" disabled={ isLoading } onClick={ this.onClickReset2FASecretHandler }>
														{ user.qrcode ? 'RESET' : 'GENERATE' } 2FA SECRET
													</Button>
												</div>
											)
										}
									</CardBody>
								</Card>
							</Col>
						</Row>

						<div className="text-center">
							<Button size="lg" color="primary" disabled={ isLoading }>
								SAVE
							</Button>
						</div>
					</Form>

					<ModalHistory
						isOpen={ showModalHistory }
						onToggle={ this.onToggleModalHistoryHandler }
						histories={ user && user.passwordHistories }
					/>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	usersReducer.selector,
	{
		usersGetOptions,
		usersAssign,
		usersUnlock,
		usersClear2FASecret,
		usersReset2FASecret,
		usersChangePassword,
		usersGetDetails,
		errorReset,
	}
)(PageUser);