import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import Pluralize from 'react-pluralize';
import ReactTable from 'react-table';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import {
	itemGroupsFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as itemGroupsReducer from 'Redux/itemGroups/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageItemGroups extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.item-group', href: '/item-group/list' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={ `/app/item-group/edit/${d.id}` }>
						{ d.name }
					</NavLink>
				),
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'numItems',
				Header: 'Item',
				sortable: false,
				accessor: (d) => (
					d.item_ids ? (
						<Pluralize singular="Item" count={ d.item_ids.length } />
					) : (
						'N/A'
					)
				),
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					if (d.status) {
						let className = 'badge-primary';
						switch (d.status.label) {
						case 'Inactive':
							className = 'badge-light';
							break;
						}
						return (
							<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ d.status ? d.status.label : '' }</span>
						);
					}
					return '';
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/item-group/edit/${d.id}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('itemGroups');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.itemGroupsFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { itemGroupsByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('itemGroups');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="float-left">
							<BreadcrumbContainer
								heading={ <IntlMessages id="item-groups.heading" /> }
								items={ this.breadcrumb }
								match={ this.props.match }
							/>
						</div>
						<div className="float-right">
							<NavLink to="/app/item-group/create" className="btn btn-lg btn-primary">
								ADD GROUP
							</NavLink>
						</div>
						<div className="clearfix"></div>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ itemGroupsByPage.get('data') }
									pages={ itemGroupsByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	itemGroupsReducer.selector,
	{
		itemGroupsFindAllByPageAndOptions,
		errorReset,
	}
)(PageItemGroups);