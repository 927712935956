import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';
import { Map } from 'immutable';
import ReactSelect from 'react-select';
import CustomSelectInput from 'Components/CustomSelectInput';

class FormPromotionTierBenefit extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			benefit: props.defaults,
		};
		this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
		this.onChangeCheckboxHandler = this.onChangeCheckboxHandler.bind(this);
		this.onChangeTypeHandler = this.onChangeReactSelectHandler.bind(this, 'type');
		this.onChangeNumberUnitHandler = this.onChangeReactSelectHandler.bind(this, 'number_unit');
		this.onChangeApplyToOperatorHandler = this.onChangeReactSelectHandler.bind(this, 'apply_to_operator');
		this.onChangeApplyToHandler = this.onChangeReactSelectHandler.bind(this, 'apply_to');
		this.onChangeApplyCriteriaTypeHandler = this.onChangeReactSelectHandler.bind(this, 'apply_criteria_type');
		this.onChangeParametersHandler = this.onChangeReactSelectHandler.bind(this, 'parameters');
		this.onAddHandler = this.onAddHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
		this.renderSelect = this.renderSelect.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderParameters = this.renderParameters.bind(this);
	}

	componentDidUpdate(prevProps) {
		const benefit = this.props.benefit;
		const prevBenefit = prevProps.benefit;
		if (benefit !== prevBenefit) {
			this.setState({
				benefit: benefit ? benefit : this.props.defaults,
			});
		}
	}

	onChangeInputHandler(e) {
		const { benefit } = this.state;
		let value = e.target.value;
		switch (e.target.id) {
		case 'number':
		case 'apply_to_max_items':
			value = parseInt(value);
			break;
		}
		this.setState({
			benefit: benefit.set(e.target.id, value),
		});
	}

	onChangeCheckboxHandler(e) {
		const { benefit } = this.state;
		let value = e.target.checked;
		this.setState({
			benefit: benefit.set(e.target.id, value),
		});
	}

	onChangeReactSelectHandler(field, value) {
		let { benefit } = this.state;
		benefit = benefit.set(field, value);
		switch (field) {
		case 'type':
			if (value.label === 'Target Amount') {
				benefit = benefit.set('number_unit', '$');
			}
			break;
		case 'apply_criteria_type':
			if (this.state.benefit.get(field) !== value) {
				benefit = benefit
					.set('parameter', '')
					.set('parameters', [])
				;
			}
			break;
		case 'apply_to':
			if (benefit.get('apply_to')) {
				if (
					benefit.get('apply_to').label.indexOf('Highest Price Item') === -1 &&
					benefit.get('apply_to').label.indexOf('Lowest Price Item') === -1
				) {
					benefit = benefit.set('apply_to_max_items', null);
				}

				if (benefit.get('apply_to').label === 'Invoice' || benefit.get('apply_to').label === 'Invoice (excl. Discounted Items)') {
					benefit = benefit
						.set('apply_to_max_items', null)
						.set('apply_to_operator', null)
						.set('apply_criteria_type', null)
						.set('parameter', '')
						.set('parameters', [])
					;
				}
			}
			break;
		}
		this.setState({
			benefit,
		});
	}

	onAddHandler(e) {
		e.preventDefault();
		const { benefit } = this.state;
		this.props.onEdit(benefit);
		this.setState({
			benefit: this.props.defaults,
		});
	}

	onCancelHandler(e) {
		this.props.onCancel(e);
	}

	renderSelect(field, label, options, onChange, isMulti = false) {
		const { isLoading } = this.props;
		const { benefit } = this.state;
		return (
			<FormGroup>
				<Label className="has-float-label">
					<ReactSelect
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						value={ benefit.get(field) }
						isDisabled={ isLoading }
						isClearable={ true }
						isMulti={ isMulti }
						options={ options }
						onChange={ onChange }
					/>
					<span>{ label }</span>
				</Label>
			</FormGroup>
		);
	}

	renderInput(field, label, disableFormGroup) {
		const { isLoading } = this.props;
		const { benefit } = this.state;
		const body = (
			<Label className="has-float-label">
				<Input
					type="text"
					id={ field }
					value={ !benefit.get(field) && benefit.get(field) != 0 ? '' : benefit.get(field) }
					disabled={ isLoading }
					onChange={ this.onChangeInputHandler }
				/>
				<span>{ label }</span>
			</Label>
		);
		if (disableFormGroup) {
			return body;
		}
		return (
			<FormGroup>
				{ body }
			</FormGroup>
		);
	}

	renderParameters() {
		const { options } = this.props;
		const { benefit } = this.state;
		const applyCriteriaType = benefit.get('apply_criteria_type');
		if (applyCriteriaType) {
			switch (applyCriteriaType.value) {
			case 1: // Item Group
				return this.renderSelect('parameters', 'Parameter', options.get('item_group'), this.onChangeParametersHandler, true);
			case 6: // Coupon Code (Range)
				return (
					<Row className="form-row">
						<Colxx xxs="6">{ this.renderInput('parameter', 'Parameter: Range (From, inclusive)') }</Colxx>
						<Colxx xxs="6">{ this.renderInput('parameterTo', 'Parameter: Range (To, inclusive)') }</Colxx>
					</Row>
				);
			default:
				return this.renderInput('parameter', 'Parameter');
			}
		}
		return null;
	}

	render() {
		const { options, index, isLoading } = this.props;
		const { benefit } = this.state;
		let applyTo = (
			<Colxx xxs="12">
				{ this.renderSelect('apply_to', 'Apply To', options.get('benefit.apply_to'), this.onChangeApplyToHandler) }
			</Colxx>
		);
		if (benefit.get('type') && benefit.get('type').label === 'Target Amount') {
			applyTo = (
				<Colxx xxs="12">
					{ this.renderSelect('apply_to', 'Apply To', options.get('benefit.apply_to').filter((applyTo) => applyTo.label === 'Invoice' || applyTo.label === 'Invoice (excl. Discounted Items)'), this.onChangeApplyToHandler) }
				</Colxx>
			);
		}
		if (benefit.get('apply_to')) {
			switch (benefit.get('apply_to').label) {
			case 'Invoice':
			case 'Invoice (excl. Discounted Items)':
				applyTo = (
					<Fragment>
						<Colxx xxs="6">
							{ this.renderSelect('apply_to', 'Apply To', options.get('benefit.apply_to'), this.onChangeApplyToHandler) }
						</Colxx>
						<Colxx xxs="6">
							{ this.renderSelect('apply_to_operator', 'Operator', options.get('benefit.apply_to_operator'), this.onChangeApplyToOperatorHandler) }
						</Colxx>
					</Fragment>
				);
				break;
			case 'All Matched Items':
				applyTo = (
					<Fragment>
						<Colxx xxs="6">
							{ this.renderSelect('apply_to', 'Apply To', options.get('benefit.apply_to'), this.onChangeApplyToHandler) }
						</Colxx>
						<Colxx xxs="6">
							{ this.renderSelect('apply_to_operator', 'Operator', options.get('benefit.apply_to_operator'), this.onChangeApplyToOperatorHandler) }
						</Colxx>
					</Fragment>
				);
				break;
			case 'Highest Price Item':
			case 'Lowest Price Item':
				applyTo = (
					<Fragment>
						<Colxx xxs="4">
							{ this.renderSelect('apply_to', 'Apply To', options.get('benefit.apply_to'), this.onChangeApplyToHandler) }
						</Colxx>
						<Colxx xxs="4">
							{ this.renderInput('apply_to_max_items', 'Max No. of Items') }
						</Colxx>
						<Colxx xxs="4">
							{ this.renderSelect('apply_to_operator', 'Operator', options.get('benefit.apply_to_operator'), this.onChangeApplyToOperatorHandler) }
						</Colxx>
					</Fragment>
				);
				break;
			}
		}
		return (
			<Form className="mb-5" onSubmit={ this.onAddHandler }>
				<Row className="form-row">
					<Colxx xxs="4">
						{ this.renderSelect('type', 'Benefit', options.get('benefit.type'), this.onChangeTypeHandler) }
					</Colxx>
					<Colxx xxs="6">
						{ this.renderInput('number', 'Number') }
					</Colxx>
					<Colxx xxs="2">
						<Input
							id="number_unit"
							type="select"
							value={ benefit.get('number_unit') || '' }
							onChange={ this.onChangeInputHandler }
						>
							{
								options.get('benefit.number_unit') && options.get('benefit.number_unit').map((number_unit, index) => (
									<option key={ `form-promotion-tier-benefit-${index}` } value={ number_unit.value }>
										{ number_unit.label }
									</option>
								))
							}
						</Input>
					</Colxx>
				</Row>
				<Row className="form-row">
					{ applyTo }
				</Row>
				{
					benefit.get('apply_to') && (
						<Row className="form-row">
							<Colxx xxs="4">
								{ this.renderSelect('apply_criteria_type', 'Criteria', options.get('benefit.apply_criteria_type'), this.onChangeApplyCriteriaTypeHandler) }
							</Colxx>
							<Colxx xxs="8">
								{ this.renderParameters() }
							</Colxx>
						</Row>
					)
				}
				{
					benefit.get('type') && benefit.get('type').label === 'Gift' && (
						<Row className="form-row">
							<Colxx xxs="12">
								<FormGroup>
									<CustomInput
										type="checkbox"
										id="hide_line_item"
										label="Hide gift line item"
										checked={ benefit.get('hide_line_item') || false }
										inline
										onChange={ this.onChangeCheckboxHandler }
									/>
								</FormGroup>
							</Colxx>
						</Row>
					)
				}
				<Row>
					<Colxx xxs="12" className="text-center mt-3">
						<button className="btn btn-outline-theme-3 mr-2" onClick={ this.onCancelHandler } disabled={ isLoading }>
							<i className="simple-icon-close btn-group-icon mr-1" />
							Cancel
						</button>
						<button className="btn btn-outline-theme-3" onClick={ this.onAddHandler }>
							{
								index === null ? (
									<Fragment>
										<i className="simple-icon-plus btn-group-icon mr-1" />
										Add
									</Fragment>
								) : (
									<Fragment>
										<i className="simple-icon-pencil btn-group-icon mr-1" />
										Edit
									</Fragment>
								)
							}
						</button>
					</Colxx>
				</Row>
			</Form>
		);
	}
}

FormPromotionTierBenefit.propTypes = {
	options: PropTypes.instanceOf(Map).isRequired,
	defaults: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	index: PropTypes.number,
	condition: PropTypes.instanceOf(Map),
	onEdit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default FormPromotionTierBenefit;