import React, { Component } from 'react';
import { injectIntl} from 'react-intl';
import {
	UncontrolledDropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
	setContainerClassnames,
	clickOnMobileMenu,
	logoutUser,
	changeLocale
} from 'Redux/actions';

class TopNav extends Component {
	constructor(props) {
		super(props);
		this.menuButtonClick = this.menuButtonClick.bind(this);
		this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
		this.renderUserName = this.renderUserName.bind(this);
		this.state = {
			isInFullScreen: false,
		};
	}

	isInFullScreen = () => {
		return (
			(document.fullscreenElement && document.fullscreenElement !== null) ||
			(document.webkitFullscreenElement &&
				document.webkitFullscreenElement !== null) ||
			(document.mozFullScreenElement &&
				document.mozFullScreenElement !== null) ||
			(document.msFullscreenElement && document.msFullscreenElement !== null)
		);
	};

	toggleFullScreen = () => {
		const isInFullScreen = this.isInFullScreen();

		var docElm = document.documentElement;
		if (!isInFullScreen) {
			if (docElm.requestFullscreen) {
				docElm.requestFullscreen();
			} else if (docElm.mozRequestFullScreen) {
				docElm.mozRequestFullScreen();
			} else if (docElm.webkitRequestFullScreen) {
				docElm.webkitRequestFullScreen();
			} else if (docElm.msRequestFullscreen) {
				docElm.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
		this.setState({
			isInFullScreen: !isInFullScreen
		});
	};

	handleLogout = () => {
		this.props.logoutUser();
	};

	handleChangePassword = () => {
		this.props.history.push('/app/user/change-password');
	};

	menuButtonClick(e, menuClickCount, containerClassnames) {
		e.preventDefault();

		setTimeout(() => {
			var event = document.createEvent('HTMLEvents');
			event.initEvent('resize', false, false);
			window.dispatchEvent(event);
		}, 350);
		this.props.setContainerClassnames(++menuClickCount, containerClassnames,this.props.selectedMenuHasSubItems);
	}
	mobileMenuButtonClick(e, containerClassnames) {
		e.preventDefault();
		this.props.clickOnMobileMenu(containerClassnames);
	}

	renderUserName() {
		const { current } = this.props.users;
		const status = this.props.users.status.get('current');
		return (
			status === 'fetching' ? (
				<span className="name mr-1 text-muted">
					Loading...
				</span>
			) : (
				<span className="name mr-1">
					{ current.get('user_name') }
				</span>
			)
		);
	}

	render() {
		const { containerClassnames, menuClickCount } = this.props;
		return (
			<nav className={ classnames('navbar', 'fixed-top', process.env.ENVIRONMENT !== 'PROD' && 'environment') }>
				<NavLink
					to="#"
					className="menu-button d-none d-md-block"
					onClick={e =>
						this.menuButtonClick(e, menuClickCount, containerClassnames)
					}
				>
					<svg
						className="main"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 9 17"
					>
						<rect x="0.48" y="0.5" width="7" height="1" />
						<rect x="0.48" y="7.5" width="7" height="1" />
						<rect x="0.48" y="15.5" width="7" height="1" />
					</svg>
					<svg
						className="sub"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 18 17"
					>
						<rect x="1.56" y="0.5" width="16" height="1" />
						<rect x="1.56" y="7.5" width="16" height="1" />
						<rect x="1.56" y="15.5" width="16" height="1" />
					</svg>
				</NavLink>
				<NavLink
					to="#"
					className="menu-button-mobile d-xs-block d-sm-block d-md-none"
					onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
						<rect x="0.5" y="0.5" width="25" height="1" />
						<rect x="0.5" y="7.5" width="25" height="1" />
						<rect x="0.5" y="15.5" width="25" height="1" />
					</svg>
				</NavLink>

				<NavLink
					to="/"
					className="navbar-logo"
				>
					<span className="logo d-none d-xs-block" />
					<span className="logo-mobile d-block d-xs-none" />

					{
						process.env.ENVIRONMENT !== 'PROD' && (
							<div className="environmentLabel">{ process.env.ENVIRONMENT }</div>
						)
					}
				</NavLink>

				<div className="ml-auto">
					<div className="header-icons d-inline-block align-middle">
						<button
							className="header-icon btn btn-empty d-none d-sm-inline-block"
							type="button"
							id="fullScreenButton"
							onClick={this.toggleFullScreen}
						>
							{this.state.isInFullScreen ? (
								<i className="simple-icon-size-actual d-block" />
							) : (
								<i className="simple-icon-size-fullscreen d-block" />
							)}
						</button>
					</div>
					<div className="user d-inline-block">
						<UncontrolledDropdown className="dropdown-menu-right">
							<DropdownToggle className="p-0" color="empty">
								{ this.renderUserName() }
							</DropdownToggle>
							<DropdownMenu className="mt-3" right>
								<DropdownItem onClick={ this.handleChangePassword }>
									Change Password
								</DropdownItem>
								<DropdownItem onClick={ this.handleLogout }>
									Logout
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = ({ menu, settings, users }) => {
	const { containerClassnames, menuClickCount,selectedMenuHasSubItems } = menu;
	const { locale } = settings;
	return { containerClassnames, menuClickCount, selectedMenuHasSubItems, locale, users };
};
export default injectIntl(connect(
	mapStateToProps,
	{ setContainerClassnames, clickOnMobileMenu, logoutUser, changeLocale }
)(TopNav));
