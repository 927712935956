import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	CONFIGS_FIND_ALL_BY_GROUP,
	CONFIGS_FIND_ALL_BY_GROUP_SUCCESS,
	CONFIGS_FIND_ALL_BY_GROUP_FAIL,
	CONFIGS_BATCH_SET_CONFIG,
	CONFIGS_BATCH_SET_CONFIG_SUCCESS,
	CONFIGS_BATCH_SET_CONFIG_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case CONFIGS_FIND_ALL_BY_GROUP:
		return state.set('configs', 'fetching');
	case CONFIGS_FIND_ALL_BY_GROUP_SUCCESS:
		return state.set('configs', 'fetched');
	case CONFIGS_FIND_ALL_BY_GROUP_FAIL:
		return state.set('configs', 'has-errors');
	case CONFIGS_BATCH_SET_CONFIG:
		return state.set('configs', 'submitting');
	case CONFIGS_BATCH_SET_CONFIG_SUCCESS:
		return state.set('configs', 'submitted');
	case CONFIGS_BATCH_SET_CONFIG_FAIL:
		return state.set('configs', 'has-errors');
	default:
		return state;
	}
};

const configsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case CONFIGS_FIND_ALL_BY_GROUP:
		return new Map();
	case CONFIGS_FIND_ALL_BY_GROUP_SUCCESS:
	case CONFIGS_BATCH_SET_CONFIG_SUCCESS:
		return state.set(action.payload.group, action.payload.items);
	default:
		return state;
	}
};

export default combineReducers({
	status  : statusReducer,
	configs : configsReducer,
});

export const selector = createStructuredSelector({
	status  : (state) => state.configs.status,
	configs : (state) => state.configs.configs,
});