import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TabButton from './TabButton.jsx';

class Tab extends PureComponent {
	render() {
		const { tabs, current, onChange } = this.props;
		return (
			<ul className="nav nav-pills nav-fill">
				{
					tabs.map((tab) => (
						<TabButton
							key={ `tab-${tab.id}` }
							id={ tab.id }
							name={ tab.name }
							active={ current === tab.id }
							onChange={ onChange }
						/>
					))
				}
			</ul>
		);
	}
}

Tab.propTypes = {
	tabs: PropTypes.array.isRequired,
	current: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default Tab;