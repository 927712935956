import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Row, Card, CardBody, Form, Button } from 'reactstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import CustomFormGroup from 'Components/CustomFormGroup';
import LoadingOverlay from 'Components/LoadingOverlay';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import IntlMessages from 'Util/IntlMessages';
import {
	warehouseGroupsCreate,
	warehouseGroupsUpdate,
	warehouseGroupsGetDetails,
	warehouseGroupsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as warehouseGroupsReducer from 'Redux/warehouseGroups/reducer';

class PageWarehouseGroup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			warehouseGroup: new Map({
				status: null,
				name: '',
				description: '',
				wh_codes: [],
			}),
		};
		this.fields = [
			{ id: 'name', type: 'text', label: 'Name' },
			{ id: 'description', type: 'textarea', label: 'Description' },
			{ id: 'wh_codes', type: 'react-select', label: 'Related Warehouses', onChange: this.onChangeWarehouseHandler.bind(this), isMulti: true },
			{ id: 'status', type: 'react-select', label: 'Status', onChange: this.onChangeStatusHandler.bind(this) },
		];
		this.breadcrumb = [
			{ id: 'menu.warehouse', href: '/warehouse' },
			{ id: 'menu.warehouse.manage-warehouse-group', href: '/warehouse/group/list' },
			{ id: 'warehouse-group.heading', href: `/warehouse/group/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.warehouseGroupsGetDetails(id);
		}
		this.props.warehouseGroupsGetOptions();
		this.props.errorReset('warehouseGroup');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevWarehouseGroup = prevProps.warehouseGroup.get(id);
			const warehouseGroup = this.props.warehouseGroup.get(id);
			if (warehouseGroup && warehouseGroup !== prevWarehouseGroup) {
				this.setState({
					warehouseGroup: this.state.warehouseGroup.merge(warehouseGroup),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('warehouseGroup');
		const status = this.props.status.get('warehouseGroup');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The warehouse group is ${id ? 'updated' : 'created'} successfully.`,
					`Warehouse Group ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/warehouse/group/edit/${current}`);
				const warehouseGroup = this.props.warehouseGroup.get(current);
				this.setState({
					warehouseGroup: this.state.warehouseGroup.merge(warehouseGroup),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The warehouse group is NOT ${id ? 'updated' : 'created'}.`,
					`Warehouse Group ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.warehouseGroupId);
	}

	onChangeHandler(e) {
		let { warehouseGroup } = this.state;
		let value;
		switch (e.target.id) {
		case 'item_ids':
			value = e.target.value.split(/[\n,]/).map((v) => v.trim());
			break;
		default:
			value = e.target.value;
			break;
		}
		this.setState({
			warehouseGroup: warehouseGroup.set(e.target.id, value),
		});
	}

	onChangeWarehouseHandler(selectedOption) {
		let { warehouseGroup } = this.state;
		this.setState({
			warehouseGroup: warehouseGroup.set('wh_codes', selectedOption),
		});
	}

	onChangeStatusHandler(selectedOption) {
		let { warehouseGroup } = this.state;
		this.setState({
			warehouseGroup: warehouseGroup.set('status', selectedOption),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let warehouseGroup = this.state.warehouseGroup.toJS();
		warehouseGroup.wh_codes = warehouseGroup.wh_codes ? warehouseGroup.wh_codes.map((warehouse) => warehouse.value) : null;
		warehouseGroup.status = warehouseGroup.status ? warehouseGroup.status.value : null;
		if (!id) {
			this.props.warehouseGroupsCreate(warehouseGroup);
		} else {
			this.props.warehouseGroupsUpdate(warehouseGroup);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { warehouseGroup } = this.state;
		const status = this.props.status.get('warehouseGroup');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="warehouse-group.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx md="10" className="mx-auto">
						<Card>
							<LoadingOverlay active={ isLoading }>
								<CardBody>
									<Form>
										{
											this.fields.map((field) => (
												<CustomFormGroup
													key={ `page-warehouse-group-${field.id}` }
													id={ field.id }
													label={ field.label }
													type={ field.type }
													value={ warehouseGroup.get(field.id) }
													disabled={ isLoading }
													errors={ errors && errors.detail[field.id] }
													onChange={ field.onChange ? field.onChange : this.onChangeHandler }
													options={ options.get(field.id) }
													isMulti={ field.isMulti }
												/>
											))
										}

										<div className="mt-4 text-center">
											<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
												SAVE
											</Button>
										</div>
									</Form>
								</CardBody>
							</LoadingOverlay>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	warehouseGroupsReducer.selector,
	{
		warehouseGroupsCreate,
		warehouseGroupsUpdate,
		warehouseGroupsGetDetails,
		warehouseGroupsGetOptions,
		errorReset,
	}
)(PageWarehouseGroup);