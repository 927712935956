import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class TabButton extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		const { id, onChange } = this.props;
		e.preventDefault();
		onChange(id);
	}

	render() {
		const { name, active } = this.props;
		return (
			<li className="nav-item">
				<a href="#" onClick={ this.onClickHandler } className={ classnames('nav-link', active && 'active') }>
					{ name }
				</a>
			</li>
		);
	}
}

TabButton.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default TabButton;