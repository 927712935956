import React, { Fragment } from 'react';
import { Card, CardBody, Table, Row } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import thousands from 'thousands';
import Tooltip from 'rc-tooltip';
import { Separator } from 'Components/CustomBootstrap';
import FormPromotionTierCondition from './FormPromotionTierCondition.jsx';
import 'rc-tooltip/assets/bootstrap.css';

class PromotionTierCondition extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			mode: 'read',
			index: null,
			conditions: props.conditions ? props.conditions : new List(),
		};
		this.onToggleModeHandler = this.onToggleModeHandler.bind(this);
		this.onEditHandler = this.onEditHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
		this.onClickReOrderUpHandler = this.onClickReOrderUpHandler.bind(this);
		this.onClickReOrderDownHandler = this.onClickReOrderDownHandler.bind(this);
		this.onClickEditHandler = this.onClickEditHandler.bind(this);
		this.onClickDeleteHandler = this.onClickDeleteHandler.bind(this);
		this.onClickAddHandler = this.onClickAddHandler.bind(this);
		this.renderParameters = this.renderParameters.bind(this);
		this.renderErrors = this.renderErrors.bind(this);
	}

	componentDidUpdate(prevProps) {
		const prevConditions = prevProps.conditions;
		const conditions = this.props.conditions;
		if (conditions !== prevConditions) {
			this.setState({
				conditions,
			});
		}
	}

	onToggleModeHandler() {
		const { mode } = this.state;
		this.setState({
			mode: mode === 'read' ? 'write' : 'read',
		});
	}

	onEditHandler(condition) {
		let { index, conditions } = this.state;
		if (index === null) {
			conditions = conditions.push(condition.set('ordering', conditions.size + 1));
		} else {
			conditions = conditions.set(index, condition);
		}
		this.props.onChange(conditions);
		this.setState({
			index: null,
			conditions,
		});
	}

	onCancelHandler(e) {
		e.preventDefault();
		const { conditions } = this.props;
		this.setState({
			mode: 'read',
			index: null,
			conditions,
		});
	}

	onClickReOrderUpHandler(e) {
		e.preventDefault();
		let { conditions } = this.state;
		const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
		if (index == 0) {
			return;
		}
		const condition = conditions.get(index).set('ordering', index);
		const prevCondition = conditions.get(index - 1).set('ordering', index + 1);
		conditions = conditions
			.set(index - 1, condition)
			.set(index, prevCondition)
		;
		this.setState({
			conditions,
		});
		this.props.onChange(conditions);
	}

	onClickReOrderDownHandler(e) {
		e.preventDefault();
		let { conditions } = this.state;
		const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
		if (index == conditions.size - 1) {
			return;
		}
		const condition = conditions.get(index).set('ordering', index + 2);
		const nextCondition = conditions.get(index + 1).set('ordering', index + 1);
		conditions = conditions
			.set(index, nextCondition)
			.set(index + 1, condition)
		;
		this.setState({
			conditions,
		});
		this.props.onChange(conditions);
	}

	onClickEditHandler(e) {
		e.preventDefault();
		const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
		this.setState({
			index,
		});
	}

	onClickDeleteHandler(e) {
		e.preventDefault();
		if (confirm('Are you sure to delete this condition?')) {
			const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
			let { conditions } = this.state;
			conditions = conditions.delete(index);
			this.setState({
				conditions,
			});
			this.props.onChange(conditions);
		}
	}

	onClickAddHandler(e) {
		e.preventDefault();
		this.setState({
			index: null,
		});
	}

	renderParameters(condition) {
		if (condition.get('criteria_type')) {
			switch (condition.get('criteria_type').value) {
			case 1: // Item Group
				return condition.get('parameters').map((parameter, index) => (
					<div key={ `promotion-tier-condition-parameter-${index}` }>
						{ parameter.label }
					</div>
				));
			case 8: // Coupon Code (Range)
				return `${condition.get('parameter')} - ${condition.get('parameterTo')}`;
			default:
				return condition.get('parameter');
			}
		}
	}

	renderErrors(index, field) {
		let { errors, tierIndex } = this.props;
		const key = `tiers.${tierIndex}.conditions.${index}.${field}`;
		if (errors && errors.detail && errors.detail[key]) {
			errors = errors.detail[key];
			return (
				<div className="text-danger">
					{
						errors.map((error, index) => (
							<Fragment key={ `promotion-tier-condition-${tierIndex}-${index}-error-${field}` }>
								{ index > 0 && <br /> }
								{ error }
							</Fragment>
						))
					}
				</div>
			);
		}
	}

	render() {
		const { options, tierIndex, defaults, isLoading } = this.props;
		const { mode, index, conditions } = this.state;
		return (
			<Card className="mb-5">
				<CardBody>
					<h3 className="d-block mb-4 text-primary">Tier { tierIndex + 1 } Conditions:</h3>
					<Table borderless>
						<thead>
							<tr>
								<th width="2%"></th>
								<th width="13%"></th>
								<th width="25%">Criteria</th>
								<th width={ mode === 'write' ? '35%' : '40%' }>Parameter</th>
								<th width={ mode === 'write' ? '7%' : '10%' }>Item</th>
								<th width={ mode === 'write' ? '8%' : '10%' }>Amount</th>
								{
									mode === 'write' && (
										<Fragment>
											<th width="10%"></th>
										</Fragment>
									)
								}
							</tr>
						</thead>
						<tbody>
							{
								conditions.map((condition, index) => (
									<tr key={ `promotion-tier-condition-${tierIndex}-${index}` }>
										<td>
											{
												condition.get('sales_description') && (
													<Tooltip placement="bottom" overlay={
														<span>{ condition.get('sales_description') }</span>
													}>
														<i className="simple-icon-info" />
													</Tooltip>
												)
											}
										</td>
										<td>
											{ condition.get('type') && condition.get('type').label }
											{ this.renderErrors(index, 'type') }
										</td>
										<td>
											{ condition.get('criteria_type') && condition.get('criteria_type').label }
											{ this.renderErrors(index, 'criteria_type') }
										</td>
										<td>
											{ this.renderParameters(condition) }
										</td>
										<td>
											{ condition.get('total_item_count_operator') }
											{ ' ' }
											{ thousands(condition.get('total_item_count_number')) }

											{ this.renderErrors(index, 'total_item_count_operator') }
											{ this.renderErrors(index, 'total_item_count_number') }
										</td>
										<td>
											{ condition.get('total_amount_operator') }
											{ ' ' }
											{ thousands(condition.get('total_amount_number')) }

											{ this.renderErrors(index, 'total_amount_operator') }
											{ this.renderErrors(index, 'total_amount_number') }
										</td>
										{
											mode === 'write' && (
												<td className="text-center">
													<i
														className="p-1 cursor-pointer simple-icon-arrow-up"
														data-index={ index }
														onClick={ this.onClickReOrderUpHandler }
													/>
													<i
														className="p-1 cursor-pointer simple-icon-arrow-down"
														data-index={ index }
														onClick={ this.onClickReOrderDownHandler }
													/>
													<i
														className="p-1 cursor-pointer simple-icon-pencil"
														data-index={ index }
														onClick={ this.onClickEditHandler }
													/>
													<i
														className="p-1 cursor-pointer simple-icon-ban"
														data-index={ index }
														onClick={ this.onClickDeleteHandler }
													/>
												</td>
											)
										}
									</tr>
								))
							}
						</tbody>
					</Table>

					{
						mode === 'write' && (
							<Fragment>
								<Separator className="mt-5 my-2" />
								<Row className='my-3'>
									<Colxx xxs="12" className="text-right">
										<button className="btn btn-outline-theme-3 icon-button" onClick={ this.onClickAddHandler }>
											<i className="simple-icon-plus" />
										</button>
									</Colxx>
								</Row>
								<FormPromotionTierCondition
									options={ options }
									defaults={ defaults }
									isLoading={ isLoading }
									index={ index }
									condition={ index !== null ? conditions.get(index) : null }
									onEdit={ this.onEditHandler }
									onCancel={ this.onCancelHandler }
								/>
							</Fragment>
						)
					}

					<div className="text-center">
						{
							mode !== 'write' && (
								<Fragment>
									<button className="btn btn-outline-theme-3" onClick={ this.onToggleModeHandler } disabled={ isLoading }>
										<i className="simple-icon-pencil mr-1" />
										Edit
									</button>
								</Fragment>
							)
						}
					</div>
				</CardBody>
			</Card>
		);
	}
}

PromotionTierCondition.propTypes = {
	tierIndex: PropTypes.number.isRequired,
	options: PropTypes.instanceOf(Map).isRequired,
	errors: PropTypes.object,
	defaults: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	conditions: PropTypes.instanceOf(List).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default PromotionTierCondition;