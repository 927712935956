import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import IntlMessages from 'Util/IntlMessages';

class BreadcrumbContainer extends PureComponent {
	render() {
		const { items, heading } = this.props;
		const { url } = this.props.match;
		const appPath = '/app';
		const homePath = '/app/home';
		return (
			<Fragment>
				{
					heading && (
						<h1>{ heading }</h1>
					)
				}
				<Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
					<BreadcrumbItem active={ homePath === url }>
						{
							homePath === url ? (
								<IntlMessages id="home.heading" />
							) : (
								<NavLink to={ homePath }>
									<IntlMessages id="home.heading" />
								</NavLink>
							)
						}
					</BreadcrumbItem>
					{
						items.map((item, index) => {
							const href = `${appPath}${item.href}`;
							return (
								<BreadcrumbItem key={ index } active={ href === url }>
									{
										(!item.href || href === url) ? (
											<IntlMessages id={ item.id } />
										) : (
											<NavLink to={ href }>
												<IntlMessages id={ item.id } />
											</NavLink>
										)
									}
								</BreadcrumbItem>
							);
						})
					}
				</Breadcrumb>
			</Fragment>
		);
	}
}

BreadcrumbContainer.propTypes = {
	items: PropTypes.array.isRequired,
	heading: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

export default BreadcrumbContainer;
