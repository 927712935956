import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class ModalHistory extends PureComponent {
	render() {
		const { isOpen, onToggle, histories } = this.props;
		return (
			<Modal isOpen={ isOpen } toggle={ onToggle }>
				<ModalHeader toggle={ onToggle }>
					Password Change History
				</ModalHeader>
				<ModalBody>
					{
						histories && histories.map((history, index) => (
							<div key={ `modal-history-${index}` }>
								{ history }
							</div>
						))
					}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={ onToggle }>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

ModalHistory.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	histories: PropTypes.array,
};

export default ModalHistory;