import React, { PureComponent, Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Row, Nav, NavItem, TabContent, TabPane, Form, FormGroup, Label, Card, CardBody, Button } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { Map } from 'immutable';
import DropzoneComponent from 'react-dropzone-component';
import CustomFormGroup from 'Components/CustomFormGroup';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import {
	paymentMethodsUpdate,
	paymentMethodsGetDetails,
	paymentMethodsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as paymentMethodsReducer from 'Redux/paymentMethods/reducer';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import IntlMessages from 'Util/IntlMessages';
import 'dropzone/dist/min/dropzone.min.css';

class PagePayment extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'details',
			paymentMethod: new Map({
				code: null,
				icon: null,
				warehouses: [],
			}),
		};
		this.breadcrumb = [
			{ id: 'menu.payment', href: '/payment/list' },
			{ id: 'payment.heading', href: `/payment/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.dropzoneComponentConfig = {
			postUrl: 'no-url',
			iconFiletypes: ['.png'],
		};
		this.dropzoneEventHandlers = {
			init: this.initDropzoneHandler.bind(this),
			addedfile: this.onAddedFileHandler.bind(this),
		};
		this.dropzoneConfig = {
			autoProcessQueue: false,
			dictDefaultMessage: 'Choose or Drop PNG file',
			thumbnailHeight: 160,
			maxFiles: 1,
			previewTemplate: ReactDOMServer.renderToStaticMarkup(
				<div className="dz-preview dz-file-preview mb-3">
					<div className="d-flex flex-row ">
						<div className="p-0 w-30 position-relative">
							<div className="dz-error-mark"><span><i></i>  </span></div>
							<div className="dz-success-mark"><span><i></i></span></div>
							<div className="preview-container">
								<img data-dz-thumbnail className="img-thumbnail border-0" />
								<i className="simple-icon-doc preview-icon"></i>
							</div>
						</div>
						<div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
							<div> <span data-dz-name /> </div>
							<div className="text-primary text-extra-small" data-dz-size />
							<div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress></span></div>
							<div className="dz-error-message"><span data-dz-errormessage></span></div>
						</div>
					</div>
					<a href="#" className="remove" data-dz-remove> <i className="glyph-icon simple-icon-trash"></i> </a>
				</div>
			),
		};
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeWarehousesHandler = this.onChangeWarehousesHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.paymentMethodsGetDetails(id);
		}
		this.props.paymentMethodsGetOptions();
		this.props.errorReset('paymentMethod');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevPaymentMethod = prevProps.paymentMethod.get(id);
			const paymentMethod = this.props.paymentMethod.get(id);
			if (paymentMethod && paymentMethod !== prevPaymentMethod) {
				this.setState({
					paymentMethod: this.state.paymentMethod.merge(paymentMethod).set('icon', null),
				});
				this.dropzone.removeAllFiles();
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('paymentMethod');
		const status = this.props.status.get('paymentMethod');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The payment method is ${id ? 'updated' : 'created'} successfully.`,
					`Payment Method ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/payment/edit/${current}`);
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The payment method is NOT ${id ? 'updated' : 'created'}.`,
					`Payment Method ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.paymentId;
	}

	onChangeHandler(e) {
		let { paymentMethod } = this.state;
		this.setState({
			paymentMethod: paymentMethod.set(e.target.id, e.target.value),
		});
	}

	onChangeWarehousesHandler(selectedOptions) {
		let { paymentMethod } = this.state;
		this.setState({
			paymentMethod: paymentMethod.set('warehouses', selectedOptions),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		let paymentMethod = this.state.paymentMethod.toJS();
		let data = {};
		data.code = paymentMethod.code;
		data.icon = paymentMethod.icon;
		data.wh_codes = paymentMethod.warehouses.map((warehouse) => warehouse.value);
		this.props.paymentMethodsUpdate(data);
	}

	initDropzoneHandler(dropzone) {
		this.dropzone = dropzone;
	}

	onAddedFileHandler(file) {
		const reader = new FileReader();
		reader.addEventListener('load', () => {
			const { paymentMethod } = this.state;
			const icon = reader.result.replace('data:image/png;base64,', '');
			this.setState({
				paymentMethod: paymentMethod.set('icon', icon),
			});
		}, false);
		reader.readAsDataURL(file);
	}

	render() {
		const { paymentMethod, activeTab } = this.state;
		const { options, errors } = this.props;
		const status = this.props.status.get('paymentMethod');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="payment.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<LoadingOverlay active={ isLoading }>
					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'details',
									'nav-link': true
								}) }
								to="#"
							>
								DETAILS
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={ activeTab }>
						<TabPane tabId="details">
							<Form>
								<Row>
									<Colxx md="4">
										<Card>
											<CardBody>
												<FormGroup>
													<Label>Payment Method:</Label>
													<h3>{ paymentMethod.get('description') }</h3>
												</FormGroup>
												<FormGroup>
													<Label>Payment Code:</Label>
													<h3>{ paymentMethod.get('code') }</h3>
												</FormGroup>
												<FormGroup>
													<Label>Is Cash:</Label>
													<h3>{ paymentMethod.get('is_cash') }</h3>
												</FormGroup>
												<FormGroup>
													<Label>Icon:</Label>
													<h3>
														{
															paymentMethod.get('pay_icon') && (
																<img src={ `data:image/png;base64,${paymentMethod.get('pay_icon')}` } className="list-thumbnail responsive border-0" />
															)
														}
													</h3>
													<DropzoneComponent
														config={ this.dropzoneComponentConfig }
														eventHandlers={ this.dropzoneEventHandlers }
														djsConfig={ this.dropzoneConfig }
													/>
												</FormGroup>
											</CardBody>
										</Card>
									</Colxx>
									<Colxx md="8">
										<Card>
											<CardBody>
												<p className="list-item-heading mb-4">Warehouses:</p>

												<CustomFormGroup
													key="page-payment-warehouses"
													id="warehouses"
													label="Applied to Warehouses"
													type="react-select"
													value={ paymentMethod.get('warehouses') }
													disabled={ isLoading }
													errors={ errors && errors.detail['warehouses'] }
													onChange={ this.onChangeWarehousesHandler }
													options={ options.get('warehouses') }
													isMulti={ true }
												/>

												<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading }>
													SAVE
												</Button>
											</CardBody>
										</Card>
									</Colxx>
								</Row>
							</Form>
						</TabPane>
					</TabContent>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	paymentMethodsReducer.selector,
	{
		paymentMethodsUpdate,
		paymentMethodsGetDetails,
		paymentMethodsGetOptions,
		errorReset,
	}
)(PagePayment);