import {
	WAREHOUSE_GROUPS_CREATE,
	WAREHOUSE_GROUPS_CREATE_SUCCESS,
	WAREHOUSE_GROUPS_CREATE_FAIL,
	WAREHOUSE_GROUPS_UPDATE,
	WAREHOUSE_GROUPS_UPDATE_SUCCESS,
	WAREHOUSE_GROUPS_UPDATE_FAIL,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	WAREHOUSE_GROUPS_GET_DETAILS,
	WAREHOUSE_GROUPS_GET_DETAILS_SUCCESS,
	WAREHOUSE_GROUPS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const warehouseGroupsCreate = (data) => ({
	type: WAREHOUSE_GROUPS_CREATE,
	payload: data,
});

export const warehouseGroupsCreateSuccess = (data) => {
	return ({
		type: WAREHOUSE_GROUPS_CREATE_SUCCESS,
		payload: data,
	});
};

export const warehouseGroupsCreateFail = (error) => ({
	type: WAREHOUSE_GROUPS_CREATE_FAIL,
	payload: error,
});

export const warehouseGroupsUpdate = (data) => ({
	type: WAREHOUSE_GROUPS_UPDATE,
	payload: data,
});

export const warehouseGroupsUpdateSuccess = (data) => {
	return ({
		type: WAREHOUSE_GROUPS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const warehouseGroupsUpdateFail = (error) => ({
	type: WAREHOUSE_GROUPS_UPDATE_FAIL,
	payload: error,
});

export const warehouseGroupsFindAllByPageAndOptions = (params) => ({
	type: WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const warehouseGroupsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const warehouseGroupsFindAllByPageAndOptionsFail = (error) => ({
	type: WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const warehouseGroupsGetDetails = (id) => ({
	type: WAREHOUSE_GROUPS_GET_DETAILS,
	payload: id,
});

export const warehouseGroupsGetDetailsSuccess = (data) => {
	return ({
		type: WAREHOUSE_GROUPS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const warehouseGroupsGetDetailsFail = (error) => ({
	type: WAREHOUSE_GROUPS_GET_DETAILS_FAIL,
	payload: error,
});

export const warehouseGroupsGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'warehouseGroup',
		params,
	},
});
