import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageStockTransferOrder from 'Pages/stock-transfer-order';
import PageStockTransferOrderPrint from 'Pages/stock-transfer-order-print';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/view/:type/:orderType/:transactionNumber`} component={ PageStockTransferOrder } />
			<Route exact path={`${match.url}/print/:type/:orderType/:transactionNumber`} component={ PageStockTransferOrderPrint } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;