import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import { CONFIGS_FIND_ALL_BY_GROUP, CONFIGS_BATCH_SET_CONFIG } from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	configsFindAllByGroupSuccess,
	configsFindAllByGroupFail,
	configsBatchSetConfigSuccess,
	configsBatchSetConfigFail,
} from './actions';

export function* watchConfigsFindAllByGroup() {
	yield takeEvery(CONFIGS_FIND_ALL_BY_GROUP, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/config/findAllByGroup`, { params: data });
			return response.data;
		},
		configsFindAllByGroupSuccess,
		configsFindAllByGroupFail
	));
}

export function* watchConfigsBatchSetConfig() {
	yield takeEvery(CONFIGS_BATCH_SET_CONFIG, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/config/batchSetConfig`, data);
			return response.data;
		},
		configsBatchSetConfigSuccess,
		configsBatchSetConfigFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchConfigsFindAllByGroup),
		fork(watchConfigsBatchSetConfig),
	]);
}