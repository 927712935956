import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import Pluralize from 'react-pluralize';
import ReactTable from 'react-table';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import IntlMessages from 'Util/IntlMessages';
import {
	paymentMethodsFindAll,
	errorReset,
} from 'Redux/actions';
import * as paymentMethodsReducer from 'Redux/paymentMethods/reducer';

class PagePayments extends PureComponent {
	constructor() {
		super();
		this.breadcrumb = [
			{ id: 'menu.payment', href: '/payment/list' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Method',
				accessor: (d) => (
					<NavLink to={ `/app/payment/edit/${d.code}` }>
						{
							d.pay_icon ? (
								<img
									src={ `data:image/png;base64,${d.pay_icon}` }
									className="icon mr-3"
								/>
							) : (
								<div className="icon mr-3 d-inline-block">&nbsp;</div>
							)
						}
						<span className="list-item-heading mb-1 truncate">{ d.description }</span>
					</NavLink>
				),
			},
			{
				id: 'code',
				Header: 'Code / Is Cash',
				accessor: (d) => `${d.code} / ${d.is_cash}`,
			},
			{
				id: 'warehouses',
				Header: 'Applied',
				sortable: false,
				accessor: (d) => (
					d.warehouses ? (
						<Pluralize singular="Warehouse" count={ d.warehouses.length } />
					) : (
						'N/A'
					)
				),
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/payment/edit/${d.code}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
	}

	componentDidMount() {
		this.fetchData();
		this.props.errorReset('paymentMethods');
	}

	fetchData() {
		this.props.paymentMethodsFindAll();
	}

	render() {
		const { paymentMethods } = this.props;
		const status = this.props.status.get('paymentMethods');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="payments.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									data={ paymentMethods.toJS() }
									loading={ status === 'fetching' }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	paymentMethodsReducer.selector,
	{
		paymentMethodsFindAll,
		errorReset,
	}
)(PagePayments);