import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { NavLink } from 'react-router-dom';
import { Map } from 'immutable';
import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { Row, Nav, NavItem, TabContent, TabPane, Card, CardTitle, CardBody, Form, Button, Table, CustomInput } from 'reactstrap';
import CustomFormGroup from 'Components/CustomFormGroup';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import IntlMessages from 'Util/IntlMessages';
import {
	userRolesCreate,
	userRolesUpdate,
	userRolesGetDetails,
	userRolesGetOptions,
	errorReset,
} from 'Redux/actions';
import * as userRolesReducer from 'Redux/userRoles/reducer';

class PageUserRole extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'permission',
			userRole: new Map({
				id: null,
				status: null,
				name: '',
				acls: new Map(),
			}),
		};
		this.fields = [
			{ id: 'name', type: 'text', label: 'Name' },
			{ id: 'status', type: 'react-select', label: 'Status', onChange: this.onChangeStatusHandler.bind(this) },
		];
		this.columns = [
			{
				id: 'user_id',
				Header: 'ID',
				accessor: (d) => (
					<NavLink
						to={ `/app/user/edit/${d.user_id}` }
						className="list-item-heading mb-1 truncate w-20 w-xs-100"
					>
						{ d.user_id }
					</NavLink>
				),
			},
			{
				id: 'user_name',
				Header: 'Name',
				accessor: (d) => (
					<p className="text-muted">
						{ d.user_name }
					</p>
				),
			},
			{
				id: 'role',
				Header: 'Role',
				accessor: (d) => (
					<p className="text-muted">
						{
							d.salesman_pos && (
								<strong>{ d.salesman_pos }</strong>
							)
						}
						<br />
						{ d.user_dept }
					</p>
				),
			},
			{
				Header: 'Level',
				accessor: 'user_level',
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					let className = 'badge-primary';
					let status = d.status;
					switch (status) {
					case 'I':
						className = 'badge-light';
						status = 'Inactive';
						break;
					case 'A':
						className = 'badge-primary';
						status = 'Active';
						break;
					}
					return (
						<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ status }</span>
					);
				},
			},
		];
		this.breadcrumb = [
			{ id: 'menu.user.manage-role', href: '/user-role/list' },
			{ id: 'user-role.heading', href: `/user-role/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.getUserRoleMap = this.getUserRoleMap.bind(this);
		this.onSelectPermissionTabHandler = this.onSelectTabHandler.bind(this, 'permission');
		this.onSelectUsersTabHandler = this.onSelectTabHandler.bind(this, 'users');
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSelectRadioHandler = this.onSelectRadioHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.renderModule = this.renderModule.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.userRolesGetDetails(id);
		}
		this.props.userRolesGetOptions();
		this.props.errorReset('userRole');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevUserRole = prevProps.userRole.get(id);
			const userRole = this.props.userRole.get(id);
			if (userRole && userRole !== prevUserRole) {
				this.setState({
					userRole: this.getUserRoleMap(userRole),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('userRole');
		const status = this.props.status.get('userRole');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The user role is ${id ? 'updated' : 'created'} successfully.`,
					`User Role ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/user-role/edit/${current}`);
				const userRole = this.props.userRole.get(current);
				this.setState({
					userRole: this.getUserRoleMap(userRole),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The user role is NOT ${id ? 'updated' : 'created'}.`,
					`User Role ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.userRoleId);
	}

	getUserRoleMap(userRole) {
		let ret = this.state.userRole.merge(userRole);
		let acls = {};
		userRole.acls.forEach((acl) => {
			acls[acl.name] = acl.permission;
		});
		return ret.set('acls', new Map(acls));
	}

	onSelectTabHandler(tab) {
		this.setState({
			activeTab: tab,
		});
	}

	onChangeHandler(e) {
		let { userRole } = this.state;
		this.setState({
			userRole: userRole.set(e.target.id, e.target.value),
		});
	}

	onChangeStatusHandler(selectedOption) {
		let { userRole } = this.state;
		this.setState({
			userRole: userRole.set('status', selectedOption),
		});
	}

	onSelectRadioHandler(e) {
		const { userRole } = this.state;
		const acls = userRole.get('acls');
		this.setState({
			userRole: userRole.set('acls', acls.set(e.target.name, e.target.value)),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let userRole = this.state.userRole.toJS();
		userRole.status = userRole.status ? userRole.status.value : null;
		userRole.acls = _.map(userRole.acls, (permission, name) => ({
			name,
			permission,
		}));
		if (!id) {
			this.props.userRolesCreate(userRole);
		} else {
			this.props.userRolesUpdate(userRole);
		}
	}

	renderModule(category, module, index) {
		let { options } = this.props;
		const acls = this.state.userRole.get('acls');
		if (module.type === 'rw') {
			options = options.get('permissions') && options.get('permissions').map((permission, j) => (
				<CustomInput
					id={ `${category}-${module.value}-${permission.value}` }
					key={ `page-user-role-module-${category}-${index}-${j}` }
					type="radio"
					inline
					name={ module.value }
					label={ permission.label }
					value={ permission.value }
					checked={ acls.get(module.value) === permission.value }
					onChange={ this.onSelectRadioHandler }
				/>
			));
		} else {
			options = (
				<Fragment>
					<CustomInput
						id={ `${category}-${module.value}- ` }
						type="radio"
						inline
						name={ module.value }
						label="No"
						value=" "
						checked={ acls.get(module.value) === ' ' }
						onChange={ this.onSelectRadioHandler }
					/>
					<CustomInput
						id={ `${category}-${module.value}-w` }
						type="radio"
						inline
						name={ module.value }
						label="Yes"
						value="w"
						checked={ acls.get(module.value) === 'w' }
						onChange={ this.onSelectRadioHandler }
					/>
				</Fragment>
			);
		}
		return (
			<Fragment key={ `page-user-role-module-${category}-${index}` }>
				<tr>
					{
						module.parent ? (
							<th>{ module.label }</th>
						) : (
							<td>{ module.label }</td>
						)
					}
					<td>{ module.desc }</td>
					<td>{ options }</td>
				</tr>
			</Fragment>
		);
	}

	render() {
		const { errors, options } = this.props;
		const { activeTab, userRole } = this.state;
		const id = this.getId();
		const status = this.props.status.get('userRole');
		const isLoading = status === 'fetching' || status === 'submitting';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="user-role.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'permission',
									'nav-link': true
								}) }
								onClick={ this.onSelectPermissionTabHandler }
								to="#"
							>
								PERMISSION
							</NavLink>
						</NavItem>
						{
							!!id && (
								<NavItem>
									<NavLink
										className={ classnames({
											active: activeTab === 'users',
											'nav-link': true
										}) }
										onClick={ this.onSelectUsersTabHandler }
										to="#"
									>
										VIEW USERS
									</NavLink>
								</NavItem>
							)
						}
					</Nav>

					<TabContent activeTab={ activeTab }>
						<TabPane tabId="permission">
							<Form onSubmit={ this.onSubmitHandler }>
								<Row>
									<Colxx lg="12">
										<Card>
											<CardBody>
												<h4>Role Name</h4>

												{
													this.fields.map((field) => (
														<CustomFormGroup
															key={ `page-user-role-${field.id}` }
															id={ field.id }
															label={ field.label }
															type={ field.type }
															value={ userRole.get(field.id) }
															disabled={ isLoading }
															errors={ errors && errors.detail[field.id] }
															onChange={ field.onChange ? field.onChange : this.onChangeHandler }
															options={ options.get(field.id) }
														/>
													))
												}

												{
													userRole.get('updated_on') && (
														<p>Updated at { moment(userRole.get('updated_on')).format('YYYY-MM-DD HH:mm:ss') }</p>
													)
												}
											</CardBody>
										</Card>
									</Colxx>
								</Row>

								<Row className="mt-4">
									<Colxx lg="12">
										<Card>
											<CardBody>
												<CardTitle>Backend Permission Control</CardTitle>
												<Table>
													<thead>
														<tr>
															<th width="20%">Module</th>
															<th width="50%">Name</th>
															<th width="30%">Permission</th>
														</tr>
													</thead>
													<tbody>
														{
															options.get('modules') && options.get('modules').backend.map((p, index) => this.renderModule('backend', p, index))
														}
													</tbody>
												</Table>
											</CardBody>
										</Card>
									</Colxx>
								</Row>

								<Row className="mt-4">
									<Colxx lg="12">
										<Card>
											<CardBody>
												<CardTitle>Shop Front Permission Control</CardTitle>
												<Table>
													<thead>
														<tr>
															<th width="20%">Module</th>
															<th width="50%">Name</th>
															<th width="30%">Permission</th>
														</tr>
													</thead>
													<tbody>
														{
															options.get('modules') && options.get('modules').shop_front.map((p, index) => this.renderModule('shop_front', p, index))
														}
													</tbody>
												</Table>
											</CardBody>
										</Card>
									</Colxx>
								</Row>

								<div className="mt-4 text-center">
									<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
										SAVE
									</Button>
								</div>
							</Form>
						</TabPane>
						<TabPane tabId="users">
							<Row>
								<Colxx xxs="12">
									<Card>
										<CardBody>
											<ReactTable
												data={ userRole.get('users') }
												loading={ status === 'fetching' }
												columns={ this.columns }
												minRows={ 0 }
												showPageJump={ true }
												showPageSizeOptions={ true }
												PaginationComponent={ DataTablePagination }
											/>
										</CardBody>
									</Card>
								</Colxx>
							</Row>
						</TabPane>
					</TabContent>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	userRolesReducer.selector,
	{
		userRolesCreate,
		userRolesUpdate,
		userRolesGetDetails,
		userRolesGetOptions,
		errorReset,
	}
)(PageUserRole);