import React, { PureComponent, Fragment } from 'react';
import { Row, Nav, NavItem, TabContent, TabPane, Form, FormGroup, Label, Card, CardTitle, CardBody, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap';
import Switch from 'rc-switch';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';
import LoadingOverlay from 'Components/LoadingOverlay';
import classnames from 'classnames';
import { Map, List } from 'immutable';
import CustomFormGroup from 'Components/CustomFormGroup';
import { Colxx } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import {
	warehousesUpdate,
	warehousesGetDetails,
	warehousesGetOptions,
	warehousesGetIPWhitelists,
	warehousesBatchUpdateIPWhitelists,
	warehousesGetDomainNameWhitelists,
	warehousesBatchUpdateDomainNameWhitelists,
	warehousesGetBssids,
	warehousesBatchUpdateBssids,
	errorReset,
} from 'Redux/actions';
import * as warehousesReducer from 'Redux/warehouses/reducer';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import IntlMessages from 'Util/IntlMessages';

class PageWarehouse extends PureComponent {
	constructor(props) {
		super(props);
		this.defaultIPWhitelist = new Map({
			id: null,
			ip_address: '',
			desc: null,
			enabled: true,
		});
		this.defaultDomainNameWhitelist = new Map({
			id: null,
			domain_name: '',
			desc: null,
			enabled: true,
		});
		this.defaultBssid = new Map({
			id: null,
			bssid: '',
			desc: null,
			enabled: true,
		});
		this.state = {
			activeTab: 'details',
			showModalIP: false,
			showModalDomainName: false,
			showModalBssid: false,
			currentIndex: null,
			warehouse: new Map({
				wh_code: null,
				wh_status: 'A',
				login_url: null,
				wechat_id: null,
				email: null,
				company: null,
				base_curr: 'HKD',
				warehouse_groups: [],
				stock_transfer_destinations: [],
				retail_group: null,
				document_types: [],
				payment_methods: [],
				show_barcode: null,
				show_footer: null,
				show_exchange_rate: null,
				default_order_type_r: null,
				enable_emergency: false,
				emergency_time_from: null,
				emergency_time_to: null,
				assigned_user: null,
				enable_business_hour: null,
				business_hour_from: null,
				business_hour_to: null,
				disable_sales_order_outside_business_hour: null,
				disable_service_memo_outside_business_hour: null,
				disable_void_order_outside_business_hour: null,
				disable_return_item_outside_business_hour: null,
				disable_redeem_q_to_r_outside_business_hour: null,
				disable_deposit_refund_outside_business_hour: null,
				enable_bssid: null,
			}),
			ipWhitelists: new List(),
			domainNameWhitelists: new List(),
			bssids: new List(),
			modalData: null,
		};
		this.fields = {
			details: [
				{ id: 'wh_desc', label: 'Warehouse' },
				{ id: 'wh_code', label: 'Shop Code' },
				{ id: 'wh_type', label: 'Type' },
				{ id: 'wh_tel', label: 'Phone' },
				{ id: 'wh_fax', label: 'Fax' },
				{ id: 'wh_add', label: 'Address' },
			],
			detailsInput: [
				{ id: 'login_url', type: 'text', label: 'Login URL (leave empty to disable)' },
				{ id: 'wechat_id', type: 'text', label: 'WeChat ID' },
				{ id: 'email', type: 'text', label: 'E-mail' },
			],
			detailsInvoice: [
				{ id: 'show_barcode', type: '', label: 'Show barcode in invoice', onChange: this.onToggleSwitchHandler.bind(this, 'show_barcode') },
				{ id: 'show_footer', type: '', label: 'Show footer in invoice', onChange: this.onToggleSwitchHandler.bind(this, 'show_footer') },
				{ id: 'default_order_type_r', type: '', label: 'Set default order type to R', onChange: this.onToggleSwitchHandler.bind(this, 'default_order_type_r') },
				{ id: 'show_exchange_rate', type: '', label: 'Show exchange rate in payment page', onChange: this.onToggleSwitchHandler.bind(this, 'show_exchange_rate') },
			],
			detailsSelect: [
				{ id: 'company', label: 'Company', isMulti: false, onChange: this.onChangeMultipleHandler.bind(this, 'company') },
				{ id: 'wh_status', label: 'Status', isMulti: false, onChange: this.onChangeMultipleHandler.bind(this, 'wh_status') },
			],
			currency: [
				{ id: 'base_curr', label: 'Base Currency', isMulti: false, onChange: this.onChangeMultipleHandler.bind(this, 'base_curr') },
			],
			group: [
				{ id: 'warehouse_groups', label: 'Warehouse Groups', isMulti: true, onChange: this.onChangeMultipleHandler.bind(this, 'warehouse_groups') },
				{ id: 'stock_transfer_destinations', label: 'Available Stock Transfer Destinations', isMulti: true, onChange: this.onChangeMultipleHandler.bind(this, 'stock_transfer_destinations') },
				{ id: 'retail_group', label: 'Price Group', isMulti: false, onChange: this.onChangeMultipleHandler.bind(this, 'retail_group') },
			],
			document_type: [
				{ id: 'document_types', label: 'Document Types', desc: 'Allowed Document Type', isMulti: true, onChange: this.onChangeMultipleHandler.bind(this, 'document_types') },
			],
			payment_method: [
				{ id: 'payment_methods', label: 'Payment Methods', desc: 'Applied Payment Method', isMulti: true, onChange: this.onChangeMultipleHandler.bind(this, 'payment_methods') },
			],
			emergency: [
				{ id: 'enable_emergency', type: 'switch', label: 'Enable No IP Filter Mode', onChange: this.onToggleSwitchHandler.bind(this, 'enable_emergency') },
				{ id: 'emergency_time_from', type: 'time' , label: 'Time Range (From)' },
				{ id: 'emergency_time_to', type: 'time' , label: 'Time Range (To)' },
				{ id: 'assigned_user', type: 'select', label: 'Assigned User', onChange: this.onChangeMultipleHandler.bind(this, 'assigned_user') }
			],
			business_hour: [
				{ id: 'enable_business_hour', type: 'switch', label: 'Enable Out of Business Hour Restriction', onChange: this.onToggleSwitchHandler.bind(this, 'enable_business_hour') },
				{ id: 'business_hour_from', type: 'time' , label: 'Business Hour (From)' },
				{ id: 'business_hour_to', type: 'time' , label: 'Business Hour (To)' },
				{ id: 'out_of_business_hour_restrictuion', type: 'title', label: 'Restrict the following functions outside business hour' },
				{ id: 'disable_sales_order_outside_business_hour', type: 'switch', label: 'Create sales order', onChange: this.onToggleSwitchHandler.bind(this, 'disable_sales_order_outside_business_hour') },
				{ id: 'disable_service_memo_outside_business_hour', type: 'switch', label: 'Create service memo', onChange: this.onToggleSwitchHandler.bind(this, 'disable_service_memo_outside_business_hour') },
				{ id: 'disable_void_order_outside_business_hour', type: 'switch', label: 'Void order', onChange: this.onToggleSwitchHandler.bind(this, 'disable_void_order_outside_business_hour') },
				{ id: 'disable_return_item_outside_business_hour', type: 'switch', label: 'Return item', onChange: this.onToggleSwitchHandler.bind(this, 'disable_return_item_outside_business_hour') },
				{ id: 'disable_redeem_q_to_r_outside_business_hour', type: 'switch', label: 'Redeem order', onChange: this.onToggleSwitchHandler.bind(this, 'disable_redeem_q_to_r_outside_business_hour') },
				{ id: 'disable_deposit_refund_outside_business_hour', type: 'switch', label: 'Deposit refund', onChange: this.onToggleSwitchHandler.bind(this, 'disable_deposit_refund_outside_business_hour') },
			],
			bssid: [
				{ id: 'enable_bssid', type: '', label: 'Enable BSSID whitelist', onChange: this.onToggleSwitchHandler.bind(this, 'enable_bssid') },
			],
		};
		this.breadcrumb = [
			{ id: 'menu.warehouse', href: '/warehouse/list' },
			{ id: 'menu.warehouse.warehouse-list', href: '/warehouse/list' },
			{ id: 'warehouse.heading', href: `/warehouse/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onToggleIPWhitelistHandler = this.onToggleIPWhitelistHandler.bind(this);
		this.onEditIPWhitelistHandler = this.onEditIPWhitelistHandler.bind(this);
		this.onDeleteIPWhitelistHandler = this.onDeleteIPWhitelistHandler.bind(this);
		this.onToggleDomainNameWhitelistHandler = this.onToggleDomainNameWhitelistHandler.bind(this);
		this.onEditDomainNameWhitelistHandler = this.onEditDomainNameWhitelistHandler.bind(this);
		this.onDeleteDomainNameWhitelistHandler = this.onDeleteDomainNameWhitelistHandler.bind(this);
		this.onToggleBssidHandler = this.onToggleBssidHandler.bind(this);
		this.onEditBssidHandler = this.onEditBssidHandler.bind(this);
		this.onDeleteBssidHandler = this.onDeleteBssidHandler.bind(this);
		this.onSelectDetailsTabHandler = this.onSelectTabHandler.bind(this, 'details');
		this.onSelectBusinessHourTabHandler = this.onSelectTabHandler.bind(this, 'businessHour');
		this.onSelectIPTabHandler = this.onSelectTabHandler.bind(this, 'ip');
		this.onSelectDomainNameTabHandler = this.onSelectTabHandler.bind(this, 'domainName');
		this.onSelectBssidTabHandler = this.onSelectTabHandler.bind(this, 'bssid');
		this.onSelectEmergencyTabHandler = this.onSelectTabHandler.bind(this, 'emergency');
		this.onToggleModalIPHandler = this.onToggleModalIPHandler.bind(this);
		this.onCancelModalIPHandler = this.onCancelModalIPHandler.bind(this);
		this.onConfirmModalIPHandler = this.onConfirmModalIPHandler.bind(this);
		this.onToggleModalDomainNameHandler = this.onToggleModalDomainNameHandler.bind(this);
		this.onCancelModalDomainNameHandler = this.onCancelModalDomainNameHandler.bind(this);
		this.onConfirmModalDomainNameHandler = this.onConfirmModalDomainNameHandler.bind(this);
		this.onToggleModalBssidHandler = this.onToggleModalBssidHandler.bind(this);
		this.onCancelModalBssidHandler = this.onCancelModalBssidHandler.bind(this);
		this.onConfirmModalBssidHandler = this.onConfirmModalBssidHandler.bind(this);
		this.onChangeModalIPAddressHandler = this.onChangeModalIPAddressHandler.bind(this);
		this.onChangeModalDomainNameHandler = this.onChangeModalDomainNameHandler.bind(this);
		this.onChangeModalBssidHandler = this.onChangeModalBssidHandler.bind(this);
		this.onChangeModalDescHandler = this.onChangeModalDescHandler.bind(this);
		this.onChangeModalStatusHandler = this.onChangeModalStatusHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.renderField = this.renderField.bind(this);
		this.renderSelect = this.renderSelect.bind(this);
		this.renderSwitch = this.renderSwitch.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.warehousesGetDetails(id);
			this.props.warehousesGetIPWhitelists(id);
			this.props.warehousesGetDomainNameWhitelists(id);
			this.props.warehousesGetBssids(id);
		}
		this.props.warehousesGetOptions();
		this.props.errorReset('warehouse');
		this.props.errorReset('warehouseIPWhitelists');
		this.props.errorReset('warehouseDomainNameWhitelists');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevWarehouse = prevProps.warehouse.get(id);
			const warehouse = this.props.warehouse.get(id);
			if (warehouse && warehouse !== prevWarehouse) {
				this.setState({
					warehouse: this.state.warehouse.merge(warehouse),
				});
			}

			const prevIPWhitelists = prevProps.ipWhitelists.get(id);
			const ipWhitelists = this.props.ipWhitelists.get(id);
			if (ipWhitelists && ipWhitelists !== prevIPWhitelists) {
				this.setState({
					ipWhitelists: new List(ipWhitelists.map((ipWhitelist) => this.defaultIPWhitelist.merge(ipWhitelist))),
				});
			}

			const prevDomainNameWhitelists = prevProps.domainNameWhitelists.get(id);
			const domainNameWhitelists = this.props.domainNameWhitelists.get(id);
			if (domainNameWhitelists && domainNameWhitelists !== prevDomainNameWhitelists) {
				this.setState({
					domainNameWhitelists: new List(domainNameWhitelists.map((domainNameWhitelist) => this.defaultDomainNameWhitelist.merge(domainNameWhitelist))),
				});
			}

			const prevBssids = prevProps.bssids.get(id);
			const bssids = this.props.bssids.get(id);
			if (bssids && bssids !== prevBssids) {
				this.setState({
					bssids: new List(bssids.map((bssid) => this.defaultBssid.merge(bssid))),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('warehouse');
		const status = this.props.status.get('warehouse');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The warehouse is ${id ? 'updated' : 'created'} successfully.`,
					`Warehouse ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/warehouse/edit/${current}`);
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The warehouse is NOT ${id ? 'updated' : 'created'}.`,
					`Warehouse ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.warehouseId;
	}

	onChangeHandler(e) {
		let { warehouse } = this.state;
		this.setState({
			warehouse: warehouse.set(e.target.id, e.target.value),
		});
	}

	onToggleIPWhitelistHandler(index, value) {
		const { ipWhitelists } = this.state;
		this.setState({
			ipWhitelists: ipWhitelists.set(
				index,
				ipWhitelists.get(index).set('enabled', value)
			),
		});
	}

	onEditIPWhitelistHandler(index) {
		const { ipWhitelists } = this.state;
		this.setState({
			showModalIP: true,
			currentIndex: index,
			modalData: ipWhitelists.get(index),
		});
	}

	onDeleteIPWhitelistHandler(index) {
		if (confirm('Are you sure to delete this IP address?')) {
			const { ipWhitelists } = this.state;
			this.setState({
				ipWhitelists: ipWhitelists.delete(index),
			});
		}
	}

	onToggleDomainNameWhitelistHandler(index, value) {
		const { domainNameWhitelists } = this.state;
		this.setState({
			domainNameWhitelists: domainNameWhitelists.set(
				index,
				domainNameWhitelists.get(index).set('enabled', value)
			),
		});
	}

	onEditDomainNameWhitelistHandler(index) {
		const { domainNameWhitelists } = this.state;
		this.setState({
			showModalDomainName: true,
			currentIndex: index,
			modalData: domainNameWhitelists.get(index),
		});
	}

	onDeleteDomainNameWhitelistHandler(index) {
		if (confirm('Are you sure to delete this domain name?')) {
			const { domainNameWhitelists } = this.state;
			this.setState({
				domainNameWhitelists: domainNameWhitelists.delete(index),
			});
		}
	}

	onToggleBssidHandler(index, value) {
		const { bssids } = this.state;
		this.setState({
			bssids: bssids.set(
				index,
				bssids.get(index).set('enabled', value)
			),
		});
	}

	onEditBssidHandler(index) {
		const { bssids } = this.state;
		this.setState({
			showModalBssid: true,
			currentIndex: index,
			modalData: bssids.get(index),
		});
	}

	onDeleteBssidHandler(index) {
		if (confirm('Are you sure to delete this BSSID?')) {
			const { bssids } = this.state;
			this.setState({
				bssids: bssids.delete(index),
			});
		}
	}

	onSelectTabHandler(tab) {
		this.setState({
			activeTab: tab,
		});
	}

	onChangeMultipleHandler(field, selectedOptions) {
		const { warehouse } = this.state;
		this.setState({
			warehouse: warehouse.set(field, selectedOptions),
		});
	}

	onToggleSwitchHandler(field) {
		const { warehouse } = this.state;
		const value = warehouse.get(field);
		this.setState({
			warehouse: warehouse.set(field, !value),
		});
	}

	onToggleModalIPHandler() {
		const { showModalIP } = this.state;
		this.setState({
			showModalIP: !showModalIP,
			modalData: this.defaultIPWhitelist,
			currentIndex: null,
		});
	}

	onCancelModalIPHandler(e) {
		e.preventDefault();
		this.setState({
			showModalIP: false,
			currentIndex: null,
			modalData: null,
		});
	}

	onConfirmModalIPHandler(e) {
		e.preventDefault();
		const { modalData, ipWhitelists, currentIndex } = this.state;
		this.setState({
			showModalIP: false,
			currentIndex: null,
			modalData: null,
			ipWhitelists: currentIndex === null ? ipWhitelists.push(modalData) : ipWhitelists.set(currentIndex, modalData),
		});
	}

	onToggleModalDomainNameHandler() {
		const { showModalDomainName } = this.state;
		this.setState({
			showModalDomainName: !showModalDomainName,
			modalData: this.defaultDomainNameWhitelist,
			currentIndex: null,
		});
	}

	onCancelModalDomainNameHandler(e) {
		e.preventDefault();
		this.setState({
			showModalDomainName: false,
			currentIndex: null,
			modalData: null,
		});
	}

	onConfirmModalDomainNameHandler(e) {
		e.preventDefault();
		const { modalData, domainNameWhitelists, currentIndex } = this.state;
		this.setState({
			showModalDomainName: false,
			currentIndex: null,
			modalData: null,
			domainNameWhitelists: currentIndex === null ? domainNameWhitelists.push(modalData) : domainNameWhitelists.set(currentIndex, modalData),
		});
	}

	onToggleModalBssidHandler() {
		const { showModalBssid } = this.state;
		this.setState({
			showModalBssid: !showModalBssid,
			modalData: this.defaultBssid,
			currentIndex: null,
		});
	}

	onCancelModalBssidHandler(e) {
		e.preventDefault();
		this.setState({
			showModalBssid: false,
			currentIndex: null,
			modalData: null,
		});
	}

	onConfirmModalBssidHandler(e) {
		e.preventDefault();
		const { modalData, bssids, currentIndex } = this.state;
		this.setState({
			showModalBssid: false,
			currentIndex: null,
			modalData: null,
			bssids: currentIndex === null ? bssids.push(modalData) : bssids.set(currentIndex, modalData),
		});
	}

	onChangeModalIPAddressHandler(e) {
		const { modalData } = this.state;
		this.setState({
			modalData: modalData.set('ip_address', e.target.value),
		});
	}

	onChangeModalDomainNameHandler(e) {
		const { modalData } = this.state;
		this.setState({
			modalData: modalData.set('domain_name', e.target.value),
		});
	}

	onChangeModalBssidHandler(e) {
		const { modalData } = this.state;
		this.setState({
			modalData: modalData.set('bssid', e.target.value),
		});
	}

	onChangeModalDescHandler(e) {
		const { modalData } = this.state;
		this.setState({
			modalData: modalData.set('desc', e.target.value),
		});
	}

	onChangeModalStatusHandler(value) {
		const { modalData } = this.state;
		this.setState({
			modalData: modalData.set('enabled', value),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let warehouse = this.state.warehouse.toJS();
		let data = {};
		data.wh_code = id;
		data.wh_status = warehouse.wh_status ? warehouse.wh_status.value : null;
		data.login_url = warehouse.login_url;
		data.wechat_id = warehouse.wechat_id;
		data.email = warehouse.email;
		data.base_curr = warehouse.base_curr ? warehouse.base_curr.value : null;
		data.company_id = warehouse.company ? warehouse.company.value : null;
		data.warehouse_group_ids = warehouse.warehouse_groups.map((warehouseGroup) => warehouseGroup.value);
		data.stock_transfer_destination_ids = warehouse.stock_transfer_destinations.map((warehouseGroup) => warehouseGroup.value);
		data.retail_group = warehouse.retail_group ? warehouse.retail_group.value.trim() : null;
		data.doc_types = warehouse.document_types.map((document_type) => document_type.value.trim());
		data.pay_codes = warehouse.payment_methods.map((payment_method) => payment_method.value.trim());
		data.show_barcode = warehouse.show_barcode;
		data.show_footer = warehouse.show_footer;
		data.show_exchange_rate = warehouse.show_exchange_rate;
		data.default_order_type_r = warehouse.default_order_type_r;
		data.enable_emergency = warehouse.enable_emergency;
		data.emergency_time_from = warehouse.emergency_time_from;
		data.emergency_time_to = warehouse.emergency_time_to;
		data.assigned_user = warehouse.assigned_user ? warehouse.assigned_user.value.trim() : null;
		data.enable_business_hour = warehouse.enable_business_hour;
		data.business_hour_from = warehouse.business_hour_from;
		data.business_hour_to = warehouse.business_hour_to;
		data.disable_sales_order_outside_business_hour = warehouse.disable_sales_order_outside_business_hour;
		data.disable_service_memo_outside_business_hour = warehouse.disable_service_memo_outside_business_hour;
		data.disable_void_order_outside_business_hour = warehouse.disable_void_order_outside_business_hour;
		data.disable_return_item_outside_business_hour = warehouse.disable_return_item_outside_business_hour;
		data.disable_redeem_q_to_r_outside_business_hour = warehouse.disable_redeem_q_to_r_outside_business_hour;
		data.disable_deposit_refund_outside_business_hour = warehouse.disable_deposit_refund_outside_business_hour;
		data.enable_bssid = warehouse.enable_bssid;
		this.props.warehousesUpdate(data);

		let ipWhitelists = {
			warehouse_code: id,
			delete: true,
			whitelist: this.state.ipWhitelists.toJS(),
		};
		this.props.warehousesBatchUpdateIPWhitelists(ipWhitelists);

		let domainNameWhitelists = {
			warehouse_code: id,
			delete: true,
			whitelist: this.state.domainNameWhitelists.toJS(),
		};
		this.props.warehousesBatchUpdateDomainNameWhitelists(domainNameWhitelists);

		let bssids = {
			warehouse_code: id,
			delete: true,
			whitelist: this.state.bssids.toJS(),
		};
		this.props.warehousesBatchUpdateBssids(bssids);
	}

	filterEnabledDomainNameWhitelist(domainNameWhitelist) {
		return domainNameWhitelist.get('enabled');
	}

	renderField(warehouse, field) {
		let value = field.id ? warehouse.get(field.id) : '';
		if (field.id === 'wh_type') {
			switch (value) {
			case 'C':
				value = 'Consignment';
				break;
			case 'S':
				value = 'Shop';
				break;
			case 'W':
				value = 'Warehouse';
				break;
			}
		}
		return (
			<FormGroup key={ `page-warehouse-form-group-${field.id}` }>
				<Label>{ field.label }:</Label>
				<h3>{ value }</h3>
			</FormGroup>
		);
	}

	renderSelect(field, isLoading) {
		const { warehouse } = this.state;
		const { errors } = this.props;
		const  options = this.props.options.get(field.id === 'stock_transfer_destinations' ? 'warehouse_groups' : field.id);
		return (
			<CustomFormGroup
				key={ `page-warehouse-${field.id}` }
				id={ field.id }
				label={ field.label }
				type="react-select"
				value={ warehouse.get(field.id) }
				disabled={ isLoading }
				errors={ errors && errors.detail[field.id] }
				onChange={ field.onChange }
				options={ options }
				isMulti={ field.isMulti }
			/>
		);
	}

	renderSwitch(field) {
		const { warehouse } = this.state;
		return (
			<FormGroup key={ `page-warehouse-form-group-${field.id}` }>
				<Label>{ field.label }</Label>
				<Switch
					className="custom-switch custom-switch-primary"
					checked={ warehouse.get(field.id) == '1' }
					onChange={ field.onChange }
				/>
			</FormGroup>
		);
	}

	render() {
		const { errors, errorsIPWhitelists, errorsDomainNameWhitelists, errorsBssids, options } = this.props;
		const { warehouse, activeTab, showModalIP, showModalDomainName, showModalBssid, ipWhitelists, domainNameWhitelists, bssids, currentIndex, modalData } = this.state;
		const status = this.props.status.get('warehouse');
		const ipWhitelistsStatus = this.props.status.get('ipWhitelists');
		const domainNameWhitelistsStatus = this.props.status.get('domainNameWhitelists');
		const bssidsStatus = this.props.status.get('bssids');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || ipWhitelistsStatus === 'fetching' || ipWhitelistsStatus === 'submitting' || domainNameWhitelistsStatus === 'fetching' || domainNameWhitelistsStatus === 'submitting' || bssidsStatus === 'fetching' || bssidsStatus === 'submitting' || optionsStatus === 'fetching';
		const enableBssid = warehouse.get('enable_bssid') === true || warehouse.get('enable_bssid') === '1';
		const isInvalid = (
			(warehouse.get('login_url') && !enableBssid) ||
			(!warehouse.get('login_url') && enableBssid)
		);
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={
								<Fragment>
									<IntlMessages id="warehouse.heading" />: { warehouse.get('wh_desc') }
								</Fragment>
							}
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<LoadingOverlay active={ isLoading }>
					{
						!!(warehouse.get('login_url') && !enableBssid) && (
							<Alert color="danger">
								Please enable BSSID list for app login if you decide to use login URL for web login.
							</Alert>
						)
					}
					{
						!!(!warehouse.get('login_url') && enableBssid) && (
							<Alert color="danger">
								Please enable login URL for web login if you decide to use BSSID list for app login.
							</Alert>
						)
					}
					{
						domainNameWhitelists.filter(this.filterEnabledDomainNameWhitelist).size > 0 &&
						(!warehouse.get('login_url') || !enableBssid) && (
							<Alert color="warning">
								Please enable both login URL for web login and BSSID list for using domain name whitelist.
							</Alert>
						)
					}

					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'details',
									'nav-link': true
								}) }
								onClick={ this.onSelectDetailsTabHandler }
								to="#"
							>
								DETAILS
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'businessHour',
									'nav-link': true
								}) }
								onClick={ this.onSelectBusinessHourTabHandler }
								to="#"
							>
								BUSINESS HOUR
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'ip',
									'nav-link': true
								}) }
								onClick={ this.onSelectIPTabHandler }
								to="#"
							>
								IP
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'domainName',
									'nav-link': true
								}) }
								onClick={ this.onSelectDomainNameTabHandler }
								to="#"
							>
								DOMAIN NAME
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'bssid',
									'nav-link': true
								}) }
								onClick={ this.onSelectBssidTabHandler }
								to="#"
							>
								BSSID
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={ classnames({
									active: activeTab === 'emergency',
									'nav-link': true
								}) }
								onClick={ this.onSelectEmergencyTabHandler }
								to="#"
							>
								EMERGENCY
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={ activeTab }>
						<TabPane tabId="details">
							<Form>
								<Row>
									<Colxx md="6">
										<Card>
											<CardBody>
												{
													this.fields.details.map((field) => this.renderField(warehouse, field))
												}

												{
													this.fields.detailsInput.map((field) => (
														<CustomFormGroup
															key={ `page-warehouse-${field.id}` }
															id={ field.id }
															label={ field.label }
															type={ field.type }
															value={ warehouse.get(field.id) }
															disabled={ isLoading }
															errors={ errors && errors.detail[field.id] }
															onChange={ this.onChangeHandler }
														/>
													))
												}

												{ this.fields.detailsSelect.map((field) => this.renderSelect(field, isLoading)) }
											</CardBody>
										</Card>
									</Colxx>
									<Colxx md="6">
										<Card className="mb-4">
											<CardBody>
												{ this.fields.currency.map((field) => this.renderSelect(field, isLoading)) }
												<CustomFormGroup
													id="currency_rate"
													label={ 'Currency Rate' + (get(warehouse.get('base_curr'), 'value') ? ` (1 ${get(warehouse.get('base_curr'), 'value')} = ? HKD)` : '') }
													type="text"
													value={ (get(options.get('exch_rates'), get(warehouse.get('base_curr'), 'value'))) || '' }
													disabled
												/>
												<small>Update the currency rate in <code>[exchange_2]</code></small>
											</CardBody>
										</Card>

										<Card className="mb-4">
											<CardBody>
												{ this.fields.group.map((field) => this.renderSelect(field, isLoading)) }
											</CardBody>
										</Card>

										<Card className="mb-4">
											<CardBody>
												{ this.fields.document_type.map((field) => this.renderSelect(field, isLoading)) }
											</CardBody>
										</Card>

										<Card className="mb-4">
											<CardBody>
												{ this.fields.payment_method.map((field) => this.renderSelect(field, isLoading)) }
											</CardBody>
										</Card>

										<Card>
											<CardBody>
												{ this.fields.detailsInvoice.map((field) => this.renderSwitch(field, isLoading)) }
											</CardBody>
										</Card>
									</Colxx>
								</Row>
							</Form>

							<div className="mt-4 mb-3 text-center">
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading || isInvalid }>
									SAVE
								</Button>
							</div>
						</TabPane>
						<TabPane tabId="businessHour">
							<Card>
								<CardBody>
									<CardTitle>Out of Business Hour Restriction</CardTitle>
									{
										this.fields.business_hour.map((field) => {
											switch(field.type) {
											case 'switch':
												return this.renderSwitch(field);
											case 'title':
												return (
													<Fragment key={ `page-warehouse-${field.id}` }>
														<hr className="mt-4 mb-3" />
														<h4 className="mt-4 mb-3">{ field.label }</h4>
													</Fragment>
												);
											case 'time':
												return (
													<CustomFormGroup
														key={ `page-warehouse-${field.id}` }
														id={ field.id }
														label={ field.label }
														type={ field.type }
														value={ warehouse.get(field.id) }
														disabled={ isLoading }
														errors={ errors && errors.detail[field.id] }
														onChange={ this.onChangeHandler }
													/>
												);
											}
										})
									}
								</CardBody>
							</Card>
							<div className="mt-4 mb-3 text-center">
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading }>
									SAVE
								</Button>
							</div>
						</TabPane>
						<TabPane tabId="ip">
							<Card>
								<CardBody>
									<CardTitle>IP Whitelist Control</CardTitle>
									<Table hover>
										<thead>
											<tr>
												<th width="30%">IP</th>
												<th width="35%">Description</th>
												<th width="15%">Status</th>
												<th width="20%"></th>
											</tr>
										</thead>
										<tbody>
											{
												ipWhitelists.map((ipWhitelist, index) => (
													<tr key={ `page-warehouse-ip-whitelist-${index}` }>
														<th>
															<h4 className={ classnames(errorsIPWhitelists && errorsIPWhitelists.detail[`whitelist.${index}.ip_address`] && 'text-danger') }>
																{ ipWhitelist.get('ip_address') }
															</h4>
															{
																errorsIPWhitelists && errorsIPWhitelists.detail[`whitelist.${index}.ip_address`] && (
																	<div className="text-danger">
																		{ errorsIPWhitelists.detail[`whitelist.${index}.ip_address`] }
																	</div>
																)
															}
														</th>
														<td>
															<h4 className={ classnames(errorsIPWhitelists && errorsIPWhitelists.detail[`whitelist.${index}.desc`] && 'text-danger') }>
																{ ipWhitelist.get('desc') }
															</h4>
															{
																errorsIPWhitelists && errorsIPWhitelists.detail[`whitelist.${index}.desc`] && (
																	<div className="text-danger">
																		{ errorsIPWhitelists.detail[`whitelist.${index}.desc`] }
																	</div>
																)
															}
														</td>
														<td>
															<Switch
																className="custom-switch custom-switch-primary"
																checked={ ipWhitelist.get('enabled') }
																onChange={ this.onToggleIPWhitelistHandler.bind(this, index) }
															/>
														</td>
														<td className="text-right">
															<Button size="sm" outline color="primary" className="mr-2" disabled={ isLoading } onClick={ this.onEditIPWhitelistHandler.bind(this, index) }>
																<i className="simple-icon-pencil btn-group-icon" />
															</Button>
															<Button size="sm" outline color="danger" disabled={ isLoading } onClick={ this.onDeleteIPWhitelistHandler.bind(this, index) }>
																<i className="simple-icon-close btn-group-icon" />
															</Button>
														</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>

							<div className="mt-4 mb-3 text-center">
								<Button outline color="primary" size="lg" className="mr-2" onClick={ this.onToggleModalIPHandler } disabled={ isLoading }>
									ADD NEW
								</Button>
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading || isInvalid }>
									SAVE
								</Button>
							</div>
						</TabPane>
						<TabPane tabId="domainName">
							<Card>
								<CardBody>
									<CardTitle>Domain Name Whitelist Control</CardTitle>
									<Table hover>
										<thead>
											<tr>
												<th width="30%">Domain Name</th>
												<th width="35%">Description</th>
												<th width="15%">Status</th>
												<th width="20%"></th>
											</tr>
										</thead>
										<tbody>
											{
												domainNameWhitelists.map((domainNameWhitelist, index) => (
													<tr key={ `page-warehouse-domain-name-whitelist-${index}` }>
														<th>
															<h4 className={ classnames(errorsDomainNameWhitelists && errorsDomainNameWhitelists.detail[`whitelist.${index}.domain_name`] && 'text-danger') }>
																{ domainNameWhitelist.get('domain_name') }
															</h4>
															{
																errorsDomainNameWhitelists && errorsDomainNameWhitelists.detail[`whitelist.${index}.domain_name`] && (
																	<div className="text-danger">
																		{ errorsDomainNameWhitelists.detail[`whitelist.${index}.domain_name`] }
																	</div>
																)
															}
														</th>
														<td>
															<h4 className={ classnames(errorsDomainNameWhitelists && errorsDomainNameWhitelists.detail[`whitelist.${index}.desc`] && 'text-danger') }>
																{ domainNameWhitelist.get('desc') }
															</h4>
															{
																errorsDomainNameWhitelists && errorsDomainNameWhitelists.detail[`whitelist.${index}.desc`] && (
																	<div className="text-danger">
																		{ errorsDomainNameWhitelists.detail[`whitelist.${index}.desc`] }
																	</div>
																)
															}
														</td>
														<td>
															<Switch
																className="custom-switch custom-switch-primary"
																checked={ domainNameWhitelist.get('enabled') }
																onChange={ this.onToggleDomainNameWhitelistHandler.bind(this, index) }
															/>
														</td>
														<td className="text-right">
															<Button size="sm" outline color="primary" className="mr-2" disabled={ isLoading } onClick={ this.onEditDomainNameWhitelistHandler.bind(this, index) }>
																<i className="simple-icon-pencil btn-group-icon" />
															</Button>
															<Button size="sm" outline color="danger" disabled={ isLoading } onClick={ this.onDeleteDomainNameWhitelistHandler.bind(this, index) }>
																<i className="simple-icon-close btn-group-icon" />
															</Button>
														</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>

							<div className="mt-4 mb-3 text-center">
								<Button outline color="primary" size="lg" className="mr-2" onClick={ this.onToggleModalDomainNameHandler } disabled={ isLoading }>
									ADD NEW
								</Button>
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading || isInvalid }>
									SAVE
								</Button>
							</div>
						</TabPane>
						<TabPane tabId="bssid">
							<Card>
								<CardBody>
									<CardTitle>BSSID List</CardTitle>
									{ this.fields.bssid.map((field) => this.renderSwitch(field, isLoading)) }
									<Table hover className="mt-4">
										<thead>
											<tr>
												<th width="30%">BSSID</th>
												<th width="35%">Description</th>
												<th width="15%">Status</th>
												<th width="20%"></th>
											</tr>
										</thead>
										<tbody>
											{
												bssids.map((bssid, index) => (
													<tr key={ `page-warehouse-bssid-${index}` }>
														<th>
															<h4 className={ classnames(errorsBssids && errorsBssids.detail[`whitelist.${index}.bssid`] && 'text-danger') }>
																{ bssid.get('bssid') }
															</h4>
															{
																errorsBssids && errorsBssids.detail[`whitelist.${index}.bssid`] && (
																	<div className="text-danger">
																		{ errorsBssids.detail[`whitelist.${index}.bssid`] }
																	</div>
																)
															}
														</th>
														<td>
															<h4 className={ classnames(errorsBssids && errorsBssids.detail[`whitelist.${index}.desc`] && 'text-danger') }>
																{ bssid.get('desc') }
															</h4>
															{
																errorsBssids && errorsBssids.detail[`whitelist.${index}.desc`] && (
																	<div className="text-danger">
																		{ errorsBssids.detail[`whitelist.${index}.desc`] }
																	</div>
																)
															}
														</td>
														<td>
															<Switch
																className="custom-switch custom-switch-primary"
																checked={ bssid.get('enabled') }
																onChange={ this.onToggleBssidHandler.bind(this, index) }
															/>
														</td>
														<td className="text-right">
															<Button size="sm" outline color="primary" className="mr-2" disabled={ isLoading } onClick={ this.onEditBssidHandler.bind(this, index) }>
																<i className="simple-icon-pencil btn-group-icon" />
															</Button>
															<Button size="sm" outline color="danger" disabled={ isLoading } onClick={ this.onDeleteBssidHandler.bind(this, index) }>
																<i className="simple-icon-close btn-group-icon" />
															</Button>
														</td>
													</tr>
												))
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>

							<div className="mt-4 mb-3 text-center">
								<Button outline color="primary" size="lg" className="mr-2" onClick={ this.onToggleModalBssidHandler } disabled={ isLoading }>
									ADD NEW
								</Button>
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading || isInvalid }>
									SAVE
								</Button>
							</div>
						</TabPane>

						<TabPane tabId="emergency">
							<Card>
								<CardBody>
									<CardTitle>Emergency Control</CardTitle>

									{
										this.fields.emergency.map((field) => {
											switch (field.type) {
											case 'switch':
												return this.renderSwitch(field);
											case 'time':
												return (
													<CustomFormGroup
														key={ `page-warehouse-${field.id}` }
														id={ field.id }
														label={ field.label }
														type={ field.type }
														value={ warehouse.get(field.id) }
														disabled={ isLoading }
														errors={ errors && errors.detail[field.id] }
														onChange={ this.onChangeHandler }
													/>
												);
											case 'select':
												return this.renderSelect(field, isLoading);
											}
										})
									}
								</CardBody>
							</Card>

							<div className="mt-4 mb-3 text-center">
								<Button color="primary" size="lg" onClick={ this.onSubmitHandler } disabled={ isLoading || isInvalid }>
									SAVE
								</Button>
							</div>
						</TabPane>
					</TabContent>
				</LoadingOverlay>

				<Modal
					isOpen={ showModalIP }
					toggle={ this.onCancelModalIPHandler }
					wrapClassName="modal-right"
				>
					<Form onSubmit={ this.onConfirmModalIPHandler }>
						<ModalHeader>
							{ currentIndex === null ? 'New' : 'Edit' } IP
						</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label>IP Address</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('ip_address') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalIPAddressHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Description</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('desc') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalDescHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Status</Label>
								<Switch
									className="custom-switch custom-switch-primary"
									checked={ modalData && modalData.get('enabled') }
									onChange={ this.onChangeModalStatusHandler }
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button outline color="primary" onClick={ this.onCancelModalIPHandler } disabled={ isLoading }>
								Cancel
							</Button>
							<Button type="submit" color="primary" onClick={ this.onConfirmModalIPHandler } disabled={ isLoading }>
								Submit
							</Button>
						</ModalFooter>
					</Form>
				</Modal>

				<Modal
					isOpen={ showModalDomainName }
					toggle={ this.onCancelModalDomainNameHandler }
					wrapClassName="modal-right"
				>
					<Form onSubmit={ this.onConfirmModalDomainNameHandler }>
						<ModalHeader>
							{ currentIndex === null ? 'New' : 'Edit' } Domain Name
						</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label>Domain Name</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('domain_name') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalDomainNameHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Description</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('desc') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalDescHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Status</Label>
								<Switch
									className="custom-switch custom-switch-primary"
									checked={ modalData && modalData.get('enabled') }
									onChange={ this.onChangeModalStatusHandler }
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button outline color="primary" onClick={ this.onCancelModalDomainNameHandler } disabled={ isLoading }>
								Cancel
							</Button>
							<Button type="submit" color="primary" onClick={ this.onConfirmModalDomainNameHandler } disabled={ isLoading }>
								Submit
							</Button>
						</ModalFooter>
					</Form>
				</Modal>

				<Modal
					isOpen={ showModalBssid }
					toggle={ this.onCancelModalBssidHandler }
					wrapClassName="modal-right"
				>
					<Form onSubmit={ this.onConfirmModalBssidHandler }>
						<ModalHeader>
							{ currentIndex === null ? 'New' : 'Edit' } BSSID
						</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label>BSSID</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('bssid') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalBssidHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Description</Label>
								<Input
									type="text"
									value={ modalData && modalData.get('desc') || '' }
									disabled={ isLoading }
									onChange={ this.onChangeModalDescHandler }
								/>
							</FormGroup>
							<FormGroup>
								<Label>Status</Label>
								<Switch
									className="custom-switch custom-switch-primary"
									checked={ modalData && modalData.get('enabled') }
									onChange={ this.onChangeModalStatusHandler }
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button outline color="primary" onClick={ this.onCancelModalBssidHandler } disabled={ isLoading }>
								Cancel
							</Button>
							<Button type="submit" color="primary" onClick={ this.onConfirmModalBssidHandler } disabled={ isLoading }>
								Submit
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
			</Fragment>
		);
	}
}

export default connect(
	warehousesReducer.selector,
	{
		warehousesUpdate,
		warehousesGetDetails,
		warehousesGetOptions,
		warehousesGetIPWhitelists,
		warehousesBatchUpdateIPWhitelists,
		warehousesGetDomainNameWhitelists,
		warehousesBatchUpdateDomainNameWhitelists,
		warehousesGetBssids,
		warehousesBatchUpdateBssids,
		errorReset,
	}
)(PageWarehouse);