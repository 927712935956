import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactLoadingOverlay from 'react-loading-overlay';
import Spinner from 'Components/Spinner';

class LoadingOverlay extends PureComponent {
	constructor() {
		super();
		this.styles = {
			overlay: (base) => ({
				...base,
				background: 'rgba(255, 255, 255, 0.7)',
			}),
			// spinner: (base) => ({
			// 	...base,
			// 	'& svg circle': {
			// 		stroke: 'rgba(0, 0, 0, 1)'
			// 	}
			// }),
			content: (base) => ({
				...base,
				color: '#000',
			}),
		};
	}

	render() {
		const { active, children } = this.props;
		return (
			<ReactLoadingOverlay
				active={ active }
				spinner={ <Spinner /> }
				text="Loading..."
				styles={ this.styles }
			>
				{ children }
			</ReactLoadingOverlay>
		);
	}
}

LoadingOverlay.propTypes = {
	active: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
};

export default LoadingOverlay;