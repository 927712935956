import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactSpinner from 'react-spinkit';

class Spinner extends PureComponent {
	render() {
		const { load, className } = this.props;
		if (!load) {
			return null;
		}
		return (
			<ReactSpinner
				name="pulse"
				fadeIn="none"
				className={ className }
			/>
		);
	}
}

Spinner.propTypes = {
	load: PropTypes.bool,
	className: PropTypes.string,
};

Spinner.defaultProps = {
	load: true,
	className: 'mx-3 rc-spinner',
};

export default Spinner;