import React, { PureComponent, Fragment } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import LoadingOverlay from 'Components/LoadingOverlay';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import CustomFormGroup from 'Components/CustomFormGroup';
import { usersChangePassword, errorReset } from 'Redux/actions';
import * as usersReducer from 'Redux/users/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageChangePassword extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			original: '',
			password: '',
			confirmPassword: '',
			mismatch: false,
			incorrect: false,
		};
		this.breadcrumb = [
			{ id: 'menu.change-password', href: '/user/change-password' },
		];
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('password');
	}

	componentDidUpdate(prevProps) {
		const prevPasswordStatus = prevProps.status.get('password');
		const passwordStatus = this.props.status.get('password');
		const { errorsPassword } = this.props;
		if (passwordStatus && prevPasswordStatus !== passwordStatus) {
			switch (passwordStatus) {
			case 'submitted':
				NotificationManager.success(
					'The user password is updated successfully.',
					'User Password Updated'
				);
				this.setState({
					original: '',
					password: '',
					confirmPassword: '',
				});
				break;
			case 'has-errors':
				NotificationManager.error(
					'Error occured! The user password is NOT updated',
					'User Password Update Failed'
				);
				if (errorsPassword && errorsPassword.code === 'USER0011') {
					this.setState({
						incorrect: true,
					});
				}
				break;
			}
		}
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.id] = e.target.value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		const { current } = this.props;
		const { original, password, confirmPassword } = this.state;
		e.preventDefault();
		let newState = { mismatch: false, incorrect: false };
		if (password || confirmPassword) {
			if (password === confirmPassword) {
				let data = {};
				data.username = current.get('user_id');
				data.original = original;
				data.password = password;
				this.props.usersChangePassword(data);
			} else {
				alert('The password and confirmation password do not match. Please fix it and try again.');
				newState.mismatch = true;
			}
		}
		this.setState(newState);
	}

	render() {
		const { errorsPassword } = this.props;
		const { original, password, confirmPassword, mismatch, incorrect } = this.state;
		const status = this.props.status.get('password');
		const currentStatus = this.props.status.get('current');
		const isLoading = status === 'fetching' || status === 'submitting' || currentStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="change-password.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Form onSubmit={ this.onSubmitHandler }>
					<Row className="mb-4">
						<Col md="3" />
						<Col md="6">
							<Card>
								<LoadingOverlay active={ isLoading }>
									<CardBody>
										<FormGroup>
											<Label for="original">Current Password</Label>
											<Input
												type="password"
												id="original"
												value={ original }
												disabled={ isLoading }
												onChange={ this.onChangeHandler }
												invalid={ incorrect }
											/>
											{
												incorrect && (
													<div className="invalid-feedback d-block">
														Incorrect password.
													</div>
												)
											}
										</FormGroup>

										<CustomFormGroup
											id="password"
											label="New Password"
											type="password"
											value={ password }
											disabled={ isLoading }
											errors={ errorsPassword && errorsPassword.detail.password }
											onChange={ this.onChangeHandler }
										/>

										<FormGroup>
											<Label for="confirmPassword">Confirm Password</Label>
											<Input
												type="password"
												id="confirmPassword"
												value={ confirmPassword }
												disabled={ isLoading }
												onChange={ this.onChangeHandler }
												invalid={ mismatch }
											/>
											{
												mismatch && (
													<div className="invalid-feedback d-block">
														The password and confirmation password do not match. Please fix it and try again.
													</div>
												)
											}
										</FormGroup>

										<div className="text-center">
											<Button size="lg" color="primary" disabled={ isLoading || password == '' || confirmPassword == '' }>
												SAVE
											</Button>
										</div>
									</CardBody>
								</LoadingOverlay>
							</Card>
						</Col>
					</Row>
				</Form>
			</Fragment>
		);
	}
}

export default connect(
	usersReducer.selector,
	{
		usersChangePassword,
		errorReset,
	}
)(PageChangePassword);