import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageItemGroups from 'Pages/item-groups';
import PageItemGroup from 'Pages/item-group';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/list`} component={ PageItemGroups } />
			<Route exact path={`${match.url}/create`} component={ PageItemGroup } />
			<Route exact path={`${match.url}/edit/:itemGroupId`} component={ PageItemGroup } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;