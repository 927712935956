import {
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	TRANSACTIONS_GET_DETAILS,
	TRANSACTIONS_GET_DETAILS_SUCCESS,
	TRANSACTIONS_GET_DETAILS_FAIL,
	TRANSACTIONS_PRINT,
	TRANSACTIONS_PRINT_SUCCESS,
	TRANSACTIONS_PRINT_FAIL,
} from 'Constants/actionTypes';

export const transactionsFindAllByPageAndOptions = (params) => ({
	type: TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const transactionsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const transactionsFindAllByPageAndOptionsFail = (error) => ({
	type: TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const transactionsGetDetails = (transactionNumber) => ({
	type: TRANSACTIONS_GET_DETAILS,
	payload: transactionNumber,
});

export const transactionsGetDetailsSuccess = (data) => {
	return ({
		type: TRANSACTIONS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const transactionsGetDetailsFail = (error) => ({
	type: TRANSACTIONS_GET_DETAILS_FAIL,
	payload: error,
});

export const transactionsPrint = (transactionNumber) => ({
	type: TRANSACTIONS_PRINT,
	payload: transactionNumber,
});

export const transactionsPrintSuccess = (data) => {
	return ({
		type: TRANSACTIONS_PRINT_SUCCESS,
		payload: data,
	});
};

export const transactionsPrintFail = (error) => ({
	type: TRANSACTIONS_PRINT_FAIL,
	payload: error,
});