import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import { TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS, TRANSACTIONS_GET_DETAILS, TRANSACTIONS_PRINT } from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import { transactionsFindAllByPageAndOptionsSuccess, transactionsFindAllByPageAndOptionsFail, transactionsGetDetailsSuccess, transactionsGetDetailsFail, transactionsPrintSuccess, transactionsPrintFail } from './actions';

export function* watchTransactionsFindAllByPageAndOptions() {
	yield takeEvery(TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			if (data.doc_type === 'TO' || data.doc_type === 'TI') {
				const response = await Axios.get(`${apiUrl}/stockTransfer/listTransfer${data.doc_type === 'TO' ? 'Out' : 'In'}OrdersByPageAndOptions`, { params: data });
				return response.data;
			} else {
				const response = await Axios.get(`${apiUrl}/transaction/findAllByPageAndOptions`, { params: data });
				return response.data;
			}
		},
		transactionsFindAllByPageAndOptionsSuccess,
		transactionsFindAllByPageAndOptionsFail
	));
}

export function* watchTransactionsGetDetails() {
	yield takeEvery(TRANSACTIONS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			if (data.transaction_number && data.order_type) {
				const response = await Axios.get(`${apiUrl}/stockTransfer/details`, { params: data });
				return response.data;
			} else {
				const response = await Axios.get(`${apiUrl}/transaction/details`, { params: { transaction_number: data } });
				return response.data;
			}
		},
		transactionsGetDetailsSuccess,
		transactionsGetDetailsFail
	));
}

export function* watchTransactionsPrint() {
	yield takeEvery(TRANSACTIONS_PRINT, defaultFetchDataFunc(
		async (data) => {
			if (data.transaction_number && data.order_type) {
				const response = await Axios.get(`${apiUrl}/stockTransfer/print`, { params: data });
				return response.data;
			} else {
				const response = await Axios.post(`${apiUrl}/transaction/print`, { trx_no: data, signature: '' });
				return response.data;
			}
		},
		transactionsPrintSuccess,
		transactionsPrintFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchTransactionsFindAllByPageAndOptions),
		fork(watchTransactionsGetDetails),
		fork(watchTransactionsPrint),
	]);
}