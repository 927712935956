import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Row, Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import moment from 'moment';
import CustomFormGroup from 'Components/CustomFormGroup';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import LoadingOverlay from 'Components/LoadingOverlay';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import IntlMessages from 'Util/IntlMessages';
import {
	remarksCreate,
	remarksUpdate,
	remarksGetDetails,
	remarksGetOptions,
	errorReset,
} from 'Redux/actions';
import * as remarksReducer from 'Redux/remarks/reducer';

class PageRemark extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			remark: new Map({
				code: null,
				type: null,
				content: '',
			}),
		};
		this.fields = [
			{ id: 'type', type: 'react-select', label: 'Document Type', onChange: this.onChangeTypeHandler.bind(this) },
			{ id: 'content', type: 'textarea', label: 'Description', extraProps: { rows: 5, maxLength: 255 } },
		];
		this.breadcrumb = [
			{ id: 'menu.remark', href: '/remark/list' },
			{ id: 'remark.heading', href: `/remark/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.remarksGetDetails(id);
		}
		this.props.remarksGetOptions();
		this.props.errorReset('remark');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevRemark = prevProps.remark.get(id);
			const remark = this.props.remark.get(id);
			if (remark && remark !== prevRemark) {
				this.setState({
					remark: this.state.remark.merge(remark),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('remark');
		const status = this.props.status.get('remark');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The remark template is ${id ? 'updated' : 'created'} successfully.`,
					`Remark Template ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/remark/edit/${current}`);
				const remark = this.props.remark.get(current);
				this.setState({
					remark: this.state.remark.merge(remark),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The remark template is NOT ${id ? 'updated' : 'created'}.`,
					`Remark Template ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.remarkId;
	}

	onChangeHandler(e) {
		const { remark } = this.state;
		this.setState({
			remark: remark.set(e.target.id, e.target.value),
		});
	}

	onChangeTypeHandler(selectedOption) {
		let { remark } = this.state;
		this.setState({
			remark: remark.set('type', selectedOption),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let remark = this.state.remark.toJS();
		remark.type = remark.type ? remark.type.value : null;
		if (!id) {
			this.props.remarksCreate(remark);
		} else {
			this.props.remarksUpdate(remark);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { remark } = this.state;
		const id = this.getId();
		const status = this.props.status.get('remark');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="remark.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Form onSubmit={ this.onSubmitHandler }>
					<Row>
						<Colxx xxs="1" />
						<Colxx xxs="10">
							<Card>
								<LoadingOverlay active={ isLoading }>
									<CardBody>
										{
											!!id && (
												<FormGroup>
													<Label for="name">Remark Code</Label>
													<Input
														type="text"
														id="code"
														value={ remark.get('code') || '' }
														disabled={ isLoading }
														// readOnly={ !!id }
														readOnly
														onChange={ this.onChangeHandler }
													/>
												</FormGroup>
											)
										}

										{
											this.fields.map((field) => (
												<CustomFormGroup
													key={ `page-remark-${field.id}` }
													id={ field.id }
													label={ field.label }
													type={ field.type }
													value={ remark.get(field.id) }
													disabled={ isLoading }
													errors={ errors && errors.detail[field.id] }
													onChange={ field.onChange ? field.onChange : this.onChangeHandler }
													options={ options.get(field.id) }
													isMulti={ field.isMulti }
													extraProps={ field.extraProps }
												/>
											))
										}

										{
											remark.get('updated_on') && (
												<p>Updated at { moment(remark.get('updated_on')).format('YYYY-MM-DD HH:mm:ss') }</p>
											)
										}

										<div className="mt-4 text-center">
											<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
												SAVE
											</Button>
										</div>
									</CardBody>
								</LoadingOverlay>
							</Card>
						</Colxx>
					</Row>
				</Form>
			</Fragment>
		);
	}
}

export default connect(
	remarksReducer.selector,
	{
		remarksCreate,
		remarksUpdate,
		remarksGetDetails,
		remarksGetOptions,
		errorReset,
	}
)(PageRemark);