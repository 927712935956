import {
	CONFIGS_FIND_ALL_BY_GROUP,
	CONFIGS_FIND_ALL_BY_GROUP_SUCCESS,
	CONFIGS_FIND_ALL_BY_GROUP_FAIL,
	CONFIGS_BATCH_SET_CONFIG,
	CONFIGS_BATCH_SET_CONFIG_SUCCESS,
	CONFIGS_BATCH_SET_CONFIG_FAIL,
} from 'Constants/actionTypes';

export const configsFindAllByGroup = (group) => ({
	type: CONFIGS_FIND_ALL_BY_GROUP,
	payload: { group }
});

export const configsFindAllByGroupSuccess = (data) => {
	return ({
		type: CONFIGS_FIND_ALL_BY_GROUP_SUCCESS,
		payload: data,
	});
};

export const configsFindAllByGroupFail = (error) => ({
	type: CONFIGS_FIND_ALL_BY_GROUP_FAIL,
	payload: error,
});

export const configsBatchSetConfig = (data) => ({
	type: CONFIGS_BATCH_SET_CONFIG,
	payload: data
});

export const configsBatchSetConfigSuccess = (data) => {
	return ({
		type: CONFIGS_BATCH_SET_CONFIG_SUCCESS,
		payload: data,
	});
};

export const configsBatchSetConfigFail = (error) => ({
	type: CONFIGS_BATCH_SET_CONFIG_FAIL,
	payload: error,
});