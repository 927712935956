import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';
import { Map } from 'immutable';
import ReactSelect from 'react-select';
import CustomSelectInput from 'Components/CustomSelectInput';

class FormPromotionTierCondition extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			condition: props.defaults,
		};
		this.getCriteriaTypes = this.getCriteriaTypes.bind(this);
		this.showTotalRow = this.showTotalRow.bind(this);
		this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
		this.onChangeTypeHandler = this.onChangeReactSelectHandler.bind(this, 'type');
		this.onChangeCriteriaTypeHandler = this.onChangeReactSelectHandler.bind(this, 'criteria_type');
		this.onChangeParametersHandler = this.onChangeReactSelectHandler.bind(this, 'parameters');
		this.onAddHandler = this.onAddHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
		this.renderSelect = this.renderSelect.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderParameters = this.renderParameters.bind(this);
	}

	componentDidUpdate(prevProps) {
		const condition = this.props.condition;
		const prevCondition = prevProps.condition;
		if (condition !== prevCondition) {
			this.setState({
				condition: condition ? condition : this.props.defaults,
			});
		}
	}

	getCriteriaTypes() {
		const { options } = this.props;
		const { condition } = this.state;
		const type = condition.get('type');
		const criteriaTypes = options.get('condition.criteria_type');
		return type ? criteriaTypes.filter((criteriaType) => criteriaType.allowedTypes && criteriaType.allowedTypes.indexOf(type.value) !== -1) : criteriaTypes;
	}

	showTotalRow() {
		const { condition } = this.state;
		const criteriaType = condition.get('criteria_type');
		if (criteriaType) {
			switch (criteriaType.value) {
			case 4: // Member tier
			case 5: // Member code
			case 7: // Coupon code
			case 8: // Coupon code (range)
				return false;
			}
		}
		return true;
	}

	onChangeInputHandler(e) {
		const { condition } = this.state;
		let value = e.target.value;
		switch (e.target.id) {
		case 'total_item_count_number':
		case 'total_amount_number':
			value = parseInt(value);
			break;
		}
		this.setState({
			condition: condition.set(e.target.id, value),
		});
	}

	onChangeReactSelectHandler(field, value) {
		let { condition } = this.state;
		condition = condition.set(field, value);
		switch (field) {
		case 'type':
			if (value.label === 'Invoice') {
				condition = condition
					.set('total_item_count_operator', null)
					.set('total_item_count_number', null)
					.set('total_amount_operator', null)
					.set('total_amount_number', null)
				;
			}
			if (this.state.condition.get(field) !== value) {
				condition = condition.set('criteria_type', null);
			}
			break;
		case 'criteria_type':
			if (this.state.condition.get(field) !== value) {
				condition = condition
					.set('parameter', '')
					.set('parameters', [])
					.set('total_item_count_operator', null)
					.set('total_item_count_number', null)
					.set('total_amount_operator', null)
					.set('total_amount_number', null)
				;
			}
			break;
		}
		this.setState({
			condition,
		});
	}

	onAddHandler(e) {
		e.preventDefault();
		const { condition } = this.state;
		this.props.onEdit(condition);
		this.setState({
			condition: this.props.defaults,
		});
	}

	onCancelHandler(e) {
		this.props.onCancel(e);
	}

	renderSelect(field, label, options, onChange, isMulti = false) {
		const { isLoading } = this.props;
		const { condition } = this.state;
		return (
			<FormGroup>
				<Label className="has-float-label">
					<ReactSelect
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						value={ condition.get(field) }
						isDisabled={ isLoading }
						isClearable={ true }
						isMulti={ isMulti }
						options={ options }
						onChange={ onChange }
					/>
					<span>{ label }</span>
				</Label>
			</FormGroup>
		);
	}

	renderInput(field, label, disableFormGroup) {
		const { isLoading } = this.props;
		const { condition } = this.state;
		const body = (
			<Label className="has-float-label">
				<Input
					type="text"
					id={ field }
					value={ !condition.get(field) && condition.get(field) != 0 ? '' : condition.get(field) }
					disabled={ isLoading }
					onChange={ this.onChangeInputHandler }
				/>
				<span>{ label }</span>
			</Label>
		);
		if (disableFormGroup) {
			return body;
		}
		return (
			<FormGroup>
				{ body }
			</FormGroup>
		);
	}

	renderParameters() {
		const { options } = this.props;
		const { condition } = this.state;
		const criteriaType = condition.get('criteria_type');
		if (criteriaType) {
			switch (criteriaType.value) {
			case 1: // Item Group
				return this.renderSelect('parameters', 'Parameter', options.get('item_group'), this.onChangeParametersHandler, true);
			case 8: // Coupon Code (Range)
				return (
					<Row className="form-row">
						<Colxx xxs="6">{ this.renderInput('parameter', 'Parameter: Range (From, inclusive)') }</Colxx>
						<Colxx xxs="6">{ this.renderInput('parameterTo', 'Parameter: Range (To, inclusive)') }</Colxx>
					</Row>
				);
			default:
				return this.renderInput('parameter', 'Parameter');
			}
		}
		return null;
	}

	render() {
		const { options, index, isLoading } = this.props;
		const { condition } = this.state;
		return (
			<Form className="mb-5" onSubmit={ this.onAddHandler }>
				<Row className="form-row">
					<Colxx xxs="3">
						{ this.renderSelect('type', 'Operator', options.get('condition.type'), this.onChangeTypeHandler) }
					</Colxx>
					<Colxx xxs="9">
						{ this.renderSelect('criteria_type', 'Criteria', this.getCriteriaTypes(), this.onChangeCriteriaTypeHandler) }
					</Colxx>
				</Row>
				<Row className="form-row">
					<Colxx xxs="12">
						{ this.renderParameters() }
					</Colxx>
				</Row>
				{
					condition.get('type') && this.showTotalRow() /* && condition.get('type').label !== 'Invoice' */ && (
						<Row className="form-row mb-3">
							<Colxx xxs="6">
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<Input
											id="total_item_count_operator"
											type="select"
											className="select-prepend"
											value={ condition.get('total_item_count_operator') || '' }
											onChange={ this.onChangeInputHandler }
										>
											<option value="">Logic</option>
											{
												options.get('condition.total_item_count_operator') && options.get('condition.total_item_count_operator').map((operator, index) => (
													<option key={ `form-promotion-tier-condition-${index}` } value={ operator.value }>
														{ operator.label }
													</option>
												))
											}
										</Input>
									</InputGroupAddon>
									{ this.renderInput('total_item_count_number', 'Total Item Count / #', true) }
								</InputGroup>
							</Colxx>
							<Colxx xxs="6">
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<Input
											id="total_amount_operator"
											type="select"
											className="select-prepend"
											value={ condition.get('total_amount_operator') || '' }
											onChange={ this.onChangeInputHandler }
										>
											<option value="">Logic</option>
											{
												options.get('condition.total_amount_operator') && options.get('condition.total_amount_operator').map((operator, index) => (
													<option key={ `form-promotion-tier-condition-${index}` } value={ operator.value }>
														{ operator.label }
													</option>
												))
											}
										</Input>
									</InputGroupAddon>
									{ this.renderInput('total_amount_number', 'Gross Amount / $', true) }
								</InputGroup>
							</Colxx>
						</Row>
					)
				}
				<Row className="form-row">
					<Colxx xxs="12">
						{ this.renderInput('sales_description', 'Sales Description') }
					</Colxx>
				</Row>
				<Row>
					<Colxx xxs="12" className="text-center mt-3">
						<button className="btn btn-outline-theme-3 mr-2" onClick={ this.onCancelHandler } disabled={ isLoading }>
							<i className="simple-icon-close btn-group-icon mr-1" />
							Cancel
						</button>
						<button className="btn btn-outline-theme-3" onClick={ this.onAddHandler }>
							{
								index === null ? (
									<Fragment>
										<i className="simple-icon-plus btn-group-icon mr-1" />
										Add
									</Fragment>
								) : (
									<Fragment>
										<i className="simple-icon-pencil btn-group-icon mr-1" />
										Edit
									</Fragment>
								)
							}
						</button>
					</Colxx>
				</Row>
			</Form>
		);
	}
}

FormPromotionTierCondition.propTypes = {
	options: PropTypes.instanceOf(Map).isRequired,
	defaults: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	index: PropTypes.number,
	condition: PropTypes.instanceOf(Map),
	onEdit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default FormPromotionTierCondition;
