import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import { NotificationManager } from 'Components/ReactNotifications';
import FormMaxBrandDiscount from './components/FormMaxBrandDiscount.jsx';
import IntlMessages from 'Util/IntlMessages';
import {
	maxBrandDiscountsFindAllByPageAndOptions,
	maxBrandDiscountsCreate,
	maxBrandDiscountsUpdate,
	errorReset,
} from 'Redux/actions';
import * as maxBrandDiscountsReducer from 'Redux/maxBrandDiscounts/reducer';

class PageConfigMaxBrandDiscount extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.config' },
			{ id: 'menu.config.max-brand-discount', href: '/config/max-brand-discount' },
		];
		this.columns = [
			{
				Header: 'Brand Code',
				accessor: 'brand_code',
			},
			{
				Header: 'Brand Name',
				accessor: 'brand',
			},
			{
				id: 'max_brand_discount',
				Header: 'Max. Discount',
				sortable: false,
				accessor: 'max_brand_discount.discount',
			},
			{
				id: 'form',
				Header: '',
				sortable: false,
				accessor: (d) => (
					<FormMaxBrandDiscount
						data={ d }
					/>
				),
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('maxBrandDiscounts');
	}

	componentDidUpdate(prevProps) {
		const prevStatus = prevProps.status.get('maxBrandDiscount');
		const status = this.props.status.get('maxBrandDiscount');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted':
				NotificationManager.success(
					'The maximum brand discount is updated successfully.',
					'Maximum Brand Discount Updated'
				);
				break;
			case 'has-errors':
				NotificationManager.error(
					'Error occured! The maximum brand discount is NOT updated.',
					'Maximum Brand Discount Updated Failed'
				);
				break;
			}
		}
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.maxBrandDiscountsFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { maxBrandDiscountsByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('maxBrandDiscounts');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="config.max-brand-discount.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ maxBrandDiscountsByPage.get('data') }
									pages={ maxBrandDiscountsByPage.get('max_page') }
									loading={ isLoading }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ true }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	maxBrandDiscountsReducer.selector,
	{
		maxBrandDiscountsFindAllByPageAndOptions,
		maxBrandDiscountsCreate,
		maxBrandDiscountsUpdate,
		errorReset,
	}
)(PageConfigMaxBrandDiscount);