import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	MAX_BRAND_DISCOUNTS_CREATE,
	MAX_BRAND_DISCOUNTS_CREATE_SUCCESS,
	MAX_BRAND_DISCOUNTS_CREATE_FAIL,
	MAX_BRAND_DISCOUNTS_UPDATE,
	MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS,
	MAX_BRAND_DISCOUNTS_UPDATE_FAIL,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MAX_BRAND_DISCOUNTS_GET_DETAILS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS,
	MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MAX_BRAND_DISCOUNTS_CREATE:
	case MAX_BRAND_DISCOUNTS_UPDATE:
		return state.set('maxBrandDiscount', 'submitting');
	case MAX_BRAND_DISCOUNTS_CREATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS:
		return state.set('maxBrandDiscount', 'submitted');
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('maxBrandDiscounts', 'fetching');
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('maxBrandDiscounts', 'fetched');
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('maxBrandDiscounts', 'has-errors');
	case MAX_BRAND_DISCOUNTS_GET_DETAILS:
		return state.set('maxBrandDiscount', 'fetching');
	case MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS:
		return state.set('maxBrandDiscount', 'fetched');
	case MAX_BRAND_DISCOUNTS_CREATE_FAIL:
	case MAX_BRAND_DISCOUNTS_UPDATE_FAIL:
	case MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL:
		return state.set('maxBrandDiscount', 'has-errors');
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case MAX_BRAND_DISCOUNTS_CREATE:
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case MAX_BRAND_DISCOUNTS_CREATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS:
		return action.payload.brand_code;
	default:
		return state;
	}
};

const maxBrandDiscountsByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	case MAX_BRAND_DISCOUNTS_CREATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS: {
		const data = [...state.get('data')];
		const brand = data.find((d) => action.payload.brand_code == d.brand_code);
		brand.max_brand_discount = action.payload;
		return state.set('data', data);
	}
	default:
		return state;
	}
};

const maxBrandDiscountReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MAX_BRAND_DISCOUNTS_GET_DETAILS:
		return new Map();
	case MAX_BRAND_DISCOUNTS_CREATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS:
	case MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.brand_code, action.payload);
	default:
		return state;
	}
};

export default combineReducers({
	status                  : statusReducer,
	current                 : currentReducer,
	maxBrandDiscountsByPage : maxBrandDiscountsByPageReducer,
	maxBrandDiscount        : maxBrandDiscountReducer,
});

export const selector = createStructuredSelector({
	status                  : (state) => state.maxBrandDiscounts.status,
	current                 : (state) => state.maxBrandDiscounts.current,
	maxBrandDiscountsByPage : (state) => state.maxBrandDiscounts.maxBrandDiscountsByPage,
	maxBrandDiscount        : (state) => state.maxBrandDiscounts.maxBrandDiscount,
});