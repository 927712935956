import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { maxBrandDiscountsCreate, maxBrandDiscountsUpdate } from 'Redux/actions';
import * as maxBrandDiscountsReducer from 'Redux/maxBrandDiscounts/reducer';

class FormMaxBrandDiscount extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			maxDiscount: null,
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const { data } = this.props;
		if (data && data.max_brand_discount) {
			this.setState({
				maxDiscount: data.max_brand_discount.discount,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const prevData = prevProps.data;
		const data = this.props.data;
		if (data.max_brand_discount !== prevData.max_brand_discount) {
			this.setState({
				maxDiscount: data.max_brand_discount ? data.max_brand_discount.discount : null,
			});
		}
	}

	onChangeHandler(e) {
		this.setState({
			maxDiscount: e.target.value,
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const d = this.props.data;
		let data = {
			brand_code: d.brand_code,
			status: 1,
			discount: this.state.maxDiscount,
		};
		if (!d.max_brand_discount) {
			this.props.maxBrandDiscountsCreate(data);
		} else {
			this.props.maxBrandDiscountsUpdate(data);
		}
	}

	render() {
		const { data } = this.props;
		const { maxDiscount } = this.state;
		const isSubmitting = this.props.status.get('maxBrandDiscount') === 'submitting';
		return (
			<Form onSubmit={ this.onSubmitHandler }>
				<InputGroup size="md" className="mb-3">
					<Input
						value={ maxDiscount || '' }
						onChange={ this.onChangeHandler }
					/>
					<InputGroupAddon addonType="append">
						%
					</InputGroupAddon>
					<InputGroupAddon addonType="append">
						<Button type="submit" size="sm" color="primary" disabled={ (maxDiscount === null || maxDiscount === '') && (data.max_brand_discount === null || data.max_brand_discount.discount == maxDiscount) || isSubmitting }>
							Update
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</Form>
		);
	}
}

FormMaxBrandDiscount.propTypes = {
	data: PropTypes.object.isRequired,
};

export default connect(
	maxBrandDiscountsReducer.selector,
	{
		maxBrandDiscountsCreate,
		maxBrandDiscountsUpdate,
	}
)(FormMaxBrandDiscount);