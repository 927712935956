import React, { PureComponent, Fragment } from 'react';
import { Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import printJS from 'print-js';
import LoadingOverlay from 'Components/LoadingOverlay';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { transactionsPrint, errorReset } from 'Redux/actions';
import * as transactionsReducer from 'Redux/transactions/reducer';
import IntlMessages from 'Util/IntlMessages';
import './PageStockTransferOrderPrint.scss';

class PageStockTransferOrderPrint extends PureComponent {
	constructor(props) {
		super(props);
		const params = this.getParams(props);
		this.breadcrumb = [
			{ id: 'menu.transaction', href: '/transaction/list' },
			{ id: 'transaction-print.heading', href: `/stock-transfer/print/${params.type}/${params.orderType}/${params.id}` },
		];
		this.getParams = this.getParams.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onClickPrintHandler = this.onClickPrintHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
		this.props.errorReset('transaction');
	}

	componentDidUpdate(prevProps) {
		const { id } = this.getParams();
		const print = this.props.print.get(id);
		const prevPrint = prevProps.print.get(id);
		if (print && print !== prevPrint) {
			this.onClickPrintHandler();
		}
	}

	getParams(props) {
		if (!props) {
			props = this.props;
		}
		return {
			id: props.match.params.transactionNumber,
			orderType: props.match.params.orderType,
			type: props.match.params.type,
		};
	}

	fetchData() {
		const { id, orderType } = this.getParams();
		const params = {
			transaction_number: id,
			order_type: orderType,
		};
		this.props.transactionsPrint(params);
	}

	onClickPrintHandler() {
		const { id } = this.getParams();
		const print = this.props.print.get(id);
		printJS({
			printable: print.data,
			type: 'pdf',
			base64: true
		});
	}

	render() {
		const { id } = this.getParams();
		const print = this.props.print.get(id);
		const status = this.props.status.get('print');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ id ? <span>{ id }</span> : <IntlMessages id="transaction.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
						<div className="float-right">
							<Button size="lg" color="primary" onClick={ this.onClickPrintHandler } disabled={ isLoading }>
								PRINT
							</Button>
						</div>
						<div className="clearfix" />
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={ isLoading }>
					<div className="transaction-print-iframe-container">
						<iframe
							src={ print && print.data ? `data:application/pdf;base64,${print.data}` : 'about:blank' }
							className="transaction-print-iframe"
						/>
					</div>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	transactionsReducer.selector,
	{
		transactionsPrint,
		errorReset,
	}
)(PageStockTransferOrderPrint);