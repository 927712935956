import {
	ITEM_GROUPS_CREATE,
	ITEM_GROUPS_CREATE_SUCCESS,
	ITEM_GROUPS_CREATE_FAIL,
	ITEM_GROUPS_UPDATE,
	ITEM_GROUPS_UPDATE_SUCCESS,
	ITEM_GROUPS_UPDATE_FAIL,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	ITEM_GROUPS_GET_DETAILS,
	ITEM_GROUPS_GET_DETAILS_SUCCESS,
	ITEM_GROUPS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const itemGroupsCreate = (data) => ({
	type: ITEM_GROUPS_CREATE,
	payload: data,
});

export const itemGroupsCreateSuccess = (data) => {
	return ({
		type: ITEM_GROUPS_CREATE_SUCCESS,
		payload: data,
	});
};

export const itemGroupsCreateFail = (error) => ({
	type: ITEM_GROUPS_CREATE_FAIL,
	payload: error,
});

export const itemGroupsUpdate = (data) => ({
	type: ITEM_GROUPS_UPDATE,
	payload: data,
});

export const itemGroupsUpdateSuccess = (data) => {
	return ({
		type: ITEM_GROUPS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const itemGroupsUpdateFail = (error) => ({
	type: ITEM_GROUPS_UPDATE_FAIL,
	payload: error,
});

export const itemGroupsFindAllByPageAndOptions = (params) => ({
	type: ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const itemGroupsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const itemGroupsFindAllByPageAndOptionsFail = (error) => ({
	type: ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const itemGroupsGetDetails = (id) => ({
	type: ITEM_GROUPS_GET_DETAILS,
	payload: id,
});

export const itemGroupsGetDetailsSuccess = (data) => {
	return ({
		type: ITEM_GROUPS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const itemGroupsGetDetailsFail = (error) => ({
	type: ITEM_GROUPS_GET_DETAILS_FAIL,
	payload: error,
});

export const itemGroupsGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'itemGroup',
		params,
	},
});
