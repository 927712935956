import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	REFRESH_ACCESS_TOKEN,
	REFRESH_ACCESS_TOKEN_SUCCESS,
	REFRESH_ACCESS_TOKEN_FAIL,
	LOGOUT_USER
} from 'Constants/actionTypes';

const INIT_STATE = {
	user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	loading: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case LOGIN_USER:
		return { ...state, loading: true };
	case LOGIN_USER_SUCCESS:
		return { ...state, loading: false, user: action.payload };
	case LOGIN_USER_FAIL:
		return { ...state, loading: false, message: action.payload };
	case REFRESH_ACCESS_TOKEN:
		return { ...state, loading: true };
	case REFRESH_ACCESS_TOKEN_SUCCESS:
		return { ...state, loading: false, user: action.payload };
	case REFRESH_ACCESS_TOKEN_FAIL:
		return { ...state, loading: false, message: action.payload };
	case LOGOUT_USER:
		return { ...state, user: null };
	default:
		return { ...state };
	}
};
