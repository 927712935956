import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	USERS_READ_CURRENT,
	USERS_ASSIGN,
	USERS_UNLOCK,
	USERS_CLEAR_2FA_SECRET,
	USERS_RESET_2FA_SECRET,
	USERS_CHANGE_PASSWORD,
	USERS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	USERS_GET_DETAILS,
	USERS_GET_PERMISSIONS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	usersReadCurrentSuccess,
	usersReadCurrentFail,
	usersAssignSuccess,
	usersAssignFail,
	usersUnlockSuccess,
	usersUnlockFail,
	usersClear2FASecretSuccess,
	usersClear2FASecretFail,
	usersReset2FASecretSuccess,
	usersReset2FASecretFail,
	usersChangePasswordSuccess,
	usersChangePasswordFail,
	usersFindAllByPageAndOptionsSuccess,
	usersFindAllByPageAndOptionsFail,
	usersGetDetailsSuccess,
	usersGetDetailsFail,
	usersGetPermissionsSuccess,
	usersGetPermissionsFail,
} from './actions';

export function* watchUsersReadCurrent() {
	yield takeEvery(USERS_READ_CURRENT, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/user/current`, data);
			return response.data;
		},
		usersReadCurrentSuccess,
		usersReadCurrentFail
	));
}

export function* watchUsersAssign() {
	yield takeEvery(USERS_ASSIGN, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/user/assign`, data);
			return response.data;
		},
		usersAssignSuccess,
		usersAssignFail
	));
}

export function* watchUsersUnlock() {
	yield takeEvery(USERS_UNLOCK, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/user/unlock`, data);
			return response.data;
		},
		usersUnlockSuccess,
		usersUnlockFail
	));
}

export function* watchUsersClear2FASecret() {
	yield takeEvery(USERS_CLEAR_2FA_SECRET, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.delete(`${apiUrl}/user/2fa/clear`, { data });
			return response.data;
		},
		usersClear2FASecretSuccess,
		usersClear2FASecretFail
	));
}

export function* watchUsersReset2FASecret() {
	yield takeEvery(USERS_RESET_2FA_SECRET, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/user/2fa/reset`, data);
			return response.data;
		},
		usersReset2FASecretSuccess,
		usersReset2FASecretFail
	));
}

export function* watchUsersChangePassword() {
	yield takeEvery(USERS_CHANGE_PASSWORD, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/user/changePassword`, data);
			return response.data;
		},
		usersChangePasswordSuccess,
		usersChangePasswordFail
	));
}

export function* watchUsersFindAllByPageAndOptions() {
	yield takeEvery(USERS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/user/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		usersFindAllByPageAndOptionsSuccess,
		usersFindAllByPageAndOptionsFail
	));
}

export function* watchUsersGetDetails() {
	yield takeEvery(USERS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/user/details`, { params: { id: data } });
			return response.data;
		},
		usersGetDetailsSuccess,
		usersGetDetailsFail
	));
}

export function* watchUsersGetPermissions() {
	yield takeEvery(USERS_GET_PERMISSIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/user/permissions`, { params: { id: data } });
			return response.data;
		},
		usersGetPermissionsSuccess,
		usersGetPermissionsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchUsersReadCurrent),
		fork(watchUsersAssign),
		fork(watchUsersUnlock),
		fork(watchUsersClear2FASecret),
		fork(watchUsersReset2FASecret),
		fork(watchUsersChangePassword),
		fork(watchUsersFindAllByPageAndOptions),
		fork(watchUsersGetDetails),
		fork(watchUsersGetPermissions),
	]);
}