import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';

class TrUserRole extends PureComponent {
	constructor() {
		super();
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}

	onChangeHandler() {
		const { userRole, onChange } = this.props;
		onChange(userRole);
	}

	render() {
		const { userRole, checked } = this.props;
		return (
			<tr>
				<th><h4>{ userRole.label }</h4></th>
				<td>
					<Switch
						className="custom-switch custom-switch-primary"
						checked={ checked }
						onChange={ this.onChangeHandler }
					/>
				</td>
			</tr>
		);
	}
}

TrUserRole.propTypes = {
	userRole: PropTypes.object.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default TrUserRole;