import React, { Component, Fragment } from 'react';
import IntlMessages from 'Util/IntlMessages';
import { Row, Card, CardTitle, Alert, Form, Label, Input, Button } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';

import { connect } from 'react-redux';
import { loginUser } from 'Redux/actions';

class LoginLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: process.env.DEFAULT_USERNAME,
			password: process.env.DEFAULT_PASSWORD
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		document.body.classList.add('background');
	}

	componentWillUnmount() {
		document.body.classList.remove('background');
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.name] = e.target.value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { username, password } = this.state;
		const data = { username, password };
		if (username !== '' && password !== '') {
			this.props.loginUser(data, this.props.history);
		}
	}

	render() {
		const { loading, message } = this.props;
		const { username, password } = this.state;
		return (
			<Fragment>
				<div className="fixed-background" />
				<main>
					<div className="container">
						<Row className="h-100">
							<Colxx xxs="12" md="10" className="mx-auto my-auto">
								<Card className="auth-card">
									<div className="position-relative image-side" />
									<div className="form-side">
										{
											message && (typeof message === 'string') && (
												<Alert color="danger">{ message }</Alert>
											)
										}
										<CardTitle className="mb-4">
											<IntlMessages id="user.login-title" />
										</CardTitle>
										<Form onSubmit={ this.onSubmitHandler }>
											<Label className="form-group has-float-label mb-4">
												<Input name="username" type="text" value={ username } onChange={ this.onChangeHandler } autoComplete="off" disabled={ loading } />
												<IntlMessages id="user.username" />
											</Label>
											<Label className="form-group has-float-label mb-4">
												<Input name="password" type="password" value={ password } onChange={ this.onChangeHandler } autoComplete="off" disabled={ loading } />
												<IntlMessages id="user.password" />
											</Label>
											<div className="d-flex justify-content-between align-items-center">
												<Button
													type="submit"
													color="primary"
													className="btn-shadow"
													size="lg"
													disabled={ loading }
												>
													<IntlMessages id="user.login-button" />
												</Button>
											</div>
										</Form>
									</div>
								</Card>

								<footer className="my-4 text-muted text-center">
									&copy; 2019-{ new Date().getFullYear() } PUYI GROUP LIMITED (HONG KONG)
								</footer>
							</Colxx>
						</Row>
					</div>
				</main>
			</Fragment>
		);
	}
}
const mapStateToProps = ({ authUser }) => {
	const { user, loading, message } = authUser;
	return { user, loading, message };
};

export default connect(
	mapStateToProps,
	{
		loginUser
	}
)(LoginLayout);
