import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	TRANSACTIONS_GET_DETAILS,
	TRANSACTIONS_GET_DETAILS_SUCCESS,
	TRANSACTIONS_GET_DETAILS_FAIL,
	TRANSACTIONS_PRINT,
	TRANSACTIONS_PRINT_SUCCESS,
	TRANSACTIONS_PRINT_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('transactions', 'fetching');
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('transactions', 'fetched');
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('transactions', 'has-errors');
	case TRANSACTIONS_GET_DETAILS:
		return state.set('transaction', 'fetching');
	case TRANSACTIONS_GET_DETAILS_SUCCESS:
		return state.set('transaction', 'fetched');
	case TRANSACTIONS_GET_DETAILS_FAIL:
		return state.set('transaction', 'has-errors');
	case TRANSACTIONS_PRINT:
		return state.set('print', 'fetching');
	case TRANSACTIONS_PRINT_SUCCESS:
		return state.set('print', 'fetched');
	case TRANSACTIONS_PRINT_FAIL:
		return state.set('print', 'has-errors');
	default:
		return state;
	}
};

const transactionsByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const transactionReducer = (state = new Map(), action) => {
	switch (action.type) {
	case TRANSACTIONS_GET_DETAILS:
		return new Map();
	case TRANSACTIONS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.transaction_number, action.payload);
	default:
		return state;
	}
};

const printReducer = (state = new Map(), action) => {
	switch (action.type) {
	case TRANSACTIONS_PRINT:
		return new Map();
	case TRANSACTIONS_PRINT_SUCCESS:
		if (action.payload.transaction_number) {
			return state.set(action.payload.transaction_number, action.payload);
		} else if (action.payload.filename) {
			return state.set(action.payload.filename.replace(/\.pdf$/, ''), action.payload);
		}
		break;
	default:
		return state;
	}
};

export default combineReducers({
	status             : statusReducer,
	transactionsByPage : transactionsByPageReducer,
	transaction        : transactionReducer,
	print              : printReducer,
});

export const selector = createStructuredSelector({
	status             : (state) => state.transactions.status,
	transactionsByPage : (state) => state.transactions.transactionsByPage,
	transaction        : (state) => state.transactions.transaction,
	print              : (state) => state.transactions.print,
});