import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	COMPANIES_CREATE,
	COMPANIES_UPDATE,
	COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	COMPANIES_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	companiesCreateSuccess,
	companiesCreateFail,
	companiesUpdateSuccess,
	companiesUpdateFail,
	companiesFindAllByPageAndOptionsSuccess,
	companiesFindAllByPageAndOptionsFail,
	companiesGetDetailsSuccess,
	companiesGetDetailsFail,
} from './actions';

export function* watchWarehouseGroupsCreate() {
	yield takeEvery(COMPANIES_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/company/create`, data);
			return response.data;
		},
		companiesCreateSuccess,
		companiesCreateFail
	));
}

export function* watchWarehouseGroupsUpdate() {
	yield takeEvery(COMPANIES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/company/update`, data);
			return response.data;
		},
		companiesUpdateSuccess,
		companiesUpdateFail
	));
}

export function* watchWarehouseGroupsFindAllByPageAndOptions() {
	yield takeEvery(COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/company/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		companiesFindAllByPageAndOptionsSuccess,
		companiesFindAllByPageAndOptionsFail
	));
}

export function* watchWarehouseGroupsGetDetails() {
	yield takeEvery(COMPANIES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/company/details`, { params: { id: data } });
			return response.data;
		},
		companiesGetDetailsSuccess,
		companiesGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchWarehouseGroupsCreate),
		fork(watchWarehouseGroupsUpdate),
		fork(watchWarehouseGroupsFindAllByPageAndOptions),
		fork(watchWarehouseGroupsGetDetails),
	]);
}