import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	MESSAGES_CREATE,
	MESSAGES_UPDATE,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MESSAGES_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	messagesCreateSuccess,
	messagesCreateFail,
	messagesUpdateSuccess,
	messagesUpdateFail,
	messagesFindAllByPageAndOptionsSuccess,
	messagesFindAllByPageAndOptionsFail,
	messagesGetDetailsSuccess,
	messagesGetDetailsFail,
} from './actions';

export function* watchMessagesCreate() {
	yield takeEvery(MESSAGES_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/message/create`, data);
			return response.data;
		},
		messagesCreateSuccess,
		messagesCreateFail
	));
}

export function* watchMessagesUpdate() {
	yield takeEvery(MESSAGES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/message/update`, data);
			return response.data;
		},
		messagesUpdateSuccess,
		messagesUpdateFail
	));
}

export function* watchMessagesFindAllByPageAndOptions() {
	yield takeEvery(MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/message/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		messagesFindAllByPageAndOptionsSuccess,
		messagesFindAllByPageAndOptionsFail
	));
}

export function* watchMessagesGetDetails() {
	yield takeEvery(MESSAGES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/message/details`, { params: { id: data } });
			return response.data;
		},
		messagesGetDetailsSuccess,
		messagesGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchMessagesCreate),
		fork(watchMessagesUpdate),
		fork(watchMessagesFindAllByPageAndOptions),
		fork(watchMessagesGetDetails),
	]);
}