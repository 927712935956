import React, { Fragment } from 'react';
import { Card, CardBody, Table, Row } from 'reactstrap';
import { Colxx } from 'Components/CustomBootstrap';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import thousands from 'thousands';
import { Separator } from 'Components/CustomBootstrap';
import FormMessageCondition from './FormMessageCondition.jsx';

class MessageCondition extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			mode: 'read',
			index: null,
			conditions: props.conditions ? props.conditions : new List(),
		};
		this.onToggleModeHandler = this.onToggleModeHandler.bind(this);
		this.onEditHandler = this.onEditHandler.bind(this);
		this.onConfirmHandler = this.onConfirmHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
		this.onClickEditHandler = this.onClickEditHandler.bind(this);
		this.onClickDeleteHandler = this.onClickDeleteHandler.bind(this);
		this.onClickAddHandler = this.onClickAddHandler.bind(this);
		this.renderParameters = this.renderParameters.bind(this);
		this.renderErrors = this.renderErrors.bind(this);
	}

	componentDidUpdate(prevProps) {
		const prevConditions = prevProps.conditions;
		const conditions = this.props.conditions;
		if (conditions !== prevConditions) {
			this.setState({
				conditions,
			});
		}
	}

	onToggleModeHandler() {
		const { mode } = this.state;
		this.setState({
			mode: mode === 'read' ? 'write' : 'read',
		});
	}

	onEditHandler(condition) {
		let { index, conditions } = this.state;
		if (index === null) {
			conditions = conditions.push(condition.set('ordering', conditions.size + 1));
		} else {
			conditions = conditions.set(index, condition);
		}
		this.props.onChange(conditions);
		this.setState({
			index: null,
			conditions,
		});
	}

	onConfirmHandler(e) {
		e.preventDefault();
		const { conditions } = this.state;
		this.props.onChange(conditions);
		this.setState({
			mode: 'read',
			index: null,
		});
	}

	onCancelHandler(e) {
		e.preventDefault();
		const { conditions } = this.props;
		this.setState({
			mode: 'read',
			index: null,
			conditions,
		});
	}

	onClickEditHandler(e) {
		e.preventDefault();
		const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
		this.setState({
			index,
		});
	}

	onClickDeleteHandler(e) {
		e.preventDefault();
		if (confirm('Are you sure to delete this condition?')) {
			const index = parseInt(e.target.attributes.getNamedItem('data-index').value);
			let { conditions } = this.state;
			conditions = conditions.delete(index);
			this.setState({
				conditions,
			});
			this.props.onChange(conditions);
		}
	}

	onClickAddHandler(e) {
		e.preventDefault();
		this.setState({
			index: null,
		});
	}

	renderParameters(condition) {
		if (condition.get('criteria_type')) {
			switch (condition.get('criteria_type').value) {
			case 1: // Item Group
				return condition.get('parameters').map((parameter, index) => (
					<div key={ `message-condition-parameter-${index}` }>
						{ parameter.label }
					</div>
				));
			case 8: // Coupon Code (Range)
				return `${condition.get('parameter')} - ${condition.get('parameterTo')}`;
			default:
				return condition.get('parameter');
			}
		}
	}

	renderErrors(index, field) {
		const key = `conditions.${index}.${field}`;
		let { errors } = this.props;
		if (errors && errors.detail && errors.detail[key]) {
			errors = errors.detail[key];
			return (
				<div className="text-danger">
					{
						errors.map((error, index) => (
							<Fragment key={ `message-condition-${index}-error-${field}` }>
								{ index > 0 && <br /> }
								{ error }
							</Fragment>
						))
					}
				</div>
			);
		}
	}

	render() {
		const { options, defaults, isLoading } = this.props;
		const { mode, index, conditions } = this.state;
		return (
			<Card className="mb-5">
				<CardBody>
					<h3 className="d-block mb-4 text-primary">Conditions:</h3>
					<Table borderless>
						<thead>
							<tr>
								{ /*
									mode === 'write' && (
										<th width="5%"></th>
									)
								*/ }
								<th width="15%"></th>
								<th width="25%">Criteria</th>
								<th width="40%">Param</th>
								<th width={ mode === 'write' ? '7%' : '10%' }>Item</th>
								<th width={ mode === 'write' ? '8%' : '10%' }>Amount</th>
								{
									mode === 'write' && (
										<th width="5%"></th>
									)
								}
							</tr>
						</thead>
						<tbody>
							{
								conditions.map((condition, index) => (
									<tr key={ `message-condition-${index}` }>
										{ /*
											mode === 'write' && (
												<td>
													<i className="simple-icon-menu cursor-move" />
												</td>
											)
										*/ }
										<td>
											{ condition.get('type') && condition.get('type').label }
											{ this.renderErrors(index, 'type') }
										</td>
										<td>
											{ condition.get('criteria_type') && condition.get('criteria_type').label }
											{ this.renderErrors(index, 'criteria_type') }
										</td>
										<td>
											{ this.renderParameters(condition) }
										</td>
										<td>
											{ condition.get('total_item_count_operator') }
											{ ' ' }
											{ thousands(condition.get('total_item_count_number')) }

											{ this.renderErrors(index, 'total_item_count_operator') }
											{ this.renderErrors(index, 'total_item_count_number') }
										</td>
										<td>
											{ condition.get('total_amount_operator') }
											{ ' ' }
											{ thousands(condition.get('total_amount_number')) }

											{ this.renderErrors(index, 'total_amount_operator') }
											{ this.renderErrors(index, 'total_amount_number') }
										</td>
										{
											mode === 'write' && (
												<td className="text-center">
													<i
														className="p-1 cursor-pointer simple-icon-pencil"
														data-index={ index }
														onClick={ this.onClickEditHandler }
													/>
													<i
														className="p-1 cursor-pointer simple-icon-ban"
														data-index={ index }
														onClick={ this.onClickDeleteHandler }
													/>
												</td>
											)
										}
									</tr>
								))
							}
						</tbody>
					</Table>

					{
						mode === 'write' && (
							<Fragment>
								<Separator className="mt-5 my-2" />
								<Row className='my-3'>
									<Colxx xxs="12" className="text-right">
										<button className="btn btn-outline-theme-3 icon-button" onClick={ this.onClickAddHandler }>
											<i className="simple-icon-plus" />
										</button>
									</Colxx>
								</Row>
								<FormMessageCondition
									options={ options }
									defaults={ defaults }
									isLoading={ isLoading }
									index={ index }
									condition={ index !== null ? conditions.get(index) : null }
									onEdit={ this.onEditHandler }
									onCancel={ this.onCancelHandler }
								/>
							</Fragment>
						)
					}

					<div className="text-center">
						{
							mode !== 'write' && (
								<Fragment>
									<button className="btn btn-outline-theme-3" onClick={ this.onToggleModeHandler }>
										<i className="simple-icon-pencil mr-1" />
										Edit
									</button>
								</Fragment>
							)
						}
					</div>
				</CardBody>
			</Card>
		);
	}
}

MessageCondition.propTypes = {
	options: PropTypes.instanceOf(Map).isRequired,
	errors: PropTypes.object,
	defaults: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	conditions: PropTypes.instanceOf(List).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default MessageCondition;