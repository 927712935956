import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	PROMOTIONS_CREATE,
	PROMOTIONS_CREATE_SUCCESS,
	PROMOTIONS_CREATE_FAIL,
	PROMOTIONS_UPDATE,
	PROMOTIONS_UPDATE_SUCCESS,
	PROMOTIONS_UPDATE_FAIL,
	PROMOTIONS_COPY,
	PROMOTIONS_COPY_SUCCESS,
	PROMOTIONS_COPY_FAIL,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	PROMOTIONS_GET_DETAILS,
	PROMOTIONS_GET_DETAILS_SUCCESS,
	PROMOTIONS_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PROMOTIONS_CREATE:
	case PROMOTIONS_UPDATE:
		return state.set('promotion', 'submitting');
	case PROMOTIONS_COPY:
		return state.set('promotion', 'copying');
	case PROMOTIONS_CREATE_SUCCESS:
	case PROMOTIONS_UPDATE_SUCCESS:
		return state.set('promotion', 'submitted');
	case PROMOTIONS_COPY_SUCCESS:
		return state.set('promotion', 'copied');
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('promotions', 'fetching');
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('promotions', 'fetched');
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('promotions', 'has-errors');
	case PROMOTIONS_GET_DETAILS:
		return state.set('promotion', 'fetching');
	case PROMOTIONS_GET_DETAILS_SUCCESS:
		return state.set('promotion', 'fetched');
	case PROMOTIONS_CREATE_FAIL:
	case PROMOTIONS_UPDATE_FAIL:
	case PROMOTIONS_COPY_FAIL:
	case PROMOTIONS_GET_DETAILS_FAIL:
		return state.set('promotion', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'promotion' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'promotion' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'promotion' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case PROMOTIONS_CREATE:
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case PROMOTIONS_CREATE_SUCCESS:
	case PROMOTIONS_UPDATE_SUCCESS:
	case PROMOTIONS_COPY_SUCCESS:
	case PROMOTIONS_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const promotionsByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const promotionReducer = (state = new Map(), action) => {
	switch (action.type) {
	case PROMOTIONS_GET_DETAILS:
		return new Map();
	case PROMOTIONS_CREATE_SUCCESS:
	case PROMOTIONS_UPDATE_SUCCESS:
	case PROMOTIONS_COPY_SUCCESS:
	case PROMOTIONS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'promotion' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'promotion' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status           : statusReducer,
	current          : currentReducer,
	promotionsByPage : promotionsByPageReducer,
	promotion        : promotionReducer,
	options          : optionsReducer,
});

export const selector = createStructuredSelector({
	status           : (state) => state.promotions.status,
	current          : (state) => state.promotions.current,
	promotionsByPage : (state) => state.promotions.promotionsByPage,
	promotion        : (state) => state.promotions.promotion,
	options          : (state) => state.promotions.options,
	errors           : (state) => state.errors.get('promotion'),
});