import {
	PROMOTIONS_CREATE,
	PROMOTIONS_CREATE_SUCCESS,
	PROMOTIONS_CREATE_FAIL,
	PROMOTIONS_UPDATE,
	PROMOTIONS_UPDATE_SUCCESS,
	PROMOTIONS_UPDATE_FAIL,
	PROMOTIONS_COPY,
	PROMOTIONS_COPY_SUCCESS,
	PROMOTIONS_COPY_FAIL,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	PROMOTIONS_GET_DETAILS,
	PROMOTIONS_GET_DETAILS_SUCCESS,
	PROMOTIONS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const promotionsCreate = (data) => ({
	type: PROMOTIONS_CREATE,
	payload: data,
});

export const promotionsCreateSuccess = (data) => {
	return ({
		type: PROMOTIONS_CREATE_SUCCESS,
		payload: data,
	});
};

export const promotionsCreateFail = (error) => ({
	type: PROMOTIONS_CREATE_FAIL,
	payload: error,
});

export const promotionsUpdate = (data) => ({
	type: PROMOTIONS_UPDATE,
	payload: data,
});

export const promotionsUpdateSuccess = (data) => {
	return ({
		type: PROMOTIONS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const promotionsUpdateFail = (error) => ({
	type: PROMOTIONS_UPDATE_FAIL,
	payload: error,
});

export const promotionsCopy = (data) => ({
	type: PROMOTIONS_COPY,
	payload: data,
});

export const promotionsCopySuccess = (data) => {
	return ({
		type: PROMOTIONS_COPY_SUCCESS,
		payload: data,
	});
};

export const promotionsCopyFail = (error) => ({
	type: PROMOTIONS_COPY_FAIL,
	payload: error,
});

export const promotionsFindAllByPageAndOptions = (params) => ({
	type: PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const promotionsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const promotionsFindAllByPageAndOptionsFail = (error) => ({
	type: PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const promotionsGetDetails = (id) => ({
	type: PROMOTIONS_GET_DETAILS,
	payload: id,
});

export const promotionsGetDetailsSuccess = (data) => {
	return ({
		type: PROMOTIONS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const promotionsGetDetailsFail = (error) => ({
	type: PROMOTIONS_GET_DETAILS_FAIL,
	payload: error,
});

export const promotionsGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'promotion',
		params,
	},
});
