import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	MESSAGES_CREATE,
	MESSAGES_CREATE_SUCCESS,
	MESSAGES_CREATE_FAIL,
	MESSAGES_UPDATE,
	MESSAGES_UPDATE_SUCCESS,
	MESSAGES_UPDATE_FAIL,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MESSAGES_GET_DETAILS,
	MESSAGES_GET_DETAILS_SUCCESS,
	MESSAGES_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MESSAGES_CREATE:
	case MESSAGES_UPDATE:
		return state.set('message', 'submitting');
	case MESSAGES_CREATE_SUCCESS:
	case MESSAGES_UPDATE_SUCCESS:
		return state.set('message', 'submitted');
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('messages', 'fetching');
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('messages', 'fetched');
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('messages', 'has-errors');
	case MESSAGES_GET_DETAILS:
		return state.set('message', 'fetching');
	case MESSAGES_GET_DETAILS_SUCCESS:
		return state.set('message', 'fetched');
	case MESSAGES_CREATE_FAIL:
	case MESSAGES_UPDATE_FAIL:
	case MESSAGES_GET_DETAILS_FAIL:
		return state.set('message', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'message' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'message' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'message' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case MESSAGES_CREATE:
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case MESSAGES_CREATE_SUCCESS:
	case MESSAGES_UPDATE_SUCCESS:
	case MESSAGES_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const messagesByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const messageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MESSAGES_GET_DETAILS:
		return new Map();
	case MESSAGES_CREATE_SUCCESS:
	case MESSAGES_UPDATE_SUCCESS:
	case MESSAGES_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'message' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'message' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status         : statusReducer,
	current        : currentReducer,
	messagesByPage : messagesByPageReducer,
	message        : messageReducer,
	options        : optionsReducer,
});

export const selector = createStructuredSelector({
	status         : (state) => state.messages.status,
	current        : (state) => state.messages.current,
	messagesByPage : (state) => state.messages.messagesByPage,
	message        : (state) => state.messages.message,
	options        : (state) => state.messages.options,
	errors         : (state) => state.errors.get('message'),
});