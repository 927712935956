import {
	MESSAGES_CREATE,
	MESSAGES_CREATE_SUCCESS,
	MESSAGES_CREATE_FAIL,
	MESSAGES_UPDATE,
	MESSAGES_UPDATE_SUCCESS,
	MESSAGES_UPDATE_FAIL,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MESSAGES_GET_DETAILS,
	MESSAGES_GET_DETAILS_SUCCESS,
	MESSAGES_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const messagesCreate = (data) => ({
	type: MESSAGES_CREATE,
	payload: data,
});

export const messagesCreateSuccess = (data) => {
	return ({
		type: MESSAGES_CREATE_SUCCESS,
		payload: data,
	});
};

export const messagesCreateFail = (error) => ({
	type: MESSAGES_CREATE_FAIL,
	payload: error,
});

export const messagesUpdate = (data) => ({
	type: MESSAGES_UPDATE,
	payload: data,
});

export const messagesUpdateSuccess = (data) => {
	return ({
		type: MESSAGES_UPDATE_SUCCESS,
		payload: data,
	});
};

export const messagesUpdateFail = (error) => ({
	type: MESSAGES_UPDATE_FAIL,
	payload: error,
});

export const messagesFindAllByPageAndOptions = (params) => ({
	type: MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const messagesFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const messagesFindAllByPageAndOptionsFail = (error) => ({
	type: MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const messagesGetDetails = (id) => ({
	type: MESSAGES_GET_DETAILS,
	payload: id,
});

export const messagesGetDetailsSuccess = (data) => {
	return ({
		type: MESSAGES_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const messagesGetDetailsFail = (error) => ({
	type: MESSAGES_GET_DETAILS_FAIL,
	payload: error,
});

export const messagesGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'message',
		params,
	},
});
