import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	WAREHOUSE_GROUPS_CREATE,
	WAREHOUSE_GROUPS_UPDATE,
	WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSE_GROUPS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	warehouseGroupsCreateSuccess,
	warehouseGroupsCreateFail,
	warehouseGroupsUpdateSuccess,
	warehouseGroupsUpdateFail,
	warehouseGroupsFindAllByPageAndOptionsSuccess,
	warehouseGroupsFindAllByPageAndOptionsFail,
	warehouseGroupsGetDetailsSuccess,
	warehouseGroupsGetDetailsFail,
} from './actions';

export function* watchWarehouseGroupsCreate() {
	yield takeEvery(WAREHOUSE_GROUPS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/warehouseGroup/create`, data);
			return response.data;
		},
		warehouseGroupsCreateSuccess,
		warehouseGroupsCreateFail
	));
}

export function* watchWarehouseGroupsUpdate() {
	yield takeEvery(WAREHOUSE_GROUPS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouseGroup/update`, data);
			return response.data;
		},
		warehouseGroupsUpdateSuccess,
		warehouseGroupsUpdateFail
	));
}

export function* watchWarehouseGroupsFindAllByPageAndOptions() {
	yield takeEvery(WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseGroup/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		warehouseGroupsFindAllByPageAndOptionsSuccess,
		warehouseGroupsFindAllByPageAndOptionsFail
	));
}

export function* watchWarehouseGroupsGetDetails() {
	yield takeEvery(WAREHOUSE_GROUPS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseGroup/details`, { params: { id: data } });
			return response.data;
		},
		warehouseGroupsGetDetailsSuccess,
		warehouseGroupsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchWarehouseGroupsCreate),
		fork(watchWarehouseGroupsUpdate),
		fork(watchWarehouseGroupsFindAllByPageAndOptions),
		fork(watchWarehouseGroupsGetDetails),
	]);
}