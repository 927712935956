import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	WAREHOUSES_UPDATE,
	WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS,
	WAREHOUSES_GET_DETAILS,
	WAREHOUSES_GET_IP_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS,
	WAREHOUSES_GET_DOMAIN_NAME_WHITELISTS,
	WAREHOUSES_BATCH_UPDATE_DOMAIN_NAME_WHITELISTS,
	WAREHOUSES_GET_BSSIDS,
	WAREHOUSES_BATCH_UPDATE_BSSIDS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	warehousesUpdateSuccess,
	warehousesUpdateFail,
	warehousesFindAllByPageAndOptionsSuccess,
	warehousesFindAllByPageAndOptionsFail,
	warehousesGetDetailsSuccess,
	warehousesGetDetailsFail,
	warehousesGetIPWhitelistsSuccess,
	warehousesGetIPWhitelistsFail,
	warehousesBatchUpdateIPWhitelistsSuccess,
	warehousesBatchUpdateIPWhitelistsFail,
	warehousesGetDomainNameWhitelistsSuccess,
	warehousesGetDomainNameWhitelistsFail,
	warehousesBatchUpdateDomainNameWhitelistsSuccess,
	warehousesBatchUpdateDomainNameWhitelistsFail,
	warehousesGetBssidsSuccess,
	warehousesGetBssidsFail,
	warehousesBatchUpdateBssidsSuccess,
	warehousesBatchUpdateBssidsFail,
} from './actions';

export function* watchWarehousesUpdate() {
	yield takeEvery(WAREHOUSES_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouse/update`, data);
			return response.data;
		},
		warehousesUpdateSuccess,
		warehousesUpdateFail
	));
}

export function* watchWarehousesFindAllByPageAndOptions() {
	yield takeEvery(WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouse/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		warehousesFindAllByPageAndOptionsSuccess,
		warehousesFindAllByPageAndOptionsFail
	));
}

export function* watchWarehousesGetDetails() {
	yield takeEvery(WAREHOUSES_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouse/details`, { params: { wh_code: data } });
			return response.data;
		},
		warehousesGetDetailsSuccess,
		warehousesGetDetailsFail
	));
}

export function* watchWarehousesGetIPWhitelists() {
	yield takeEvery(WAREHOUSES_GET_IP_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseIpWhitelist/findAllByWarehouse`, { params: { warehouse_code: data } });
			return response.data;
		},
		warehousesGetIPWhitelistsSuccess,
		warehousesGetIPWhitelistsFail
	));
}

export function* watchWarehousesBatchUpdateIPWhitelists() {
	yield takeEvery(WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouseIpWhitelist/batchUpdateByWarehouse`, data);
			return response.data;
		},
		warehousesBatchUpdateIPWhitelistsSuccess,
		warehousesBatchUpdateIPWhitelistsFail
	));
}

export function* watchWarehousesGetDomainNameWhitelists() {
	yield takeEvery(WAREHOUSES_GET_DOMAIN_NAME_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseDomainNameWhitelist/findAllByWarehouse`, { params: { warehouse_code: data } });
			return response.data;
		},
		warehousesGetDomainNameWhitelistsSuccess,
		warehousesGetDomainNameWhitelistsFail
	));
}

export function* watchWarehousesBatchUpdateDomainNameWhitelists() {
	yield takeEvery(WAREHOUSES_BATCH_UPDATE_DOMAIN_NAME_WHITELISTS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouseDomainNameWhitelist/batchUpdateByWarehouse`, data);
			return response.data;
		},
		warehousesBatchUpdateDomainNameWhitelistsSuccess,
		warehousesBatchUpdateDomainNameWhitelistsFail
	));
}

export function* watchWarehousesGetBssids() {
	yield takeEvery(WAREHOUSES_GET_BSSIDS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/warehouseBssid/findAllByWarehouse`, { params: { warehouse_code: data } });
			return response.data;
		},
		warehousesGetBssidsSuccess,
		warehousesGetBssidsFail
	));
}

export function* watchWarehousesBatchUpdateBssids() {
	yield takeEvery(WAREHOUSES_BATCH_UPDATE_BSSIDS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/warehouseBssid/batchUpdateByWarehouse`, data);
			return response.data;
		},
		warehousesBatchUpdateBssidsSuccess,
		warehousesBatchUpdateBssidsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchWarehousesUpdate),
		fork(watchWarehousesFindAllByPageAndOptions),
		fork(watchWarehousesGetDetails),
		fork(watchWarehousesGetIPWhitelists),
		fork(watchWarehousesBatchUpdateIPWhitelists),
		fork(watchWarehousesGetDomainNameWhitelists),
		fork(watchWarehousesBatchUpdateDomainNameWhitelists),
		fork(watchWarehousesGetBssids),
		fork(watchWarehousesBatchUpdateBssids),
	]);
}