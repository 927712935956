import { call, put } from 'redux-saga/effects';

export const defaultFetchDataFunc = (fetchFuncAsync, success, fail) => {
	return function*({ payload }) {
		try {
			const response = yield call(fetchFuncAsync, payload);
			yield put(success(response, payload));
		} catch (error) {
			if (error.response) {
				if (error.response.status === 400 && error.response.data.message) {
					yield put(fail(error.response.data));
				} else {
					yield put(fail({ message: 'Invalid response.' }));
				}
			} else if (error.request) {
				yield put(fail({ message: 'No response was received.' }));
			} else {
				yield put(fail({ message: 'Error while setting up the request.' }));
			}
		}
	};
};